<link rel="stylesheet" *ngFor="let customizeStylesheetUrl of customizationStylesheetUrls"
	[href]='customizeStylesheetUrl'>

<!-- TICKET CONTAINER -->
<main id="item-tracker-container" (window:touchstart)="storeTouchStart($event)"
	(window:touchend)="storeTouchEnd($event)"
	[class.temp-perspective]="currentState === TicketTrackerStatus.ShowAndTrackItem || currentState === TicketTrackerStatus.ShowItem">

	<!-- LOADER -->
	<qevo-loader loadingMessage="GLOBAL.LOADING"
		*ngIf="currentState === TicketTrackerStatus.Home || currentState === TicketTrackerStatus.Menus || currentState === TicketTrackerStatus.StartItemTracking || currentState === TicketTrackerStatus.ValidateBooking">
	</qevo-loader>

	<!-- TICKET -->
	<qoala-tt-card
		*ngIf="currentState === TicketTrackerStatus.ShowAndTrackItem || currentState === TicketTrackerStatus.ShowItem"
		[isCalled]="isItemCalled" [curLangCode]="curLangCode" [isScrollingUp]="!isScrollingUp"
		(click)="isScrollingUp = true" [showETS]="showETS" (showFormEmitter)="toggleCallingArea($event)">
	</qoala-tt-card>

	<!-- CALLING AREA -->
	<qoala-tt-calling-area *ngIf="currentState === TicketTrackerStatus.ShowAndTrackItem && !hideCallingArea"
		[curLangCode]="curLangCode" [isScrollingUp]="!isScrollingUp" (click)="isScrollingUp = false"
		[sound]="isSoundActive" [showCounter]="showCounterInCallingPanels"></qoala-tt-calling-area>
</main>