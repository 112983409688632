<div id="view">

	<h2 id="main-title" [ngStyle]="{color: fontColor}">{{ 'SMS_SETTINGS.TITLE' | translate }}</h2>

	<div id="main-container">

		<div id="center">

			<div id="title" [ngStyle]="{color: fontColor}">{{ 'SMS_SETTINGS.SET_PHONE_NUMBER' | translate }}</div>

			<input id="phone-input" name="phone-input" type="text" (keyup)="phoneNumberInput($event);"
				[value]="phoneNumber">

			<div id="terms-container">
				<!-- CHECKBOX WITH CUSTOM COLOR -->
				<label [ngStyle]="{color: fontColor}" class="checkbox-container" *ngIf="fontColor">
					<input type="checkbox" name="terms" (change)="termsChanged($event);" [checked]="termsCheck">
					<span [style.border-color]="termsCheck ? fontColor : '#8b8b8b'" class="checkbox">
						<div class="tick-1" [style.background-color]="fontColor"></div>
						<div class="tick-2" [style.background-color]="fontColor"></div>
					</span>
				</label>

				<!-- CHECKBOX WITHOUT CUSTOM COLOR -->
				<label class="checkbox-container" *ngIf="!fontColor">
					<input type="checkbox" name="terms" (change)="termsChanged($event);" [checked]="termsCheck">
					<span class="checkbox">
						<div class="tick-1 not-custom"></div>
						<div class="tick-2"></div>
					</span>
				</label>

				<span [ngStyle]="{color: fontColor}" class="side-label">{{'SMS_SETTINGS.I_ACCEPT' | translate}}
					<span [ngStyle]="{color: linkFontColor}" (click)="openServiceTermsAndConditions($event)">
						{{'SMS_SETTINGS.TERMS_CONDITIONS' | translate}}</span>
				</span>
			</div>

			<button [class.disabled]="!termsCheck || !isPhoneNumberValid" [debounceTime]="1000"
				[ngStyle]="{color: buttonFontColor, background: buttonBackgroundColor}"
				(debounceClick)="getNewTicket()">
				<i class="fa fa-ticket" aria-hidden="true"></i>{{ 'SMS_SETTINGS.TAKE_TICKET' | translate }}
			</button>

		</div>

	</div>

</div>

<!-- SERVICE TERMS AND CONDITIONS -->
<swal #serviceTermsAndConditionsPopup id="serviceTermsAndConditionsPopup" [showCancelButton]="false"
	[showConfirmButton]="false" [showCloseButton]="false" customClass="ticket-terms-and-conditions">
	<div *swalPortal id="service-terms-and-conditions">
		<!-- LOADING STATE -->
		<qevo-loader id="serviceTermsAndConditionsLoader" loadingMessage="GLOBAL.LOADING"
			[class.show]="serviceTermsAndConditionsIsLoading"></qevo-loader>
		<!-- IFRAME -->
		<iframe #serviceTermsAndConditionsIFrame
			*ngIf="!serviceTermsAndConditionsIFrameLoadError; else errorServiceTermsAndConditionsTemplate"
			title="terms-and-conditions" [src]="safeResourceTermsAndConditionsUrl" frameborder="0"
			(load)="onServiceTermsAndConditionsIframeLoad()" data-serviceTermsAndConditionsLoaded="0"
			[style.display]="serviceTermsAndConditionsIsLoading ? 'none' : ''"></iframe>
		<!-- ERROR TEMPLATE -->
		<ng-template #errorServiceTermsAndConditionsTemplate>
			<div id="error">
				<div id="error-message">{{'ERRORS.ServiceTermsAndConditionsNotFound' | translate}}</div>
			</div>
		</ng-template>
		<!-- BACK BUTTON -->
		<div id="button-container">
			<button id="back" type="button"
				[ngStyle]="{color: buttonBackgroundColor, background: buttonFontColor, 'border-color': buttonBackgroundColor}"
				class="back-btn" (click)="closeServiceTermsAndConditions()">{{'SMS_SETTINGS.BACK' | translate}}</button>
		</div>
	</div>
</swal>
&nbsp;&nbsp;