<div id="view">
	<!-- BACK BUTTON -->
	<button id="back" [ngStyle]="{color: backFontColor, background: backBackgroundColor}"
		*ngIf="!showEntityMenu && !entityFromQRCode && !storeFromQRCode" (click)="goBackToEntitySelection()">
		<i class="fa fa-arrow-left" aria-hidden="true"></i>
	</button>
	<!-- PAGE TITLE -->
	<div id="view-header" [class.with-back-button]="!showEntityMenu && !showLogo">
		<h2 id="main-title" *ngIf="!showLogo">
			{{ 'NEW_TICKET.TITLE' |translate }}
		</h2>
	</div>

	<!-- LOGO -->
	<img *ngIf="showLogo" [src]="logoSrc" />

	<!-- SELECT ENTITY -->
	<div>
		<div id="entity" *ngIf="showEntityMenu">
			<qoala-entity-selector-menu [entityId]="entityId" (selectedEntity)="selectedEntity($event)">
			</qoala-entity-selector-menu>
		</div>
	</div>

	<div id="main-container" *ngIf="!showEntityMenu">

		<!-- SELECT STORE -->
		<div id="store" *ngIf="entityId" [hidden]="storeFromQRCode">
			<label [ngStyle]="{color: dropdownFontColor}">{{storesLabel}}</label>
			<div class="select-container">
				<ng-select [searchable]="true && !disableSearch" [items]="availableStores" [virtualScroll]="true"
					bindValue="id" bindLabel="name" [placeholder]="storesPlaceHolder"
					notFoundText="{{'GLOBAL.NO_ITEMS_FOUND' | translate}}" (change)="itemSelected(2, $event)"
					[(ngModel)]="storeId" (scrollToEnd)="loadStores()" (search)="searchStores($event)"
					(clear)="clearStore()" (open)="selectOpen(2)" (blur)="selectBlur(2)" [loading]="loadingStores"
					#storesSelect>
					<ng-template ng-label-tmp let-item="item" let-clear="clear">
						<span class="ng-value-label">{{item.name |
							qmultilanguage:curLangCode:false:true}}</span>
						<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
					</ng-template>
					<ng-template ng-option-tmp let-item="item" let-index="id">
						<span class="ng-option-label">{{item.name |
							qmultilanguage:curLangCode:false:true}}</span>
						<span class="ng-option-description">{{item.description |
							qmultilanguage:curLangCode:false:true}}</span>
					</ng-template>
				</ng-select>
			</div>
		</div>

		<!-- MENU OPTIONS -->
		<div id="menu" class="dynamic-menu" *ngIf="navigationSettings && storeId">
			<div class="select-container dynamic-menu" *ngFor="let menu of navigationMenus; let level = index">
				<div class="menu">
					<div class="select-container">
						<label [ngStyle]="{color: dropdownFontColor}">{{menu.label |
							qmultilanguage:curLangCode:false:true }}</label>
						<ng-select [searchable]="true && !disableSearch" [virtualScroll]="true"
							bindLabel="nameOnDisplay"
							placeholder="{{menu.placeHolder | qmultilanguage:curLangCode:false:true}}"
							[items]="menu.items" (clear)="clearMenu(level)" (change)="itemSelected(3, $event, level)"
							[(ngModel)]="dynamicMenuModel[level]" id="dynamicMenuModel{{level}}" #menuSelect>
							<ng-template ng-label-tmp let-item="item" let-clear="clear">
								<span class="ng-value-label">{{item.nameOnDisplay |
									qmultilanguage:curLangCode:false:true}}</span>
								<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-index="id">
								<span class="ng-option-label">{{item.nameOnDisplay |
									qmultilanguage:curLangCode:false:true}}</span>
								<span class="ng-option-description">{{item.description |
									qmultilanguage:curLangCode:false:true}}</span>
							</ng-template>
						</ng-select>
					</div>
				</div>
			</div>
		</div>

		<!-- SELECT SERVICE -->
		<div id="service" *ngIf="entityId && storeId && showServiceMenu">
			<label [ngStyle]="{color: dropdownFontColor}">{{servicesLabel}}</label>
			<div class="select-container">
				<ng-select [searchable]="true && !disableSearch" [items]="availableServices" [virtualScroll]="true"
					bindValue="id" bindLabel="nameOnDisplay" [multiple]="false" [placeholder]="servicesPlaceHolder"
					notFoundText="{{'GLOBAL.NO_ITEMS_FOUND' | translate}}" (change)="itemSelected(4, $event)"
					[(ngModel)]="serviceId" (scrollToEnd)="loadServices()" (search)="searchServices($event)"
					(clear)="clearService()" (open)="selectOpen(3)" (blur)="selectBlur(3)" [loading]="loadingServices"
					#servicesSelect>
					<ng-template ng-label-tmp let-item="item" let-clear="clear">
						<span class="ng-value-label">{{item.nameOnDisplay |
							qmultilanguage:curLangCode:false:true}}</span>
						<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
					</ng-template>
					<ng-template ng-option-tmp let-item="item" let-index="id">
						<span class="ng-option-label">{{item.nameOnDisplay |
							qmultilanguage:curLangCode:false:true}}</span>
					</ng-template>
				</ng-select>
			</div>
		</div>

		<!-- CHECKBOX WITH CUSTOM COLOR -->
		<label [style.color]="fontColor" class="checkbox-container" id="priority"
			*ngIf="serviceId && hasPriority && fontColor">
			<input type="checkbox" name="priority" (change)="priorityChanged($event);" [checked]="isPrioritySelected">
			<span [style.border-color]="isPrioritySelected ? fontColor : '#8b8b8b'" class="checkbox">
				<div class="tick-1" [style.background-color]="fontColor"></div>
				<div class="tick-2" [style.background-color]="fontColor"></div>
			</span>
			<div class="side-label" [style.color]="fontColor">{{'NEW_TICKET.PRIORITY' | translate}}</div>
		</label>

		<!-- CHECKBOX WITHOUT CUSTOM COLOR -->
		<label class="checkbox-container" id="priority" *ngIf="serviceId && hasPriority && !fontColor">
			<input type="checkbox" name="priority" (change)="priorityChanged($event);" [checked]="isPrioritySelected">
			<span class="checkbox">
				<div class="tick-1 not-custom"></div>
				<div class="tick-2"></div>
			</span>
			<div class="side-label">{{'NEW_TICKET.PRIORITY' | translate}}</div>
		</label>

		<hr>

		<!-- CHECKBOX WITH CUSTOM COLOR -->
		<label [style.color]="fontColor" class="checkbox-container" id="sms-alerts"
			*ngIf="serviceId && hasSmsNotifications && fontColor" [hidden]="markAsReadOnly">
			<input type="checkbox" name="smsAlerts" (change)="smsAlertsChanged($event);" [value]="isSmsAlertsSelected"
				[attr.disabled]="markAsReadOnly ? true : null" [checked]="markAsReadOnly">
			<span [style.border-color]="isSmsAlertsSelected ? fontColor : '#8b8b8b'" class="checkbox">
				<div class="tick-1" [style.background-color]="fontColor"></div>
				<div class="tick-2" [style.background-color]="fontColor"></div>
			</span>
			<div class="side-label" [style.color]="fontColor">{{'NEW_TICKET.SMS_ALERTS' | translate}}</div>
		</label>

		<!-- CHECKBOX WITHOUT CUSTOM COLOR -->
		<label class="checkbox-container" id="sms-alerts" *ngIf="serviceId && hasSmsNotifications && !fontColor"
			[hidden]="markAsReadOnly">
			<input type="checkbox" name="smsAlerts" (change)="smsAlertsChanged($event);" [value]="isSmsAlertsSelected"
				[attr.disabled]="markAsReadOnly ? true : null" [checked]="markAsReadOnly">
			<span class="checkbox">
				<div class="tick-1 not-custom"></div>
				<div class="tick-2"></div>
			</span>
			<div class="side-label">{{'NEW_TICKET.SMS_ALERTS' | translate}}</div>
		</label>

		<!-- ACTION BUTTON -->
		<div id="results">
			<button id="take-ticket" [ngStyle]="{color: buttonFontColor, background: buttonBackgroundColor}"
				*ngIf="serviceId && !isSmsAlertsSelected" [debounceTime]="1000" (debounceClick)="getNewTicket()">
				<i class="fa fa-ticket" aria-hidden="true"></i>{{ 'NEW_TICKET.TAKE_TICKET' | translate }}
			</button>

			<button id="sms-settings" *ngIf="isSmsAlertsSelected"
				[ngStyle]="{color: buttonBackgroundColor, background: buttonFontColor}" (click)="gotoSmsSettings()">
				<i class="fa fa-ticket" aria-hidden="true"></i>{{ 'NEW_TICKET.SMS_SETTINGS' | translate }}
			</button>
		</div>

	</div>

	<!-- Swal Modal for Priority Confirmation -->
	<swal #priorityModal id="priorityModal" title="{{ 'PRIORITY.TITLE' | translate }}" [showCancelButton]="false"
		[showConfirmButton]="false" [showCloseButton]="false" customClass="priority-info">
		<div *swalPortal id="service-priority-info" class="msg-box">
			<img src="./assets/img/Priority_icons.svg" class="img-priority" />
			<p>
				{{ 'PRIORITY.TEXT' | translate }}
			</p>
			<button class="confirm-btn"
				[ngStyle]="{color: buttonFontColor, background: buttonBackgroundColor, 'border-color': buttonBackgroundColor}"
				(click)="confirmPriority()"> {{ 'PRIORITY.CONFIRM' | translate }}</button>
			<button class="cancel-btn"
				[ngStyle]="{color: buttonBackgroundColor, background: buttonFontColor, 'border-color': buttonBackgroundColor}"
				(click)="cancelPriority()"> {{ 'PRIORITY.CANCEL' | translate }}</button>
		</div>
	</swal>

</div>