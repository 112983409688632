/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class TicketTrackerErrorHandling
* @description
* Ticket Tracker Error Handling
* Created by Carlos.Moreira @ 2022/12/05
*/
// Third Party Components
import { TranslateService } from '@ngx-translate/core';

// Libraries Components
import { ApiErrors } from 'qore.models';
import { isNullOrUndefined } from 'qevo.utilities';

export class TicketTrackerErrorHandling {
	/**
	 * ================================================================================================================================
	 * Errors
	 * ================================================================================================================================
	*/

	/**
	 * Gets the error message according to the error
	 * @param error Api Errors
	 * @param translateService Translate Service
	 * @param defaultMessage Default message in case no resource exists and to use instead of unknown error
	 */
	static getErrorMessage(error: ApiErrors, translateService: TranslateService, defaultMessage?: string) {
		const errorMessage: string = translateService.instant(`ERRORS.${ApiErrors[error]}`, { errorCode: error });

		return isNullOrUndefined(errorMessage) || errorMessage === `ERRORS.${ApiErrors[error]}` ?
			isNullOrUndefined(defaultMessage) ?
				translateService.instant(`ERRORS.OtherErrors`, { errorCode: error }) :
				translateService.instant(defaultMessage, { errorCode: error }) :
			errorMessage;
	}
}
