/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @module TicketTrackerModule
 * @description
 * Ticket Tracker Module
 * Created by Carlos.Moreira @ 2019/07/02
 */
// Angular Components
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

// Sweet alert
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// ZXing Scanner
import { ZXingScannerModule } from '@zxing/ngx-scanner';

// Angular ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// Qore Environments
import { environment } from '../environments/environment.local';

// Qore Bookings Agendas
import { QoreBookingsAgendasModule } from 'qore.bookings.agendas';

// Ticket Tracker Components
import { CoreModuleCore } from './core/core.module';
import { QoalaTicketTrackerComponent } from './ticket-tracker/qoala-ticket-tracker/qoala-ticket-tracker.component';
import { QoalaTicketTrackerRoutingModule } from './qoala-ticket-tracker-routing.module';
import { CardComponent } from './ticket-tracker/components/card/card.component';
import { ItemTrackingComponent } from './ticket-tracker/components/item-tracking/item-tracking.component';
import { CallingPanelComponent } from './ticket-tracker/components/calling-area/calling-panel/calling-panel.component';
import { CallingAreaComponent } from './ticket-tracker/components/calling-area/calling-area.component';
import { TrackMenuComponent } from './ticket-tracker/components/track-menu/track-menu.component';
import { TicketSelectorComponent } from './ticket-tracker/components/ticket-selector/ticket-selector.component';
import { MainMenuComponent } from './ticket-tracker/components/main-menu/main-menu.component';
import { NewTicketComponent } from './ticket-tracker/components/new-ticket/new-ticket.component';
import { NewBookingComponent } from './ticket-tracker/components/new-booking/new-booking.component';
import { ScheduleBookingMenuComponent } from './ticket-tracker/components/schedule-booking-menu/schedule-booking-menu.component';
import { MyItemsComponent } from './ticket-tracker/components/my-items/my-items.component';
import { SmsSettingsComponent } from './ticket-tracker/components/sms-settings/sms-settings.component';
import { QRCodeScannerComponent } from './ticket-tracker/components/qr-code-scanner/qr-code-scanner.component';
import { TakeTicketMenuComponent } from './ticket-tracker/components/take-ticket-menu/take-ticket-menu.component';
import { EntitySelectorMenuComponent } from './ticket-tracker/components/entity-selector-menu/entity-selector-menu.component';
import { EditBookingComponent } from './ticket-tracker/components/edit-booking/edit-booking.component';
import { ValidateBookingComponent } from './ticket-tracker/components/validate-booking/validate-booking.component';

// Libraries
import { QevoUtilitiesModule } from 'qevo.utilities';
import { QevoComponentsModule } from 'qevo.components';

@NgModule({
	declarations: [
		QoalaTicketTrackerComponent,
		CardComponent,
		ItemTrackingComponent,
		CallingPanelComponent,
		CallingAreaComponent,
		TrackMenuComponent,
		MainMenuComponent,
		NewTicketComponent,
		NewBookingComponent,
		ScheduleBookingMenuComponent,
		MyItemsComponent,
		TicketSelectorComponent,
		SmsSettingsComponent,
		QRCodeScannerComponent,
		TakeTicketMenuComponent,
		EntitySelectorMenuComponent,
		EditBookingComponent,
		ValidateBookingComponent,
	],
	imports: [
		QevoUtilitiesModule,
		QevoComponentsModule,
		BrowserModule,
		QoalaTicketTrackerRoutingModule,
		CoreModuleCore.forRoot(),
		ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
		SweetAlert2Module.forRoot(),
		ZXingScannerModule,
		NgSelectModule,
		QoreBookingsAgendasModule
	],
	providers: [],
	bootstrap: [
		QoalaTicketTrackerComponent
	]
})
export class QoalaTicketTrackerModule { }
