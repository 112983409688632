/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class TicketTrackerBookingsActionsStateMachine
* @description
* Ticket Tracker Bookings Actions State Machine
* Created by André.Pinho & Iuri.Cunha @ 2022/10/21
*/

// Library Components
import { BookingStatusEnum, BookingActionEnum } from 'qore.bookings.models';
import { isNullOrUndefined } from 'qevo.utilities';

export class TicketTrackerBookingsActionsStateMachine {
	/***
	 * Get all possible Actions that can be made for a specific booking (according to it's state)
	 * @param status Booking status
	 * @param hasForm Booking has a form
	 */
	static getPossibleActions(status: BookingStatusEnum, hasForm: boolean = false): BookingActionEnum[] {
		switch (status) {
			case BookingStatusEnum.Booked:
				return [
					BookingActionEnum.Reschedule, BookingActionEnum.Cancel,
					hasForm ? BookingActionEnum.Update : null
				].filter(action => !isNullOrUndefined(action));

			case BookingStatusEnum.Confirmed:
				return [
					BookingActionEnum.CheckIn, BookingActionEnum.Reschedule,
					BookingActionEnum.Cancel,
					hasForm ? BookingActionEnum.Update : null
				].filter(action => !isNullOrUndefined(action));

			case BookingStatusEnum.Refused:
				return [BookingActionEnum.Reschedule, BookingActionEnum.Cancel];

			default:
				return [];
		}
	}
}
