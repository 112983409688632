/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class EntitySelectorMenuComponent
 * @description
 * Entity Selector Component
 * Created by André.Pinho @ 2021/08/03
 */

// Angular Components
import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { share } from 'rxjs/operators';

// Ticket Tracker Components
import { NgSelectComponent } from '@ng-select/ng-select';
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';

// Libraries Components
import { TicketTrackerNavigationSettings, OperationalEntity, TicketTrackerSettings } from 'qore.models';
import { LoggerService } from 'qevo.services';
import { isNullOrUndefined } from 'qevo.utilities';

@Component({
	selector: 'qoala-entity-selector-menu',
	templateUrl: './entity-selector-menu.component.html',
	styleUrls: ['./entity-selector-menu.component.scss']
})
export class EntitySelectorMenuComponent implements OnInit {

	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Component Name
	public componentName: string;

	// Input entity Id
	@Input() entityId: string;

	// Flag to filter only bookings entities from backend (null will return both with and without booking enabled)
	@Input() getBookingsEntities?: boolean = null;

	// Output Emitter
	@Output() selectedEntity: EventEmitter<any> = new EventEmitter<any>();

	// Current language
	curLangCode: string;

	// List of entities
	availableEntities: OperationalEntity[];
	loadingEntities: boolean;
	@ViewChild('entitiesSelect') entitiesSelect: NgSelectComponent;

	// User selection EntityId
	entity: OperationalEntity;

	// Entity Settings
	navigationSettings: TicketTrackerNavigationSettings;

	// Settings object
	ticketTrackerSettings: TicketTrackerSettings;

	// Last user full search
	hasLastFullSearchInfo: boolean;

	private _lastEntityId: number;

	// Flag that indicates if it's the first time the page is loaded and we need to take in account the route
	// parameters passed in router
	// Added by Carlos.Moreira @ 2020/04/28
	private _firstTime: boolean;

	// Disable search to avoid showing mobile keyboard
	public disableSearch = true;

	// UI function exports
	isNullOrUndefined: typeof isNullOrUndefined = isNullOrUndefined;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		private _logger: LoggerService,
		private _ticketTrackerService: TicketTrackerService
	) {
		this.componentName = 'EntitySelectorMenuComponent';
	}

	ngOnInit() {
		this._firstTime = true;

		// Set height
		setTimeout(() => {
			const scrollHost: any = this.entitiesSelect.element.getElementsByClassName('scroll-host')?.item(0);
			if (!isNullOrUndefined(scrollHost)) {
				scrollHost.style.height = (window.innerHeight - (window.innerHeight <= 430 ? 100 : 170)) + 'px';
			}
		}, 100);

		// Load Entities
		this.loadEntities();
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */

	entityInfoEmit(entity: OperationalEntity) {
		this.selectedEntity.emit(entity);
	}

	/**
	 * ================================================================================================================================
	 * Lists
	 * ================================================================================================================================
	 */

	/**
	 * Event when the user selects/changes an item
	 * @param item item selected
	 */

	itemSelected(item: any) {
		this.entity = null;
		if (isNullOrUndefined(item)) {
			this.entity = null;
		} else {
			this.entityInfoEmit(item);
		}

		// Reset the flag so that the last full search is only done once
		this.hasLastFullSearchInfo = false;
	}

	/**
	 * Clear Entity selected button pressed
	 */
	clearEntity() {
		this.entity = null;
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Load Entities
	 */
	private loadEntities() {
		this._logger.debug(`${this.componentName}:loadEntities`);

		this.loadingEntities = true;

		// Reset Ticket Tracker Settings
		this.ticketTrackerSettings = null;
		this._ticketTrackerService.setTicketTrackerSettings(null);
		this._ticketTrackerService.setTermsAndConditionsUrl(null);

		// Get information from the database (now injecting if we want entities with or without bookings)
		this._ticketTrackerService.tickets.getListOfEntities(this.getBookingsEntities)
			.pipe(share())
			.subscribe(
				result => {
					this._logger.info(`${this.componentName}:loadEntities`, 'Result returned', result);

					this.availableEntities = result.items;

					// Filter available entities to remove duplicates
					// Added by André.Pinho @ 2021/08/04
					this.availableEntities = this.availableEntities.filter(
						(element, index, self) => index === self.findIndex((obj) => obj.id === element.id));

					// If an entity is found in route process it
					if (this.entityId && this._firstTime) {
						// Find entity in entities list
						const entitySelected = this.availableEntities.find(entity => entity.id === parseInt(this.entityId, 10));

						if (!isNullOrUndefined(entitySelected) && !isNullOrUndefined(entitySelected.settings)) {
							this.ticketTrackerSettings = JSON.parse(entitySelected.settings.toString()).ticketTrackerSettings;
						}

						// If exists, select it
						if (!isNullOrUndefined(entitySelected)) {
							this.itemSelected(entitySelected);
						}

						this._firstTime = false;

					} else {

						// If last entity searched or only one entity ... fill the next step :)
						if (this.availableEntities.length === 1) {
							this.itemSelected(this.availableEntities[0]);

						} else {

							if (this.hasLastFullSearchInfo && this.availableEntities.some(entity => entity.id === this._lastEntityId)) {
								this.itemSelected(this.availableEntities.find(entity => entity.id === this._lastEntityId));
							}
						}
					}
				},
				error => {
					this._logger.error(`${this.componentName}:loadEntities`, 'Result returned', error);
					this.availableEntities = null;

					// In case of error, set firstTime flag to false
					this._firstTime = false;

					this.loadingEntities = false;
				},
				() => {
					this.loadingEntities = false;
				}
			);
	}

}
