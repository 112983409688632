/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class QRCodeScannerComponent
 * @description
 * QR Code Reader Component
 * Created by Carlos.Moreira @ 2020/04/17
 */
// Angular Components
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';

// Third Party
import { TranslateService } from '@ngx-translate/core';

// ZXing Scanner
import { BarcodeFormat } from '@zxing/library';

// Ticket Tracker Components
import { CoreConfig } from '../../../core/core.config';
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';
import { QRCodeType } from '../../../core/enums/qr-code/qr-code-type.interface';
import { TicketTrackerStatus } from '../../../core/enums/state/ticket-tracker-status.interface';

// Libraries Components
import { isNullOrUndefined } from 'qevo.utilities';
import { LoggerService, LanguageService } from 'qevo.services';

@Component({
	selector: 'qoala-tt-qr-code-scanner',
	templateUrl: './qr-code-scanner.component.html',
	styleUrls: ['./qr-code-scanner.component.scss'],
	encapsulation: ViewEncapsulation.None // Disable CSS encapsulation
})
export class QRCodeScannerComponent implements OnInit, OnDestroy {

	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */
	// Component
	protected componentName: string;

	// Show scanner in UI
	showScanner: boolean;

	// List of available media devices
	availableDevices: MediaDeviceInfo[];

	// Current Device
	public _currentDevice: MediaDeviceInfo = null;
	currentDeviceId = null;

	get currentDevice(): MediaDeviceInfo {
		return this._currentDevice;
	}

	set currentDevice(device: MediaDeviceInfo) {
		this._currentDevice = device;
		this.currentDeviceId = isNullOrUndefined(device) ? null : device.deviceId;
	}

	// Has devices?
	hasDevices: boolean;

	// Change Device?
	changeDevice = false;

	// Possible Scanner barcode formats
	formatsEnabled: BarcodeFormat[] = [BarcodeFormat.QR_CODE];

	// Has permissions?
	hasPermission: boolean;

	// Torch settings
	torchEnabled = false;
	torchAvailable$ = new BehaviorSubject<boolean>(false);

	tryHarder = false;

	// Ticket Unique Id read
	ticketUniqueId: string = undefined;

	// Take new item (ticket or booking) information (array with entity id, store id and service id)
	takeNewItemInfo: number[];

	// Is QR Code valid
	isQRCodeValid: boolean = undefined;

	// Type of QR Code Type that we are going to read
	qrCodeType: QRCodeType;

	// Router Subscription
	private _routerSub: Subscription;

	// Language Change Subscription
	protected _onLanguageChangeSub: Subscription;

	// Save URL Result
	navigateURL: string;

	// Goto label form going to the entity, store or service while taking a new ticket or booking
	takeNewTicketOrBookingGotoLabel: string;
	private _takeNewTicketOrBookingGotoKey: string; // internal resource key

	// UI function exports
	isNullOrUndefined: typeof isNullOrUndefined = isNullOrUndefined;
	QRCodeType: typeof QRCodeType = QRCodeType;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(private _logger: LoggerService, private _ticketTrackerService: TicketTrackerService,
		private _coreConfig: CoreConfig, private _activatedRoute: ActivatedRoute,
		private _translateService: TranslateService, private _languageService: LanguageService) {

		// Gets the components name
		this.componentName = 'QRCodeScannerComponent';
	}

	ngOnInit() {
		// Subscribe parameters for getting qr code type
		this._routerSub = this._activatedRoute.params.subscribe(params => {
			this.qrCodeType = isNullOrUndefined(params.type) ? QRCodeType.Ticket : parseInt(params.type, 10) as QRCodeType;

			this._logger.info(`${this.componentName}:ngOnInit`, `QR Code type to scan is ${QRCodeType[this.qrCodeType]}`);

			// Set state according to Qr Code Type
			this._ticketTrackerService.ticketTrackerState = {
				status: this.qrCodeType === QRCodeType.FlyerNewTicket ? TicketTrackerStatus.ScanQrFlyerNewTicket :
					this.qrCodeType === QRCodeType.FlyerNewBooking ? TicketTrackerStatus.ScanQrFlyerNewBooking :
						TicketTrackerStatus.ScanTicketQrCode
			};

			this.checkBrowserSupport();
		});

		// Watch for language changes
		this._onLanguageChangeSub = this._languageService.onLanguageChange$
			.subscribe(() => {
				this.takeNewTicketOrBookingGotoLabel = this._translateService.instant(
					this._takeNewTicketOrBookingGotoKey ?? 'QR_CODE_SCANNER.SCAN_QR_CODE');
			});
	}

	ngOnDestroy() {
		// Unsubscribe
		if (!isNullOrUndefined(this._routerSub)) { this._routerSub.unsubscribe(); }
		if (!isNullOrUndefined(this._onLanguageChangeSub)) { this._onLanguageChangeSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */

	/**
	 * Event that sends us the list of cameras found
	 * @param devices List of available devices
	 */
	onCamerasFound(devices: MediaDeviceInfo[]): void {
		this.availableDevices = devices;
		this.hasDevices = Boolean(devices && devices.length);

		if (this.hasDevices) {
			setTimeout(() => {
				this.onDeviceSelectChange(this._currentDevice);
			}, 500);
		}
	}

	/**
	 * Events that is launch when a QR Code is read
	 * @param resultString result of the QR Code read
	 */
	onCodeResult(resultString: string) {
		this._logger.debug(`${this.componentName}:onCodeResult`, `QR Code type to scan is ${QRCodeType[this.qrCodeType]}`,
			`QRCode Read is ${resultString}`);

		if (isNullOrUndefined(resultString)) {
			// Empty address
			this._logger.warn(`${this.componentName}:onCodeResult`, `Empty QRCode ${resultString}`);

			this.isQRCodeValid = false;

		} else {
			// Save navigation url
			this.navigateURL = resultString;

			const ticketTrackerUrl: string = this._coreConfig.configurations.ticketTrackerUrl +
				(this._coreConfig.configurations.ticketTrackerUrl.endsWith('/') ? '' : '/');

			// Check type of QRCode
			switch (this.qrCodeType) {
				case QRCodeType.Ticket:
					if (resultString.indexOf(ticketTrackerUrl) === 0) {
						this.ticketUniqueId = resultString.replace(ticketTrackerUrl, '');

						this._logger.debug(`${this.componentName}:onCodeResult`,
							`QRCode is good and corresponds to ticket with unique id ${this.ticketUniqueId} [${resultString}]`);

						this.isQRCodeValid = true;

					} else {
						// Invalid or empty address
						this._logger.warn(`${this.componentName}:onCodeResult`, `Invalid QRCode ${resultString}`);

						this.isQRCodeValid = false;
					}
					break;

				case QRCodeType.FlyerNewTicket:
				case QRCodeType.FlyerNewBooking:
					// Possible addresses
					const takeNewTicketUrl: string = ticketTrackerUrl + 'new-ticket/';
					const takeNewBookingUrl: string = ticketTrackerUrl + 'new-booking/';

					if (resultString.indexOf(takeNewTicketUrl) === 0 || resultString.indexOf(takeNewBookingUrl) === 0) {
						// Get new item information
						this.takeNewItemInfo = this.qrCodeType === QRCodeType.FlyerNewTicket ?
							resultString.replace(takeNewTicketUrl, '').split('/').map(Number) :
							resultString.replace(takeNewBookingUrl, '').split('/').map(Number);

						// Detect values passed
						if (this.takeNewItemInfo.length === 3) { // Entity Id / Store Id / Service Id
							this._logger.debug(`${this.componentName}:onCodeResult`,
								`QRCode is good and corresponds to take a new ticket for ${this.takeNewItemInfo.join('/')} [${resultString}]`);

							this.takeNewTicketOrBookingGotoLabel = this._translateService.instant('QR_CODE_SCANNER.GOTO_SERVICE');
							this._takeNewTicketOrBookingGotoKey = 'QR_CODE_SCANNER.GOTO_SERVICE';

							this.isQRCodeValid = true;

						} else if (this.takeNewItemInfo.length === 2) {	// Entity Id / Store Id
							this._logger.debug(`${this.componentName}:onCodeResult`,
								`QRCode is good and corresponds to take a new ticket for ${this.takeNewItemInfo.join('/')} [${resultString}]`);

							this.takeNewTicketOrBookingGotoLabel = this._translateService.instant('QR_CODE_SCANNER.GOTO_STORE');
							this._takeNewTicketOrBookingGotoKey = 'QR_CODE_SCANNER.GOTO_STORE';

							this.isQRCodeValid = true;

						} else if (this.takeNewItemInfo.length === 1) {	// Entity Id
							this._logger.debug(`${this.componentName}:onCodeResult`,
								`QRCode is good and corresponds to take a new ticket for ${this.takeNewItemInfo.join('/')} [${resultString}]`);

							this.takeNewTicketOrBookingGotoLabel = this._translateService.instant('QR_CODE_SCANNER.GOTO_ENTITY');
							this._takeNewTicketOrBookingGotoKey = 'QR_CODE_SCANNER.GOTO_ENTITY';

							this.isQRCodeValid = true;

						} else {
							// Invalid or empty address
							this._logger.warn(`${this.componentName}:onCodeResult`, `Invalid QRCode ${resultString}`);

							this.isQRCodeValid = false;
						}

					} else {
						// Invalid or empty address
						this._logger.warn(`${this.componentName}:onCodeResult`, `Invalid QRCode ${resultString}`);

						this.isQRCodeValid = false;
					}
					break;
			}
		}
	}

	/**
	 * Event that occurs when user selects a device from the list
	 * @param deviceSelected device selected
	 */
	onDeviceSelectChange(deviceSelected: MediaDeviceInfo) {
		this.currentDevice = isNullOrUndefined(deviceSelected) ? null : deviceSelected;
		this.currentDeviceId = isNullOrUndefined(deviceSelected) ? null : deviceSelected.deviceId;
	}

	/**
	 * Event that indicates if the device is compatible with torch/light
	 * @param isCompatible Is device compatible?
	 */
	onTorchCompatible(isCompatible: boolean): void {
		this.torchAvailable$.next(isCompatible || false);
	}

	/**
	 * Event that occurs when the user is given or denied permission to access to the devices
	 * @param has Has permission?
	 */
	onHasPermission(has: boolean) {
		this.hasPermission = has;
	}

	/**
	 * Enables or disables device torch/light
	 */
	toggleTorch(): void {
		this.torchEnabled = !this.torchEnabled;
	}

	/**
	 * ================================================================================================================================
	 * Navigation
	 * ================================================================================================================================
	 */

	/**
	 * Goes to the ticket tracking or new-ticket page
	 */
	actionButtonClick() {
		if (this.qrCodeType === QRCodeType.Ticket) {
			this._ticketTrackerService.gotoPage([this.ticketUniqueId]);

		} else {
			// Navigate - New bookings or new tickets
			this._ticketTrackerService.gotoPage(
				[
					this.qrCodeType === QRCodeType.FlyerNewBooking ? 'new-booking' : 'new-ticket',
					...this.takeNewItemInfo
				]);

		}
	}

	/**
	 * Goes back to the alternative page when no access to camera exists
	 */
	noCameraAccessGoBackButtonClick() {
		if (this.qrCodeType === QRCodeType.Ticket) {
			this._ticketTrackerService.gotoPage(['ticket-selector']);
		} else {
			this._ticketTrackerService.gotoPage(['new-ticket']);
		}
	}

	/**
	 * ================================================================================================================================
	 * Private
	 * ================================================================================================================================
	 */

	/**
	 * Check if browser supports media devices API
	 */
	private checkBrowserSupport() {
		// Check browser support
		if (!isNullOrUndefined(navigator.mediaDevices) || !isNullOrUndefined(MediaDevices)) {
			this.showScanner = true;
		} else {
			this.showScanner = false;
			this._logger.warn(`${this.componentName}:checkBrowserSupport`, `Browser doesn't support media devices API`);
		}
	}
}
