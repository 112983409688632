<div id="view">

	<h2 id="main-title">{{ 'SCHEDULE_BOOKING_MENU.SELECT_AN_OPTION' | translate }}</h2>

	<div id="main-container">

		<div id="center">
			<div id="scan-flyer">
				<button [routerLink]="['../qr-code-scanner/2']">{{ 'SCHEDULE_BOOKING_MENU.SCAN_FLYER_QR_CODE' |
					translate}}</button>
				<p> {{ 'SCHEDULE_BOOKING_MENU.SCAN_FLYER_QR_CODE_INFO' | translate }}</p>
			</div>

			<div id="find-service">
				<button [routerLink]="['../new-booking']">{{ 'SCHEDULE_BOOKING_MENU.SELECT_SERVICE' |
					translate}}</button>
				<p> {{ 'SCHEDULE_BOOKING_MENU.SELECT_SERVICE_INFO' | translate }}</p>
			</div>
		</div>

	</div>
</div>