/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @function Translation Loader factory
 * @description
 * To retrieve the resources files - use each of the overloads to load resources specific to each App
 * Created by Carlos.Moreira @ 2019/07/02
 */

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

/**
 * Ticket Tracker Resources
 * @param httpClient HttpClient
 */
export function TicketTrackerHttpLoaderFactory(httpClient: HttpClient) {
	return HttpLoaderFactory(httpClient, '');
}

// Config for @ngx-translate
function HttpLoaderFactory(httpClient: HttpClient, app: string | null) {
	return new TranslateHttpLoader(httpClient, 'assets/i118n/' + (app === null ? '' : app + '/'), '.json');
}

