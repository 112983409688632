/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * CardStatusLevel
 * @description
 * Card Status Level enumerator
 * Created by Carlos.Moreira @ 2019/07/24
 */

export enum CardStatusLevel {
	Disabled = -1,
	Information = 0,
	Warning = 1,
	Alert = 2
}
