/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * EntityCustomizationType
 * @description
 * Entity Customization Type enumerator
 * Created by Carlos.Moreira @ 2022/12/13
 */

/**
 * Indicates the entity customization type
 */
export enum EntityCustomizationType {
    /**
     * None
     */
    None = 0,
    /**
     * Tickets Customization
     */
    Tickets = 1,
    /**
     * Bookings Customization
     */
    Bookings = 2
}
