/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class MyItemsService
 * @description
 * My Items Service
 * Created by Carlos.Moreira @ 2020/03/27
 */

// Angular Components
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { share, map, catchError } from 'rxjs/operators';

// Third Parties
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

// Ticket Tracker Components
import { CoreConfig } from '../../core.config';
import { MyItem } from '../../models/items/my-item.interface';
import { MyLocalItem } from '../../models/items/my-local-item.interface';

// Libraries Components
import { BaseEmptyService, LoggerService, UIMessagesService, ErrorHandling } from 'qevo.services';
import { ArrayUtilities, StringUtilities } from 'qevo.utilities';
import { TakeTicketRule, TakeBookingRule, PublicTicketOrBookingStatus } from 'qore.models';
import { isNullOrUndefined } from 'qevo.utilities';
import { PublicBookingStatusEnum, ServiceTypeEnum, TicketStatusEnum } from 'qevo.models';
import { BookingDetails } from 'qore.bookings.models';

@Injectable()
export class MyItemsService extends BaseEmptyService<any> {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Cookie settings
	private _storageKey = 'QoalaTicketTrackerStorageList';
	private _cookieExpirationTime = 90;
	private _maxCookieSize = 4000;

	// List of my local items in storage
	private _storageList: MyLocalItem[];

	// Returns if the store is empty
	public get storageIsEmpty(): boolean {
		return isNullOrUndefined(this._storageList) || this._storageList.length === 0 ? true : false;
	}

	// Is window local storage available ? if not fallback to cookies that are limited by 4 Kb of data.
	private _localStorageIsAvailable: boolean;

	// Indicates the max number of tickets a user can take and respective rule (if null or undefined, no limit exists)
	public maxNumberOfTakenTickets?: number;
	public takeTicketRule: TakeTicketRule;

	// Indicates the max number of bookings a user can take and respective rule (if null or undefined, no limit exists)
	public maxNumberOfTakenBookings?: number;
	public takeBookingRule: TakeBookingRule;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		http: HttpClient, logger: LoggerService, private _cookieService: CookieService, _coreConfig: CoreConfig,
		private _uiMessagesService: UIMessagesService, private _translateService: TranslateService
	) {
		super(http as any, logger);

		// Set serviceName
		this.serviceName = 'MyItemsService';

		// Base url settings
		this._baseUrl = _coreConfig.configurations.gatewayUrl +
			(_coreConfig.configurations.gatewayUrl.endsWith('/') ? '' : '/');

		// Loads user items from storage list
		this.loadStorageList();

		// Take ticket rule and respective max number of taken ticket
		this.takeTicketRule = _coreConfig.configurations.takeTicketRule ?? TakeTicketRule.None;
		this.maxNumberOfTakenTickets = _coreConfig.configurations.maxNumberOfTakenTickets;

		// Take booking rule and respective max number of taken ticket
		this.takeBookingRule = _coreConfig.configurations.takeBookingRule ?? TakeBookingRule.None;
		this.maxNumberOfTakenBookings = _coreConfig.configurations.maxNumberOfTakenBookings;

		// Initialize UI message service
		this._uiMessagesService.initialize();

		this._logger.info(`${this.serviceName}:Constructor`, 'Items List', this._storageList,
			'Local storage is available?', this._localStorageIsAvailable,
			'Take ticket rule', TakeTicketRule[this.takeTicketRule],
			'Max number of taken tickets', this.maxNumberOfTakenTickets,
			'Take booking rule', TakeBookingRule[this.takeBookingRule],
			'Max number of taken bookings', this.maxNumberOfTakenBookings);
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	 */

	/**
	 * Gets and updates the list of tickets and bookings from then server
	 * @returns List of MyItem objects
	 */
	getItemsFromServer(): Observable<MyItem[]> {
		// Url
		const url = this._baseUrl + `v6/ticketTracker/ticketsAndBookings/status`;

		this._logger.debug(`${this.serviceName}:getItemsFromServer`, 'Url', url, 'Body: ', JSON.stringify(this._storageList));

		return this._http.post<PublicTicketOrBookingStatus[]>(url, this._storageList)
			.pipe(
				share(),
				map((response: PublicTicketOrBookingStatus[]) => {
					this._logger.debug(`${this.serviceName}:getItemsFromServer`, 'Url', url,
						'Body: ', JSON.stringify(this._storageList), 'Response', response);

					// Process and return items
					return this.processMyItems(response);
				}),
				catchError(error => ErrorHandling.handleError(error, this._logger))
			);
	}

	/**
	 * Adds a new item (ticket or booking)
	 * @param newItem New Item taken
	 */
	addItem(newItem: MyLocalItem): void {
		this._logger.debug(`${this.serviceName}:addItem`, 'Storage List', this._storageList, 'New Item', newItem);

		// If null initialize array of my items
		if (isNullOrUndefined(this._storageList)) {
			this._storageList = [];
		}

		// Check to see if new item already exists in list (in case of a reschedule this can happen)
		const indexOfNewItem = this._storageList.findIndex(item => item.id === newItem.id && item.type === newItem.type);

		if (indexOfNewItem >= 0) {
			// Remove the current one and add this one in it's place
			this._storageList.splice(indexOfNewItem, 1, newItem);
		} else {
			// Add to top of array
			this._storageList.unshift(newItem);
		}

		// Save ticket to storage
		this.updateStorageList();
	}

	/**
	 * Indicates if the item with a respective unique id was taken by this user
	 * @param guid Guid
	 * @returns boolean
	 */
	isMyItem(guid: string): boolean {
		this._logger.debug(`${this.serviceName}:isMyItem`, 'Storage List', this._storageList, 'Guid', guid);

		// Check if requested item belongs to user storage list
		const result = isNullOrUndefined(this._storageList) ? false :
			this._storageList.some(item => item.id.toUpperCase() === guid?.toUpperCase());

		this._logger.info(`${this.serviceName}:isMyItem`, 'Storage List', this._storageList, 'Guid', guid,
			'Is My Item?', result);

		return result;
	}

	/**
	 * Check cookie size availability
	 * @returns boolean
	 */
	public checkCookieSize(): boolean {
		// Return if cookie is full or not
		return StringUtilities.byteLength(JSON.stringify(this._storageList)) < this._maxCookieSize;
	}

	/**
	 * Remove expired items
	 */
	public removeExpiredItemsAndUpdateStorage(myItems: PublicTicketOrBookingStatus[]) {
		// Ids of items to be removed
		const itemIdsToRemove: string[] = [];

		// If null or empty then exit
		if (isNullOrUndefined(this._storageList) || this._storageList.length === 0) {
			// Notify user there are no items to be removed
			this._uiMessagesService.showToaster('', this._translateService.instant('MY_TICKETS.NO_ITEMS_TO_REMOVE'), 'info');
			// Exit
			return;
		}

		// Remove expired items
		myItems.forEach((item: PublicTicketOrBookingStatus) => {
			// If it's booking in end of life state, then add the id to be removed...
			// Otherwise check if it's a ticket in end of life so id is also added for removal...
			// Otherwise check if item is before today so we can force delete it also no matter the state
			if (item.type !== ServiceTypeEnum.Booking &&
				(item.status === PublicBookingStatusEnum.GaveUp || item.status === PublicBookingStatusEnum.Finished)) {
				// Add booking id to be removed
				itemIdsToRemove.push(item.bookingCode);

			} else if (item.status === TicketStatusEnum.GaveUp || item.status === TicketStatusEnum.Finished) {
				// Add ticket id to be removed
				itemIdsToRemove.push(item.ticketUniqueId);

			} else if (moment(item.start).isBefore(moment())) {
				// Add item id to be removed
				itemIdsToRemove.push(item.ticketUniqueId);
			}
		});

		// Filter flagged ids from items list
		this._storageList = this._storageList.filter(item => !itemIdsToRemove.includes(item.id));

		// If there were ids then notify user of success... otherwise notify that there were no items to be removed
		if (itemIdsToRemove.length > 0) {
			// Notification toast
			this._uiMessagesService.showToaster('', this._translateService.instant('MY_TICKETS.REMOVED_SUCCESS'), 'success');
		} else {
			// Notification toast
			this._uiMessagesService.showToaster('', this._translateService.instant('MY_TICKETS.NO_ITEMS_TO_REMOVE'), 'info');
		}

		// Save my items to storage
		this.updateStorageList();
	}

	/**
	 * ================================================================================================================================
	 * Tickets
	 * ================================================================================================================================
	 */

	/**
	 * Indicates if the user can take more tickets for a specific Entity/Store/Service
	 * Rules:
	 * 	- total number of tickets
	 *  - number of tickets by store
	 *  - number of ticket by entity
	 * @param entityId Entity Id
	 * @param storeId Store Id
	 * @param serviceId Service Id
	 * @param takeTicketRule Entity/Store/Service take ticket rule
	 * @param maxNumberOfTakenTickets Entity/Store/Service max number of taken tickets
	 * @returns boolean
	 */
	canTakeTicket(entityId: number, storeId: number, serviceId: number,
		takeTicketRule: TakeTicketRule, maxNumberOfTakenTickets: number): boolean {

		this._logger.debug(`${this.serviceName}:canTakeTicket`,
			'Entity Id', entityId, 'Store Id', storeId,
			'Service Id', serviceId,
			'Take Ticket Rule?', TakeTicketRule[takeTicketRule], 'Max Number of Tickets?', maxNumberOfTakenTickets,
			'Storage List', this._storageList);

		// No my items, so return true
		if (this.storageIsEmpty) { return true; }

		// Enable the use of -1 in max number of taken tickets as a "all" tickets value like null
		// If max number of taken tickets is null or -1, return true because it means all
		// Added by Carlos.Moreira @ 2020/07/09
		if (isNullOrUndefined(maxNumberOfTakenTickets) || maxNumberOfTakenTickets === -1) {
			return true;
		}

		// Otherwise, validate to see if it's possible to add take tickets
		const tickets: MyLocalItem[] = this._storageList
			.filter((item: MyLocalItem) => item.type !== ServiceTypeEnum.Booking);

		switch (takeTicketRule) {
			case TakeTicketRule.ByTotalNumberOfTickets:
				return tickets.length <= maxNumberOfTakenTickets - 1 ? true : false;

			case TakeTicketRule.ByNumberOfTicketsByEntity:
				return tickets.filter(item =>
					item.entityId === entityId).length <= maxNumberOfTakenTickets - 1 ?
					true : false;

			case TakeTicketRule.ByNumberOfTicketsByStore:
				return tickets.filter(item =>
					item.entityId === entityId &&
					item.storeId === storeId).length <= maxNumberOfTakenTickets - 1 ?
					true : false;

			case TakeTicketRule.ByNumberOfTicketsByService:
				return tickets.filter(item =>
					item.entityId === entityId &&
					item.storeId === storeId &&
					item.serviceId === serviceId).length <= maxNumberOfTakenTickets - 1 ?
					true : false;
		}

		return true;
	}

	/**
	 * ================================================================================================================================
	 * Bookings
	 * ================================================================================================================================
	 */


	/**
	 * Indicates if the user can take more bookings for a specific Entity/Store/Service
	 * Rules:
	 * 	- total number of bookings
	 *  - number of bookings by store
	 *  - number of bookings by entity
	 * @param entityId Entity Id
	 * @param storeId Store Id
	 * @param serviceId Service Id
	 * @param takeBookingRule Entity/Store/Service take booking rule
	 * @param maxNumberOfTakenBookings Entity/Store/Service max number of taken bookings
	 * @param booking In case of a reschedule pass where the booking
	 * @returns boolean
	 */
	canTakeBooking(entityId: number, storeId: number, serviceId: number, takeBookingRule: TakeBookingRule,
		maxNumberOfTakenBookings: number, booking: BookingDetails): boolean {

		this._logger.debug(`${this.serviceName}:canTakeBooking`,
			'Entity Id', entityId, 'Store Id', storeId,
			'Service Id', serviceId,
			'Take Booking Rule?', TakeBookingRule[takeBookingRule], 'Max Number of Bookings?', maxNumberOfTakenBookings,
			'Storage List', this._storageList, 'Booking', booking);

		// No my items, so return true
		if (this.storageIsEmpty) { return true; }

		// Enable the use of -1 in max number of taken bookings as a "all" bookings value like null
		// If max number of taken bookings is null or -1, return true because it means all
		// Added by Carlos.Moreira @ 2020/07/09
		if (isNullOrUndefined(maxNumberOfTakenBookings) || maxNumberOfTakenBookings === -1) {
			return true;
		}

		// Otherwise, validate to see if it's possible to take bookings
		const bookings: MyLocalItem[] = this._storageList
			.filter((item: MyLocalItem) => item.type === ServiceTypeEnum.Booking);

		switch (takeBookingRule) {
			case TakeBookingRule.ByTotalNumberOfBookings:
				// If is a reschedule always return true
				return !isNullOrUndefined(booking) || bookings.length <= maxNumberOfTakenBookings - 1 ? true : false;

			case TakeBookingRule.ByNumberOfBookingsByEntity:
				// If is a reschedule and the entity is the same, return true
				return (!isNullOrUndefined(booking) && booking.entityId === entityId) ||
					bookings.filter(item =>
						item.entityId === entityId).length <= maxNumberOfTakenBookings - 1 ?
					true : false;

			case TakeBookingRule.ByNumberOfBookingsByStore:
				// If is a reschedule and the entity and store are the same, return true
				return (!isNullOrUndefined(booking) && booking.entityId === entityId && booking.storeId === storeId) ||
					bookings.filter(item =>
						item.entityId === entityId &&
						item.storeId === storeId).length <= maxNumberOfTakenBookings - 1 ?
					true : false;

			case TakeBookingRule.ByNumberOfBookingsByService:
				// If is a reschedule and the entity, store and service are the same, return true
				return (!isNullOrUndefined(booking) &&
					booking.entityId === entityId && booking.storeId === storeId && booking.serviceId === serviceId) ||
					bookings.filter(item =>
						item.entityId === entityId &&
						item.storeId === storeId &&
						item.serviceId === serviceId).length <= maxNumberOfTakenBookings - 1 ?
					true : false;
		}

		return true;
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Removes expired items from user list, updates storage list (saves it to storage) and returns it
	 */
	private processMyItems(ticketOrBookings: PublicTicketOrBookingStatus[]): MyItem[] {
		// If null or empty then exit
		if (isNullOrUndefined(ticketOrBookings) || ticketOrBookings.length === 0) { return []; }

		const myItems: MyItem[] = [];

		let itemsRemoved: boolean = null;

		// Process tickets received by:
		//	1. removed expired Items
		//	2. add uniqueId and timestamp
		//	3. add them to storage
		ticketOrBookings.forEach((ticketOrBooking: PublicTicketOrBookingStatus) => {
			let timestamp: string = null;
			let uniqueId: string;

			switch (ticketOrBooking.type) {
				case ServiceTypeEnum.Booking:
					if (ticketOrBooking.status !== TicketStatusEnum.NotFound) {
						const convertedStart: moment.Moment = moment(ticketOrBooking.start);
						timestamp = convertedStart.format('YYYYMMDDHHmm');
						timestamp = convertedStart.isAfter(moment()) ? 'f' + timestamp : timestamp;
					}

					uniqueId = ticketOrBooking.bookingCode;
					break;
				default:
					if (ticketOrBooking.status !== PublicBookingStatusEnum.NotFound) {
						timestamp = timestamp = moment(ticketOrBooking.printingHour).format('YYYYMMDDHHmm');
					}
					uniqueId = ticketOrBooking.ticketUniqueId;
					break;
			}

			// If it has timestamp means ticket or booking is not expired
			if (isNullOrUndefined(timestamp)) {
				itemsRemoved = itemsRemoved ?? true;
			} else {
				myItems.push(Object.assign(ticketOrBooking,
					{
						uniqueId: uniqueId,
						timestamp: timestamp
					}));
			}
		});

		// Sort my items list from newest to oldest
		ArrayUtilities.reOrder(myItems, 'timestamp', undefined, true);

		// Save my items to storage
		this.updateStorageList(myItems);

		// Notify user with toast if there were expired items
		if (itemsRemoved) {
			this._uiMessagesService.showToaster('', this._translateService.instant('MY_TICKETS.REMOVED_SUCCESS'), 'success');
		}

		return myItems;
	}

	/**
	 * Load storage list
	 */
	private loadStorageList() {
		// Check to see if there is local storage available (enables bigger data to be saved)
		this._localStorageIsAvailable = window.localStorage ? true : false;

		// Load items from browser storage
		if (this._localStorageIsAvailable) {
			this._storageList = JSON.parse(window.localStorage.getItem(this._storageKey) ?? '[]');

		} else {
			this._storageList = this._cookieService.get(this._storageKey) === '' ? [] :
				JSON.parse(this._cookieService.get(this._storageKey));

		}

		this._logger.debug(`${this.serviceName}:loadStorageList`, 'Use Local Storage?', this._localStorageIsAvailable,
			'Storage List', this._storageList);
	}

	/**
	 * Update items list in storage
	 */
	private updateStorageList(myItems?: MyItem[]) {
		this._logger.debug(`${this.serviceName}:updateStorageList`, 'My Items?', myItems, 'Local Storage', this._storageList);

		// If items are passed convert them to local my items and add them
		if (!isNullOrUndefined(myItems)) {
			this._storageList = myItems.map((myItem: MyItem) => {
				const id: string = myItem.type === ServiceTypeEnum.Booking ?
					myItem.bookingCode : myItem.ticketUniqueId;

				return {
					id: id,
					type: myItem.type,
					storeId: myItem.storeId,
					entityId: myItem.entityId,
					serviceId: myItem.serviceId,
					timestamp: myItem.timestamp
				};
			});
		}

		// Push storage list array to storage
		if (this._localStorageIsAvailable) {
			window.localStorage.setItem(this._storageKey, JSON.stringify(this._storageList));

		} else {
			// Try to fit all possible items by discarding older ones if size of cookie is greater that 3500 bytes
			if (!isNullOrUndefined(this._storageList) && this._storageList.length !== 0) {
				while (!this.checkCookieSize()) {
					// Remove one item from bottom
					this._storageList.pop();
				}
			}

			// Set cookie with storage list
			this._cookieService.set(this._storageKey, JSON.stringify(this._storageList),
				moment().add(this._cookieExpirationTime, 'days').toDate()
			);
		}

		// Logger
		this._logger.info(`${this.serviceName}:updateStorageList`, 'Storage List', this._storageList);
	}
}
