<div id="view">

	<!-- BACK BUTTON -->
	<button id="back" [ngStyle]="{color: backFontColor, background: backBackgroundColor}" *ngIf="!showEntityMenu"
		(click)="goBackToEntitySelection()"> <i class="fa fa-arrow-left" aria-hidden="true"></i></button>
	<div id="view-header">
		<h2 id="main-title" *ngIf="!showLogo">{{ 'TICKET_SELECTOR.TITLE' | translate }}</h2>
	</div>
	<!-- LOGO -->
	<img *ngIf="showLogo" [src]="logoSrc" />

	<!-- SELECT ENTITY -->
	<div>
		<div id="entity" *ngIf="showEntityMenu">
			<qoala-entity-selector-menu (selectedEntity)="selectedEntity($event)"></qoala-entity-selector-menu>
		</div>
	</div>

	<div id="main-container" *ngIf="!showEntityMenu">

		<!-- SELECT STORE -->
		<div id="store" *ngIf="entityId">
			<label [ngStyle]="{color: dropdownFontColor}">{{storesLabel}}</label>
			<div class="select-container">
				<ng-select [searchable]="true && !disableSearch" [items]="availableStores" [virtualScroll]="true"
					bindValue="id" bindLabel="name" [placeholder]="storesPlaceHolder"
					notFoundText="{{'GLOBAL.NO_ITEMS_FOUND' | translate}}" (change)="itemSelected(2, $event)"
					[(ngModel)]="storeId" (scrollToEnd)="loadStores()" (search)="searchStores($event)"
					(clear)="clearStore()" (open)="selectOpen(2)" (blur)="selectBlur(2)" [loading]="loadingStores"
					#storesSelect>
					<ng-template ng-label-tmp let-item="item" let-clear="clear">
						<span class="ng-value-label">{{item.name |
							qmultilanguage:curLangCode:false:true}}</span>
						<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
					</ng-template>
					<ng-template ng-option-tmp let-item="item" let-index="id">
						<span class="ng-option-label">{{item.name |
							qmultilanguage:curLangCode:false:true}}</span>
						<span class="ng-option-description">{{item.description |
							qmultilanguage:curLangCode:false:true}}</span>
					</ng-template>
				</ng-select>
			</div>
		</div>

		<!-- SELECT TICKET -->
		<div id="ticket" *ngIf="entityId && storeId">
			<label [ngStyle]="{color: dropdownFontColor}">{{'TICKET_SELECTOR.TICKET' | translate}}</label>
			<div class="select-container">
				<ng-select [searchable]="true && !disableSearch" [items]="availableTickets" [virtualScroll]="true"
					bindValue="ticketUniqueId" bindLabel="ticketFormated" [multiple]="false"
					placeholder="{{'TICKET_SELECTOR.CHOOSE_TICKET' | translate}}"
					notFoundText="{{'GLOBAL.NO_ITEMS_FOUND' | translate}}" (change)="itemSelected(3, $event)"
					[(ngModel)]="ticketUniqueId" (scrollToEnd)="loadTickets()" (search)="searchTickets($event)"
					(clear)="clearTicket()" (open)="selectOpen(3)" (blur)="selectBlur(3)" [loading]="loadingTickets"
					#ticketsSelect>
					<ng-template ng-label-tmp let-item="item" let-clear="clear">
						<span class="ng-value-label">{{item.ticketFormated}}</span>
						<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
					</ng-template>
					<ng-template ng-option-tmp let-item="item" let-index="ticketUniqueId">
						<span class="ng-option-label">{{item.ticketFormated}}</span>
						<span class="ng-option-printing-hour">({{item.ticketPrintingHour
							| qmoment:'D MMM YYYY
							HH:mm':curLangCode}})</span>
					</ng-template>
				</ng-select>
			</div>
		</div>

		<!-- ACTION BUTTON -->
		<div id="results">
			<button [ngStyle]="{color: buttonFontColor, background: buttonBackgroundColor}" *ngIf="entityId && storeId"
				[class.disabled]="isNullOrUndefined(ticketUniqueId)" [debounceTime]="500"
				(debounceClick)="gotoTicketTracking()">
				<i class="fa fa-ticket" aria-hidden="true"></i>{{ 'TICKET_SELECTOR.TRACK_TICKET' | translate }}
			</button>
		</div>

	</div>

</div>