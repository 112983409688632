<div id="view">
	<h2 id="main-title">{{ 'MAIN_MENU.TITLE' | translate }}</h2>
	<div id="main-container">
		<div id="center">
			<button type="button" id="new-ticket" [routerLink]="['./take-ticket-menu']">{{ 'MAIN_MENU.NEW_TICKET' |
				translate }}</button>
			<button type="button" id="new-booking" [routerLink]="['./schedule-booking-menu']">{{ 'MAIN_MENU.NEW_BOOKING'
				| translate }}</button>
			<button type="button" id="track-ticket" [routerLink]="['./track-menu']">{{ 'MAIN_MENU.TRACK_TICKET' |
				translate }}</button>
			<button type="button" id="my-items" [routerLink]="['./my-items']">{{ 'MAIN_MENU.MY_TICKETS_AND_BOOKINGS'
				| translate }}</button>
		</div>
	</div>
</div>