<div id="calling-area-container" [class.maximize]="isScrollingUp">
	<!-- lOADING PANEL -->
	<div id="initial-panel" class="calling-panel" *ngIf="!callingPanelsToShow || callingPanelsToShow.length === 0">
		<span id="first-sentence">{{'TICKET_TRACKER.ELEMENTS.CALLING_PANEL.NO_TICKET_AVAILABLE' | translate}}</span>
		<span id="second-sentence">{{'TICKET_TRACKER.ELEMENTS.CALLING_PANEL.WILL_BE_UPDATED' | translate}}</span>
	</div>

	<!-- CALLING PANELS -->
	<qoala-tt-calling-panel class="calling-panel" *ngFor="let callingPanel of callingPanelsToShow"
		[ticket]="callingPanel.ticket" [curLangCode]="curLangCode" [isScrollingUp]="isScrollingUp"
		[showCounter]="showCounter"></qoala-tt-calling-panel>

	<!-- AUDIO SOURCE -->
	<audio #audioDingDong preload="auto">
		<source *ngFor="let dingDongTrack of dingDongAudioTracks" [src]="dingDongTrack.src" [type]="dingDongTrack.type">
	</audio>
</div>
