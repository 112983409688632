/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class EditBookingComponent
 * @description
 * Edit Booking Component
 * Created by André.Pinho @ 2022/11/24
*/

// Angular Components
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

// Third Party Components
import { TranslateService } from '@ngx-translate/core';

// Shared Components
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';
import { TicketTrackerStatus } from '../../../core/enums/state/ticket-tracker-status.interface';
import { TicketTrackerState } from '../../../core/models/state/ticket-tracker-state.interface';
import { TicketTrackerMenuButton } from '../../../core/enums/menu/ticket-tracker-menu-button.interface';
import { TicketTrackerBookingsActionsStateMachine } from '../../../core/others/bookings/ticket-tracker-booking-actions-state-machine';

// Libraries Components
import { isNullOrUndefined } from 'qevo.utilities';
import { ServiceTypeEnum } from 'qevo.models';
import { LanguageService, LoggerService, UIMessagesService } from 'qevo.services';
import { BookingActionEnum, BookingDetails, BookingFormField, BookingUpdate, FieldTypeEnum } from 'qore.bookings.models';
import { BookingDetailFormStatus } from 'qore.bookings.agendas';

@Component({
	selector: 'qoala-tt-edit-booking',
	templateUrl: './edit-booking.component.html',
	styleUrls: ['./edit-booking.component.scss']
})
export class EditBookingComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Component Name
	protected componentName: string;

	// Booking To Edit Form
	bookingToEdit: BookingDetails;

	// Edit Mode
	editMode: boolean;

	// Reset Form
	resetForm: boolean;

	// New Form Data to send
	newFormData: BookingFormField[] = [];

	// Ticket Tracker State subscription
	private _ticketTrackerStateSub: Subscription;

	// Language Change Subscription
	private _onLanguageChangeSub: Subscription;

	// Current language
	curLangCode: string;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(private _ticketTrackerService: TicketTrackerService, private _languageService: LanguageService,
		private _logger: LoggerService, private _uiMessagesService: UIMessagesService, private _translateService: TranslateService) {

		// Set component Name
		this.componentName = 'EditBookingComponent';
	}

	ngOnInit(): void {
		// Watch for language changes
		this._onLanguageChangeSub = this._languageService.onLanguageChange$.subscribe(language => {
			this.curLangCode = language.code;
		});

		// Booking to Edit Form
		this.bookingToEdit = this._ticketTrackerService.ticketTrackerState.bookingInfo;

		if (isNullOrUndefined(this.bookingToEdit)) {
			this._ticketTrackerService.gotoPage();
			return;
		}

		// Disable edit button in not in correct state
		const canEditForm: boolean = TicketTrackerBookingsActionsStateMachine
			.getPossibleActions(this.bookingToEdit.statusId, this.bookingToEdit.hasForm)
			.includes(BookingActionEnum.Update);

		if (!canEditForm) {
			// Disable edit button
			this._ticketTrackerService.setMenu({
				editButton: false
			});
		}

		// Watches for ticket tracker state changes and more specifically entity settings overrides
		// and acts accordingly (after selecting a user)
		this._ticketTrackerStateSub = this._ticketTrackerService.ticketTrackerState$.pipe(
			distinctUntilChanged(),
			filter(ticketTrackerState =>
				!isNullOrUndefined(ticketTrackerState) &&
				(ticketTrackerState.status === TicketTrackerStatus.EditBooking) &&
				!isNullOrUndefined(ticketTrackerState.menuButton)))
			.subscribe((ticketTrackerState: TicketTrackerState) => {
				// Ticket Tracker State changed
				this._logger.debug(`${this.componentName}:ngOnInit`, 'TicketTrackerState', ticketTrackerState,
					'Clicked button', ticketTrackerState.menuButton);

				// Set edit mode
				this.editMode = ticketTrackerState.isBookingFormInEditMode;

				switch (ticketTrackerState.menuButton) {
					case TicketTrackerMenuButton.Confirm:
						// Build Update Booking Object
						const bookingUpdate: BookingUpdate = { ...this.bookingToEdit, formFields: this.newFormData, reason: null, notes: null };

						this._ticketTrackerService.bookings.updateBooking(bookingUpdate)
							.subscribe(
								(bookingUpdated: BookingDetails) => {
									// Success toast
									this._uiMessagesService.showToaster('', this._translateService.instant('GLOBAL.CONFIRM_BOX.SUCCESS'),
										'success', null, null, null, null, 'bottom');

									// Update booking, leave edit mode and update menu/menu button
									this._ticketTrackerService.ticketTrackerState = {
										isBookingFormInEditMode: false,
										bookingInfo: bookingUpdated,
										status: TicketTrackerStatus.EditBooking
									};

									// Leave edit mode
									this._ticketTrackerService.setMenu({
										editButton: true,
										saveButton: false,
										cancelButton: false
									});
								},
								(error) => {
									this._logger.error(`${this.componentName}`, 'Edit Booking', 'Error', error);

									// Error toast
									this._ticketTrackerService.handleErrors(this._uiMessagesService, this._translateService,
										error, error.error);
								});
						break;

					case TicketTrackerMenuButton.Cancel:
						this.resetForm = true;

						// Leave edit mode
						this._ticketTrackerService.setMenu({
							editButton: true,
							saveButton: false,
							cancelButton: false
						});
						break;

					case TicketTrackerMenuButton.Edit:
						this.resetForm = false;

						// Enter in edit mode
						this._ticketTrackerService.setMenu({
							editButton: false,
							saveButton: true,
							cancelButton: true
						});
						break;

				}
			});
	}

	ngOnDestroy(): void {
		if (!isNullOrUndefined(this._onLanguageChangeSub)) { this._onLanguageChangeSub.unsubscribe(); }
		if (!isNullOrUndefined(this._ticketTrackerStateSub)) { this._ticketTrackerStateSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */

	/**
	 * Receive Form status
	 * @param formData
	 */
	receiveFormStatus(formData: BookingDetailFormStatus) {
		// this._ticketTrackerService.enableSaveButton(formData.status as string);
		this.newFormData = [];

		Object.entries(formData.data).forEach(formField => {
			this.newFormData.push({
				bookingId: this.bookingToEdit.id,
				fieldTypeId: FieldTypeEnum[formField[0]],
				value: formField[1] as string
			});
		});

		this._ticketTrackerService.setMenu({
			disableSaveButton: formData.status === 'VALID' ? false : true
		});
	}

	/**
	 * Go back to card
	 */
	goBackToCard() {
		this._ticketTrackerService.gotoPage([this.bookingToEdit.bookingCode, ServiceTypeEnum.Booking]);
	}
}
