<div id="view">

	<h2 id="main-title">{{ 'TRACK_MENU.SELECT_AN_OPTION' | translate }}</h2>

	<div id="main-container">

		<div id="center">
			<div id="scan-ticket">
				<button [routerLink]="['../qr-code-scanner/0']">{{ 'TRACK_MENU.SCAN_TICKET_QR_CODE' | translate }}</button>
				<p> {{ 'TRACK_MENU.SCAN_TICKET_QR_CODE_INFO' | translate }}</p>
			</div>

			<div id="find-ticket">
				<button [routerLink]="['../ticket-selector']">{{ 'TRACK_MENU.SELECT_TICKET_MANUALLY' | translate }}</button>
				<p> {{ 'TRACK_MENU.SELECT_TICKET_MANUALLY_INFO' | translate }}</p>
			</div>
		</div>

	</div>
</div>
