<div id="form-container" *ngIf="bookingToEdit">
	<!-- BACK BUTTON -->
	<button id="back" type="button" title="back" (click)="goBackToCard()"> <i class="fa fa-arrow-left"
			aria-hidden="true"></i></button>

	<!-- HEADER TITLE -->
	<div id="view-header">
		<h2 id="main-title">{{ 'TICKET_TRACKER.ELEMENTS.TICKET.FORM.TITLE' | translate }}</h2>
	</div>

	<!-- FORM -->
	<qore-bookings-booking-detail-form [agendaFormFields]="bookingToEdit.agendaFormFields"
		[formFields]="bookingToEdit.formFields" [curLangCode]="curLangCode" [editMode]="editMode"
		[bookingId]="bookingToEdit.id" [resetForm]="resetForm" [responsiveMode]="responsiveMode"
		(status)="receiveFormStatus($event)">
	</qore-bookings-booking-detail-form>
</div>