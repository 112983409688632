<div id="view">

	<h2 id="main-title">{{ 'QR_CODE_SCANNER.TITLE' | translate }}</h2>

	<div id="main-container">

		<div id="center">

			<!-- Camera -->
			<div id="camera" class="camera" [class.valid]="isQRCodeValid === true"
				[class.not-valid]="isQRCodeValid == false" [class.not-set]="isNullOrUndefined(hasPermission)">

				<zxing-scanner *ngIf="showScanner" id="qr-scanner" [tryHarder]="tryHarder" [torch]="torchEnabled"
					[(device)]="_currentDevice" (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled"
					(permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)"
					(torchCompatible)="onTorchCompatible($event)">
				</zxing-scanner>

				<div *ngIf="isNullOrUndefined(currentDeviceId)" id="no-camera"><i class="fa fa-camera"></i></div>
			</div>

			<div id="container">
				<!-- Actions -->
				<div id="actions">
					<div id="torch" (click)="toggleTorch()" *ngIf="torchAvailable$ | async">
						<i class="fa fa-flash"></i>
					</div>
					<div id="devices" *ngIf="hasDevices" (click)="changeDevice=!changeDevice">
						<i class="fa fa-camera" aria-hidden="true"></i>
					</div>
					<div id="log">

						<span id="warning-instructions" *ngIf="isNullOrUndefined(hasPermission)">
							<p><b>{{ 'QR_CODE_SCANNER.WAITING_FOR_PERMISSIONS' | translate }}</b></p>
							<p>{{ 'QR_CODE_SCANNER.WAITING_FOR_PERMISSIONS_INFO' | translate }}</p>

							<button *ngIf="qrCodeType !== QRCodeType.Ticket" id="find-service"
								(click)="noCameraAccessGoBackButtonClick()">{{
								'TAKE_TICKET_MENU.SELECT_SERVICE_MANUALLY' | translate }}</button>

							<button *ngIf="qrCodeType === QRCodeType.Ticket" id="find-ticket"
								(click)="noCameraAccessGoBackButtonClick()">{{ 'TRACK_MENU.SELECT_TICKET_MANUALLY' |
								translate }}</button>
						</span>

						<p *ngIf="hasPermission === false">{{ 'QR_CODE_SCANNER.NO_PERMISSION' | translate }}</p>

						<p *ngIf="hasPermission && isNullOrUndefined(hasDevices)">
							{{ 'QR_CODE_SCANNER.DEVICES_CHECK_ERROR' | translate }}</p>

						<p *ngIf="hasDevices === false">{{ 'QR_CODE_SCANNER.NO_DEVICES' | translate }}</p>
					</div>
				</div>

				<!-- Camera selection -->
				<div id="camera-selection">
					<div class="select-container">
						<ng-select *ngIf="changeDevice" [searchable]="false" [items]="availableDevices"
							[virtualScroll]="true" bindValue="deviceId" bindLabel="label"
							placeholder="{{'QR_CODE_SCANNER.CHOOSE_CAMERA' | translate}}"
							notFoundText="{{'GLOBAL.NO_ITEMS_FOUND' | translate}}"
							(change)="onDeviceSelectChange($event)" [(ngModel)]="currentDeviceId">
							<ng-template ng-label-tmp let-item="item" let-clear="clear">
								<span class="ng-value-label">{{item.label}}</span>
								<span class="ng-value-icon right" (click)="onDeviceSelectChange(item)"
									aria-hidden="true">×</span>
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-index="deviceId">
								<span class="ng-option-label">{{item.label}}</span>
							</ng-template>
						</ng-select>
					</div>
				</div>

				<!-- Results -->
				<div id="action-results">
					<button *ngIf="hasPermission" id="action-button" [class.disabled]="!isQRCodeValid"
						[class.take-ticket]="qrCodeType !== QRCodeType.Ticket" (click)="actionButtonClick()">

						<ng-container *ngIf="qrCodeType === QRCodeType.Ticket">
							<i class="fa fa-ticket" aria-hidden="true"></i>{{ 'QR_CODE_SCANNER.TRACK_TICKET' | translate
							}}
						</ng-container>

						<ng-container *ngIf="qrCodeType === QRCodeType.FlyerNewTicket">
							<i class="fa fa-ticket" aria-hidden="true"></i>{{ takeNewTicketOrBookingGotoLabel }}
						</ng-container>

						<ng-container *ngIf="qrCodeType === QRCodeType.FlyerNewBooking">
							<i class="fa fa-book" aria-hidden="true"></i>{{ takeNewTicketOrBookingGotoLabel }}
						</ng-container>
					</button>
				</div>
			</div>


		</div>

	</div>

</div>