/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class TicketTrackerComponent
 * @description
 * Ticket Tracker Component
 * Created by Carlos.Moreira @ 2019/07/02
 */
// Angular Components
import { Component, ViewChild, Injector, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';

// Third Party Components
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import swal from 'sweetalert2';
import * as moment_ from 'moment';
const moment = moment_;
import { CookieService } from 'ngx-cookie-service';

// Ticket Tracker Components
import { CoreConfig } from '../../core/core.config';
import { TicketTrackerStatus } from '../../core/enums/state/ticket-tracker-status.interface';
import { TicketTrackerState } from '../../core/models/state/ticket-tracker-state.interface';
import { BaseEntityCustomization } from '../components/base-entity-customization/base-entity-customization';
import { TicketTrackerMenu } from '../../core/models/menu/ticket-tracker-menu.interface';
import { TicketTrackerMenuButton } from '../../core/enums/menu/ticket-tracker-menu-button.interface';
import { EntityCustomizationType } from '../../core/enums/customization/entity-customization-type.enum';

// Libraries Components
import { Language } from 'qevo.models';
import { UIMessagesService } from 'qevo.services';
import { isNullOrUndefined } from 'qevo.utilities';

// Google analytics
// eslint-disable-next-line no-var
declare var gtag;

@Component({
	selector: 'qoala-ticket-tracker',
	templateUrl: './qoala-ticket-tracker.component.html',
	styleUrls: ['./qoala-ticket-tracker.component.scss'],
	encapsulation: ViewEncapsulation.None // Disable CSS encapsulation
})
export class QoalaTicketTrackerComponent extends BaseEntityCustomization implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Get it here within intercept
	protected _router: Router;
	protected _activatedRoute: ActivatedRoute;
	protected _coreConfig: CoreConfig;
	protected _uiMessagesService: UIMessagesService;
	protected _sanitizer: DomSanitizer;
	protected _titleService: Title;

	/**
	 * ================================================================================================================================
	 * Languages - Start
	 * ================================================================================================================================
	 */
	// Translations - Sweet Alert Confirm boxes, Message boxes and toasters
	protected _toTranslate: Array<string> = new Array<string>();

	// Available languages
	public languages: Language[];

	@ViewChild('languagesPopup') private _languagesPopup: SwalComponent;

	/**
	 * ================================================================================================================================
	 * Languages - End
	 * ================================================================================================================================
	 */

	// Router Subscription
	private _routerSub: Subscription;

	// Are we in Home Page?
	isHome: boolean;

	// Current page address
	private _currentUrl: string;

	// Cookies compliance
	cookieCompliance = false;

	// Sound
	isSoundActive = false;

	// Version Information and Date
	versionInfo: string;
	versionDate: string;
	showVersionDate: boolean;

	// PWA Features
	showInstall: boolean;
	deferredPrompt: any;

	// Ticket Tracker State subscription
	private _ticketTrackerStateSub: Subscription;

	// Is notification Online Subscription
	private _isNotificationOnlineSub: Subscription;

	// Ticket Tracker Menu
	public menu: TicketTrackerMenu;

	// UI function exports
	isNullOrUndefined: typeof isNullOrUndefined = isNullOrUndefined;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(injector: Injector) {
		super('QoalaTicketTrackerComponent', injector);

		// Get services here with interceptor
		// Angular
		this._router = injector.get(Router);
		this._activatedRoute = injector.get(ActivatedRoute);
		this._cookieService = injector.get(CookieService);
		this._sanitizer = injector.get(DomSanitizer);
		this._titleService = injector.get(Title);

		// Ticket Tracker
		this._coreConfig = injector.get(CoreConfig);
		this._uiMessagesService = injector.get(UIMessagesService);
	}

	ngOnInit() {
		// Set initial language and watch for languages change
		this._languageService.start('QoalaTicketTrackerLang',
			this._coreConfig.configurations.availableLanguages, this._coreConfig.configurations.defaultLanguage,
			this._elRef.nativeElement, this._renderer);

		// Cookie compliance check
		this.cookieCompliance = this._cookieService.get('QoalaTicketTrackerCookieCompliance') ? true : false;

		// Version Info + Date
		this.showVersionDate = false;
		this.versionInfo = this._coreConfig.configurations.versionInfo.version;
		this.versionDate = this._coreConfig.configurations.versionInfo.date;

		// Fill languages
		this.languages = this._languageService.languages;

		// Watch for navigation events to adapt according to the current page
		this._routerSub = this._router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => this._router.url))
			.subscribe((event) => {
				// Save current url
				this._currentUrl = event;

				// Reset Ticket Tracker Settings at the service
				// if not in ticket selector, new ticket or sms settings page
				if (event.indexOf('ticket-selector') <= 0 &&
					event.indexOf('new-ticket') <= 0 &&
					event.indexOf('sms-settings') <= 0) {

					this.ticketTrackerSettings = null;
					this._ticketTrackerService.setTicketTrackerSettings(null);
					this._ticketTrackerService.setTermsAndConditionsUrl(null);
				}

				// Reset ticket or booking info if we are not going to new/reschedule booking page or edit booking page
				if (event.indexOf('reschedule-booking') <= 0 &&
					event.indexOf('new-booking') <= 0 &&
					event.indexOf('edit-booking') <= 0 &&
					!this.isInTrackingPage(event)) {
					this._ticketTrackerService.resetTicketAndBookingInfo();
				}

				// New Ticket? if so set customization type to Tickets
				if (event.indexOf('ticket-selector') > 0 || event.indexOf('new-ticket') > 0 || event.indexOf('sms-settings') > 0) {
					this.customizationType = EntityCustomizationType.Tickets;
				}

				// New or reschedule booking? if so set customization type to Bookings
				if (event.indexOf('new-booking') > 0 || event.indexOf('reschedule-booking') > 0) {
					this.customizationType = EntityCustomizationType.Bookings;
				}

				// Set page title (for better analysis in Google Analytics)
				this.setPageTitle(event);

				// Is Home page ?
				this.isHome = event === '/' ? true : false;

				// Google Analytics
				gtag('config', this._coreConfig.configurations.googleAnalyticsId, {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					page_path: (event as unknown as NavigationEnd).urlAfterRedirects
				});

				// Set menu options
				this.setMenu(event);

				// Set ticket tracker state - in components that are not using gotoPage method
				this.setTicketTrackerState(event);
			});

		// Handle PWA Events
		this.handlePWAEvents();

		// Watches for ticket tracker state changes and more specifically entity settings overrides
		// and acts accordingly (after selecting a user)
		this._ticketTrackerStateSub = this._ticketTrackerService.ticketTrackerState$.pipe(
			distinctUntilChanged(),
			filter(ticketTrackerState => !isNullOrUndefined(ticketTrackerState) && ticketTrackerState.menu !== this.menu))
			.subscribe((ticketTrackerState: TicketTrackerState) => {
				// Ticket Tracker State changed
				this._logger.debug(`${this.componentName}:ngOnInit`,
					'Ticket Tracker Status', TicketTrackerStatus[ticketTrackerState.status],
					'Menu', ticketTrackerState.menu, 'Current Address', this._currentUrl);

				// Update the menu and update the menu
				this.menu = ticketTrackerState.menu;
				this.applyMenuCustomization();
			});

		// Watch for isNotificationOnline changes
		this._isNotificationOnlineSub = this._ticketTrackerService.isNotificationOnline$.pipe(
			distinctUntilChanged(),
			filter(isOnlineFilter => !isOnlineFilter &&
				(
					this._ticketTrackerService.ticketTrackerState.status === TicketTrackerStatus.StartItemTracking ||
					this._ticketTrackerService.ticketTrackerState.status === TicketTrackerStatus.ShowAndTrackItem
				)
			))
			.subscribe(() => {
				this._logger.debug(`${this.componentName}:ngOnInit`,
					`The connection to server was lost [State: ${TicketTrackerStatus[this._ticketTrackerService.ticketTrackerState.status]}]`);

				// Send information message to UI, and reload on 'ok' button or close
				swal.fire(
					{
						title: '',
						text: this._translateService.instant(`ERRORS.LOST_CONNECTION_PLEASE_RETRY`),
						icon: 'info',
						customClass: {
							popup: 'msg-box'
						}
					}
				).then(() => {
					window.location.href = window.location.href;
				});
			});

	}

	ngOnDestroy(): void {
		// Unsubscribe
		if (!isNullOrUndefined(this._routerSub)) { this._routerSub.unsubscribe(); }
		if (!isNullOrUndefined(this._ticketTrackerStateSub)) { this._ticketTrackerStateSub.unsubscribe(); }
		if (!isNullOrUndefined(this._isNotificationOnlineSub)) { this._isNotificationOnlineSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods/Events
	 * ********************************************************************************************************************************
	 */

	/**
	 * Accept cookie compliance
	 */
	acceptCookieCompliance() {
		// Set cookie compliance for one year
		this._cookieService.set('QoalaTicketTrackerCookieCompliance', 'true',
			moment().add(1, 'years').toDate()
		);

		// Update UI value
		this.cookieCompliance = true;
	}

	/**
	 * ================================================================================================================================
	 *  Menu Actions
	 * ================================================================================================================================
	 */

	/**
	 * Toggle visibility of app version
	 */
	toggleVersion() {
		this.showVersionDate = true;
		setTimeout(() => {
			this.showVersionDate = false;
		}, 10 * 1000);
	}

	/**
	 * Goto to home page
	 */
	gotoHome() {
		// If we are in a new booking or reschedule we need to send it to parent before we go to home
		if (this._ticketTrackerService.ticketTrackerState.status === TicketTrackerStatus.NewBooking
			|| this._ticketTrackerService.ticketTrackerState.status === TicketTrackerStatus.RescheduleBooking) {
			this._ticketTrackerService.setButtonClicked(TicketTrackerMenuButton.Home);
		} else {
			// Go to home page
			this._ticketTrackerService.gotoPage();
		}
	}

	/**
	 * Is Sound Active click event
	 */
	isSoundActiveClick() {
		// Change value
		this.isSoundActive = !this.isSoundActive;

		// Set current value to "Is Sound Active" property
		this._ticketTrackerService.isSoundActive = this.isSoundActive;
	}

	/**
	 * Back button click
	*/
	backButtonClick() {
		this._ticketTrackerService.setButtonClicked(TicketTrackerMenuButton.Back);
	}

	/**
	 * Next Button Click
	 */
	nextButtonClick() {
		this._ticketTrackerService.setButtonClicked(TicketTrackerMenuButton.Next);
	}

	/**
	 * Enable Form Edition
	 */
	enableEditMode() {
		this._ticketTrackerService.setButtonClicked(TicketTrackerMenuButton.Edit);
	}

	/**
	 * Cancel edit booking changes
	 */
	cancelChanges() {
		this._ticketTrackerService.setButtonClicked(TicketTrackerMenuButton.Cancel);
	}

	/**
	 * Confirms edit booking changes
	 */
	confirmChanges() {
		this._ticketTrackerService.setButtonClicked(TicketTrackerMenuButton.Confirm);
	}

	/**
	 * ================================================================================================================================
	 *  Language Button and Language Popup Events
	 * ================================================================================================================================
	 */

	/**
	 * Shows the popup for selecting the app language
	 * @param event$ event data
	 */
	showLanguages(event$: any) {
		this._languagesPopup.fire();
	}

	/**
	 * Event that occurs when the language popup is open
	 * @param event$ event
	 */
	onOpenLanguages(event$: any) {
		// Variables
		this.applyLanguagePopupCustomization(event$.modalElement, this._ticketTrackerService.ticketTrackerSettings);
	}

	/**
	 * Event that occurs when the language popup is closed
	 * @param event$ event
	 */
	onCloseLanguages(event$: any) {
		setTimeout(() => {
			// Clear language popup customization
			this.clearLanguagePopupCustomization(event$.modalElement);
		}, 50);
	}

	/**
	 * Changes the app language
	 * @param languageCode Language code
	 */
	changeLanguage(languageCode: string) {
		this._logger.debug(`${this.componentName}:changeLanguage`, 'Language Code', languageCode);

		this._languageService.changeLanguage(languageCode);
		this.curLangCode = this._languageService.curLangCode;

		this._languagesPopup.dismiss();
	}

	/**
	 * Closes the languages
	 * @param event$ event data
	  */
	closeLanguages(event$: any) {
		this._languagesPopup.dismiss();
	}

	/**
	 * ================================================================================================================================
	 *  PWA
	 * ================================================================================================================================
	 */

	/**
	 * Install the PWA (add it to Home Screen)
	 * @param event$ event data
	 */
	public installPWA(event$: any) {
		// Hide install popup
		this.showInstall = false;

		// If null or nothing exit
		if (isNullOrUndefined(this.deferredPrompt)) { return; }

		// Show the prompt
		this.deferredPrompt.prompt();

		// Wait for the user to respond to the prompt
		this.deferredPrompt.userChoice
			.then((choiceResult: any) => {

				if (choiceResult.outcome === 'accepted') {
					this._logger.info(`${this.componentName}:installPWA`,
						`User accepted the Qoala Ticket Tracker prompt and wants to add to Home Screen`);

				} else {
					this._logger.warn(`${this.componentName}:installPWA`,
						`User dismissed the Qoala Ticket Tracker prompt and doesn't want to add to Home Screen`);

					// Set cookie of don't install_pwa for one day before asking again
					this._cookieService.set('QoalaTicketTrackerCookieDontInstallPWA', 'true',
						moment().add(24, 'hours').toDate()
					);
				}

				this.deferredPrompt = null;
			});
	}

	/**
	 * Don't install the PWA
	 * @param event$ event data
	 */
	public dontInstallPWA(event$: any) {
		this.showInstall = false;

		// Set cookie of don't install_pwa for one day before asking again
		this._cookieService.set('QoalaTicketTrackerCookieDontInstallPWA', 'true',
			new Date(new Date().setHours(24))
		);
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * ================================================================================================================================
	 *  PWA
	 * ================================================================================================================================
	 */
	private handlePWAEvents() {

		// 'beforeinstallprompt' event
		window.addEventListener('beforeinstallprompt', (e) => {
			this._logger.debug(`${this.componentName}:handlePWAEvents`, `Before install prompt event launch.`,
				'Don\'t install PWA cookie?',
				isNullOrUndefined(this._cookieService.get('QoalaTicketTrackerCookieDontInstallPWA')) ? false : true);

			// Prevent the mini-infobar from appearing on mobile
			e.preventDefault();

			// Stash the event so it can be triggered later.
			this.deferredPrompt = e;

			// Show install request if didn't already say not to install it in a 24 hour period
			if (isNullOrUndefined(this._cookieService.get('QoalaTicketTrackerCookieDontInstallPWA'))) {
				// Wait 30 seconds before requesting to install (so user can absorve the greatness of app)

				setTimeout(() => {
					this.showInstall = true;
				}, 30000);
			}
		});

		// 'appinstalled'
		window.addEventListener('appinstalled', (evt) => {
			this._logger.info(`${this.componentName}:handlePWAEvents`, `Qoala Ticket Tracker added to Home Screen.`);
		});

		// Add languages to cache :)
		// Added by Carlos.Moreira @ 2020/03/04
		this._languageService.addTranslationsToCache();
	}

	/**
	 * ================================================================================================================================
	 *  Page Title
	 * ================================================================================================================================
	 */

	/**
	 * Sets the page title
	 * @param url page address
	 */
	private setPageTitle(url: string): void {
		const pageTitle = 'QoalaTicketTracker';

		if (url === '/') {
			this._titleService.setTitle(pageTitle + ' - Home');
			return;
		}

		if (url.indexOf('ticket-selector') > 0) {
			this._titleService.setTitle(pageTitle + ' - Manual ticket selection');
			return;
		}

		if (url.indexOf('error') > 0) {
			this._titleService.setTitle(pageTitle + ' - Error');
			return;
		}

		if (url.indexOf('my-items') > 0) {
			this._titleService.setTitle(pageTitle + ' - My tickets and bookings');
			return;
		}

		if (url.indexOf('new-ticket') > 0) {
			this._titleService.setTitle(pageTitle + ' - New ticket');
			return;
		}

		if (url.indexOf('sms-settings') > 0) {
			this._titleService.setTitle(pageTitle + ' - New ticket with SMS alerts');
			return;
		}

		if (url.indexOf('track-menu') > 0) {
			this._titleService.setTitle(pageTitle + ' - Track ticket or booking menu');
			return;
		}

		if (url.indexOf('qr-code-scanner') > 0) {
			this._titleService.setTitle(pageTitle + (url.endsWith('0') ? ' - Scan Ticket QR Code' : ' - Scan Flyer QR Code'));
			return;
		}

		if (url.indexOf('take-ticket-menu') > 0) {
			this._titleService.setTitle(pageTitle + ' - Take ticket menu');
			return;
		}

		if (url.indexOf('new-booking') > 0) {
			this._titleService.setTitle(pageTitle + ' - New booking menu');
			return;
		}

		if (url.indexOf('reschedule-booking') > 0) {
			this._titleService.setTitle(pageTitle + ' - Reschedule booking');
			return;
		}
		if (url.indexOf('validate-booking') > 0) {
			this._titleService.setTitle(pageTitle + ' - Validate booking');
			return;
		}

		// Otherwise we are in tracking page
		this._titleService.setTitle(pageTitle + ' - Tracking ticket');
	}

	/**
	 * ================================================================================================================================
	 *  Menu
	 * ================================================================================================================================
	 */

	/**
	 * Calculates and sets the menu according to the address
	 * @param url address
	 */
	private setMenu(url: string) {
		let menu: TicketTrackerMenu;

		// New or Reschedule Booking
		if (url.indexOf('new-booking') > 0 || url.indexOf('reschedule-booking') > 0) {
			menu = {
				homeButton: true,
				nextButton: false,
				backButton: false,
				languageButton: true,
				qevo: true,
				soundButton: true
			};
		}

		// Edit Booking
		if (url.indexOf('edit-booking') > 0) {
			const isInEditMode = this._ticketTrackerService.ticketTrackerState.isBookingFormInEditMode;

			if (isInEditMode) {
				menu = {
					homeButton: true,
					editButton: false,
					languageButton: false,
					qevo: false,
					soundButton: false,
					saveButton: true,
					cancelButton: true
				};
			} else {
				menu = {
					homeButton: true,
					editButton: true,
					languageButton: false,
					qevo: false,
					soundButton: false,
					saveButton: false,
					cancelButton: false
				};
			}
		}

		// Error Page
		if (url.indexOf('error') > 0) {
			menu = {};
			return;
		}

		// All other pages
		if (isNullOrUndefined(menu)) {
			menu = {
				homeButton: true,
				languageButton: true,
				soundButton: true,
				qevo: true
			};
		}

		// Set menu to ticket tracker state
		this._ticketTrackerService.setMenu(menu, true);

		this._logger.debug(`${this.componentName}:setMenu`, 'Url', url, 'Menu', menu);
	}

	/**
	 * ================================================================================================================================
	 *  Menu
	 * ================================================================================================================================
	 */

	/**
	 * Calculates and sets the ticket tracker state according to the address
	 * @param url address
	 */
	private setTicketTrackerState(url: string) {
		let status: TicketTrackerStatus = null;

		if (url.indexOf('new-ticket') > 0 || url.indexOf('sms-settings') > 0) {
			status = TicketTrackerStatus.NewTicket;
		}

		if (url.indexOf('my-items') > 0) {
			status = TicketTrackerStatus.MyItems;
		}

		if (url.indexOf('ticket-selector') > 0) {
			status = TicketTrackerStatus.TicketSelection;
		}

		if (url.indexOf('schedule-booking-menu') > 0 || url.indexOf('take-ticket-menu') > 0 || url.indexOf('track-menu') > 0) {
			status = TicketTrackerStatus.Menus;
		}

		if (url.indexOf('ticket-selector') > 0) {
			status = TicketTrackerStatus.TicketSelection;
		}

		if (url.indexOf('validate-booking') > 0) {
			status = TicketTrackerStatus.ValidateBooking;
		}

		if (url.indexOf('/') > 0) {
			status = TicketTrackerStatus.Home;
		}

		// If is assigned
		if (!isNullOrUndefined(status)) {
			this._ticketTrackerService.ticketTrackerState = {
				status: status
			};
		}
	}

	/**
	 * Calculates if it's in the tracking page (by excluding all others)
	 * @param url address
	 */
	private isInTrackingPage(url: string): boolean {
		if (
			url === '/' &&
			url.indexOf('ticket-selector') === 0 &&
			url.indexOf('error') === 0 &&
			url.indexOf('my-items') === 0 &&
			url.indexOf('new-ticket') === 0 &&
			url.indexOf('sms-settings') === 0 &&
			url.indexOf('track-menu') === 0 &&
			url.indexOf('qr-code-scanner') === 0 &&
			url.indexOf('take-ticket-menu') === 0 &&
			url.indexOf('new-booking') === 0 &&
			url.indexOf('reschedule-booking') === 0 &&
			url.indexOf('validate-booking') === 0) {
			return true;
		}

		return false;
	}
}
