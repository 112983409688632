/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class CallingAreaComponent
 * @description
 * Calling Area Component
 * Created by Carlos.Moreira @ 2019/07/24
 */
// Angular Components
import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

// Third Party Components
import * as moment_ from 'moment';
const moment = moment_;

// Ticket Tracker Components
import { CallingPanelUI } from '../../../core/models/calling-panels/calling-panel-ui.interface';
import { CallingTicketUI } from '../../../core/models/ticket/calling-ticket-ui.interface';
import { DingDongAudio } from '../../../core/models/configuration/ding-dong-audio.interface';
import { CoreConfig } from '../../../core/core.config';
import { TicketTrackerStatus } from '../../../core/enums/state/ticket-tracker-status.interface';
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';

// Libraries Components
import { LoggerService } from 'qevo.services';
import { LogLevel } from 'qevo.models';
import { isNullOrUndefined } from 'qevo.utilities';

@Component({
	selector: 'qoala-tt-calling-area',
	templateUrl: './calling-area.component.html',
	styleUrls: ['./calling-area.component.scss'],
	encapsulation: ViewEncapsulation.None // Disable CSS encapsulation
})
export class CallingAreaComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */
	// Component
	protected componentName: string;

	// Language
	@Input() curLangCode: string;

	// Scroll
	@Input() isScrollingUp: boolean;

	// Show Counter?
	@Input() showCounter: boolean;

	// Sound?
	@Input() sound: boolean;

	// Audio volume (Percentage)
	@Input() audioVolume: number;

	// Number of panels
	@Input() numberOfCallingPanels: number;

	/**
	 * List of panels to show in the UI
	 */
	get callingPanelsToShow(): CallingPanelUI[] {
		return this._callingPanels.filter(callingPanel => isNullOrUndefined(callingPanel.isShown) || callingPanel.isShown);
	}

	// List of calling panels
	private _callingPanels: CallingPanelUI[];

	hasTickets$: Observable<boolean>;
	protected hasTickets: BehaviorSubject<boolean>;

	// Tickets Queue Watching interval
	private _ticketsQueueInterval: any;
	private _watchTicketsQueueTimeIntervalPeriod = 500;
	private _minTicketTimeOnPanel = 3600;

	// Clean panels timeout
	private _cleanPanelsTimeout: any;

	// Ding-dong audio object
	@ViewChild('audioDingDong', { static: true }) audioPlayerRef: ElementRef;

	private _dingDongAudio: HTMLAudioElement;
	public dingDongAudioTracks: DingDongAudio[];

	// Ticket Tracker State subscription
	protected _ticketTrackerStateSub: Subscription;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(private _coreConfig: CoreConfig, private _cdRef: ChangeDetectorRef,
		private _logger: LoggerService, private _ticketTrackerService: TicketTrackerService) {

		// Gets the components name
		this.componentName = 'CallingAreaComponent';

		// Default values
		this.isScrollingUp = false;
		this.showCounter = true;
		this.sound = false;
		this.audioVolume = 30;
		this.numberOfCallingPanels = 4;

		// Default values
		this._callingPanels = [];

		// Assigns all the ding-dong sound
		this.dingDongAudioTracks = this._coreConfig.configurations.dingDongAudios;

		// Attach observer to indication if exist ticket to show
		this.hasTickets = new BehaviorSubject(false);
		this.hasTickets$ = this.hasTickets.asObservable();
	}

	ngOnInit() {

		// Because this is scroll add a new empty panel as hidden,
		// so that we can have the list of services, counters or custom to assign tickets
		const firstPanel: CallingPanelUI = {
			id: 0,
			class: '',
			counters: [],
			services: [],
			isDeleted: false,
			isDisabled: false,
			isShown: false,
			layout: null,
			ticket: null,
		};

		this._callingPanels.push(firstPanel);

		// Detects when hasTickets flag changes
		this.hasTickets.pipe(
			distinctUntilChanged())
			.subscribe(hasTickets => {
				if (hasTickets) {
					// At least one ticket in queue, so start or continue to watch tickets queue
					this.startWatchingTicketsQueue();
				} else {
					// If not tickets in queue, stop watching tickets queue
					this.stopWatchingTicketsQueue();
				}
			});

		// Watch for Ticket Tracker State change
		this.watchForTicketTrackerStateChanges();

		// Start watching ding-dong events
		this.watchDingDongEvents();
	}

	ngOnDestroy(): void {
		// Stop watching tickets queue
		this.stopWatchingTicketsQueue();

		// Unsubscribe
		if (!isNullOrUndefined(this._ticketTrackerStateSub)) { this._ticketTrackerStateSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * ================================================================================================================================
	 * Ticket Queue
	 * ================================================================================================================================
	 */

	/**
	 * Starts watching tickets queue, so it can display all the tickets correctly inside it
	 */
	private startWatchingTicketsQueue() {
		// Clean panels timeout
		if (!isNullOrUndefined(this._cleanPanelsTimeout)) { clearTimeout(this._cleanPanelsTimeout); }

		if (isNullOrUndefined(this._ticketsQueueInterval)) {
			this._ticketsQueueInterval = setInterval(() => {
				// Show next tickets
				this.showNextTickets();

			}, this._watchTicketsQueueTimeIntervalPeriod);
		}
	}

	/**
	 * Stops watching tickets queue, because no ticket exist
	 */
	private stopWatchingTicketsQueue() {
		if (!isNullOrUndefined(this._ticketsQueueInterval)) {
			clearInterval(this._ticketsQueueInterval); this._ticketsQueueInterval = null;
		}
	}

	/**
	 * Shows the next tickets (according to the tickets queue)
	 */
	private showNextTickets() {
		// ===============================================================================================
		// 1. Go throw all available destination panels and see if there is a ticket to show
		// ===============================================================================================
		this._callingPanels
			.filter(
				callingPanelFilter =>
					(
						isNullOrUndefined(callingPanelFilter.availableOn) ||
						moment().diff(callingPanelFilter.availableOn, 'milliseconds') > 0
					) &&
					(!isNullOrUndefined(callingPanelFilter.tickets) && callingPanelFilter.tickets.length > 0)
			)
			.forEach(callingPanel => {
				this._logger.debug(`${this.componentName}:showNextTickets`, `Calling Panel '${callingPanel.id}'`,
					`Tickets to show [${callingPanel.tickets.map(t => t.ticketFormated).join(',').toString()}]`);

				// ===============================================================================================
				// 1.1.1. Clean stuff
				// 		- Because we are in scroll mode, push the availability of the panel to the future ...
				// ===============================================================================================
				if (!isNullOrUndefined(this._callingPanels[0])) {
					callingPanel.availableOn = moment().add(48, 'hours');

					this._cdRef.detectChanges();
				}

				// ===============================================================================================
				// 1.1.2. Get the first ticket in queue
				// ===============================================================================================
				const ticketToShow: CallingTicketUI = callingPanel.tickets.shift();

				// ===============================================================================================
				// 1.1.3. See if first panel is already shown:
				// 	- if yes:
				// 		- clone and add a new panel like the one that exists on top
				// 		- clean all the major properties of the previous first panel
				// 			> services, counters and tickets
				// 		- add ticket to show
				// 	- if no:
				// 		- add ticket to show and display the panel
				// ===============================================================================================
				if (this._callingPanels[0].isShown) {
					// 	- if yes:
					// 		- clone and add a new panel like the one that exists on top
					// 		- clean all the major properties of the previous first panel
					// 			> services, counters and tickets
					// 		- add ticket to show

					const newCallingPanel: CallingPanelUI =
						this.cloneResetAndAddTicketToCallingPanel(this._callingPanels[0], true, ticketToShow);

					// Add new calling panel to the beginning
					this._callingPanels.unshift(newCallingPanel);

					// ===============================================================================================
					// 1.1.3. Clean calling panels are greater than the available calling are pop the older calling panel
					// ===============================================================================================
					if (this._callingPanels.length > this.numberOfCallingPanels) {
						this._callingPanels.pop();
					}
				} else {
					// 	- if no:
					// 		- add ticket to show and display the panel
					this.addTicketToShowToCallingPanel(this._callingPanels[0], ticketToShow, false);
				}

				// Check to see if there any more tickets to show and update has ticket flag
				this.hasTickets.next(this._callingPanels.some(callingPanelFilter =>
					!isNullOrUndefined(callingPanelFilter.tickets) && callingPanelFilter.tickets.length > 0));
			});
	}

	/**
	 * Adds the ticket to show to the respective calling panel
	 * @param callingPanel calling panel
	 * @param ticket ticket to add and display
	 * @param blinkTimeout blink with timeout
	 */
	private addTicketToShowToCallingPanel(callingPanel: CallingPanelUI, ticket: CallingTicketUI, blinkTimeout: boolean) {
		callingPanel.isShown = true;
		callingPanel.ticket = ticket;

		callingPanel.availableOn = moment().add(this._minTicketTimeOnPanel, 'milliseconds');

		if (blinkTimeout) {
			setTimeout(() => {
				this.playDingDong();
				this._cdRef.detectChanges();
			}, 50);
		} else {
			this.playDingDong();
		}

		this._cdRef.detectChanges();

		this._logger.info(`${this.componentName}:addTicketToShowToCallingPanel`, `Calling Panel '${callingPanel.id}'`,
			`Ticket to Show '${ticket.ticketFormated}' until ${callingPanel.availableOn.format('HH:mm:ss.SSS')}`);
	}

	/**
	 * Clones and resets a calling panel, and if a ticket is passed assigns to the cloned panel the ticket
	 * @param callingPanel calling panel
	 * @param blinkTimeout blink with timeout
	 * @param ticket ticket to add and display
	 */
	private cloneResetAndAddTicketToCallingPanel(callingPanel: CallingPanelUI, blinkTimeout: boolean, ticket?: CallingTicketUI) {
		// Clone panel and assign new id
		const newCallingPanel: CallingPanelUI = { ...callingPanel };
		newCallingPanel.id = Math.max(...this._callingPanels.map(cp => cp.id)) + 1;

		// If a ticket is passed, assign it to the cloned panel
		if (!isNullOrUndefined(ticket)) {
			this.addTicketToShowToCallingPanel(newCallingPanel, ticket, blinkTimeout);
		}

		// Reset properties in panel used to clone
		// 	> services, counters and tickets
		callingPanel.services = null;
		callingPanel.counters = null;
		callingPanel.tickets = null;

		return newCallingPanel;
	}

	/**
	 * ================================================================================================================================
	 * Sound
	 * ================================================================================================================================
	 */

	/**
	 * Starts watches the ding-dong events sound and sets the base volume
	 */
	private watchDingDongEvents() {
		try {

			this._dingDongAudio = (this.audioPlayerRef.nativeElement as HTMLAudioElement);

			this._logger.debug(`${this.componentName}:watchDingDongEvents`,
				'Can play Mp3 ?', this._dingDongAudio.canPlayType('audio/mpeg'),
				'Can play Wav ?', this._dingDongAudio.canPlayType('audio/wav'),
				'Can play Ogg ?', this._dingDongAudio.canPlayType('audio/ogg'));

			this._logger.debug(`${this.componentName}:watchDingDongEvents`, 'Codec',
				// eslint-disable-next-line max-len
				'Can play Mp3 ?', !!this._dingDongAudio.canPlayType && this._dingDongAudio.canPlayType('audio/mpeg; codecs="mpeg"'),
				// eslint-disable-next-line max-len
				'Can play Wav ?', !!this._dingDongAudio.canPlayType && this._dingDongAudio.canPlayType('audio/wav; codecs="1"'),
				// eslint-disable-next-line max-len
				'Can play Ogg ?', !!this._dingDongAudio.canPlayType && this._dingDongAudio.canPlayType('audio/ogg; codecs="vorbis"'));

			// Watch Events
			// On Error
			this._dingDongAudio.onerror = function (error) {
				// The code property of the MediaError Object returns a number representing the error state of the audio/video:

				// 1 = MEDIA_ERR_ABORTED - fetching process aborted by user
				// 2 = MEDIA_ERR_NETWORK - error occurred when downloading
				// 3 = MEDIA_ERR_DECODE - error occurred when decoding
				// 4 = MEDIA_ERR_SRC_NOT_SUPPORTED - audio/video not supported

				this._logger.error(`${this.componentName}:watchDingDongEvents`,
					'Error',
					(error.currentTarget.error === 1 ? 'Aborted' :
						error.currentTarget.error === 2 ? 'Network' :
							error.currentTarget.error === 3 ? 'Decode' :
								error.currentTarget.error === 4 ? 'Source not supported' : 'Unknown'),
					'Error Code', error.currentTarget.error.code);

			}.bind(this);

			if (this._coreConfig.configurations.logger.level <= LogLevel.Debug) {
				// On Play
				this._dingDongAudio.onplay = function () {
					this._logger.debug(`${this.componentName}:watchDingDongEvents`, 'Play Started',
						this._dingDongAudio.currentSrc);
				}.bind(this);

				// On Ended
				this._dingDongAudio.onended = function () {
					this._logger.debug(`${this.componentName}:watchDingDongEvents`, 'Play Ended',
						this._dingDongAudio.currentSrc);
				}.bind(this);

				// On Loaded Data
				this._dingDongAudio.onloadeddata = function () {
					this._logger.debug(`${this.componentName}:watchDingDongEvents`, 'Audio loaded',
						this._dingDongAudio.currentSrc);
				}.bind(this);

			}

		} catch (error) {
			this._logger.error(`${this.componentName}:watchDingDongEvents`, 'Error watching audio events', 'Error', error);
		}
	}

	/**
	 * Plays the ding-dong
	 */
	private playDingDong() {
		try {
			this._logger.info(`${this.componentName}:playDingDong`, 'Source', this._dingDongAudio.currentSrc,
				'Muted?', this._dingDongAudio.muted,
				'Audio Level', this.audioVolume / 100);

			this._dingDongAudio.volume = this.audioVolume / 100;
			this._dingDongAudio.muted = !this.sound;
			this._dingDongAudio.play().catch(error => {
				this._logger.error(`${this.componentName}:playDingDong`, 'Source', this._dingDongAudio.currentSrc,
					`AutoPlay was prevented`,
					'Muted?', this._dingDongAudio.muted,
					'Audio Level', this.audioVolume / 100,
					'Error', error);
			});
			this._cdRef.detectChanges();

		} catch (error) {
			this._logger.error(`${this.componentName}:playDingDong`, 'Source', this._dingDongAudio.currentSrc,
				`Error playing the Ding Dong`,
				'Muted?', this._dingDongAudio.muted,
				'Audio Level', this.audioVolume / 100,
				'Error', this.audioPlayerRef.nativeElement.error);
		}
	}

	/**
	 * ================================================================================================================================
	 * New tickets received >> It's here that we receive the New tickets
	 * ================================================================================================================================
	 */

	/**
	 * Watches for ticket tracker state changes and acts accordingly
	 * In here we only want to check the "TicketCalledToPanel" event
	 * CHANGES:
	 *   - Added code to check if the ticket received belongs to any of the counter ids configured (if they exist)
	 *     Added by Carlos.Moreira @ 2019/04/22
	 */
	private watchForTicketTrackerStateChanges() {
		// Watch for display changes
		this._ticketTrackerStateSub = this._ticketTrackerService.ticketTrackerState$
			.pipe(
				distinctUntilChanged(),
				filter(ticketTrackerState =>
					!isNullOrUndefined(ticketTrackerState) &&
					ticketTrackerState.status === TicketTrackerStatus.ItemCalledToPanel),
				map(displayState => displayState.lastTicket as CallingTicketUI))
			.subscribe(ticket => {
				// New ticket
				this._logger.debug(`${this.componentName}:watchForTicketTrackerStateChanges`, 'Display Status',
					TicketTrackerStatus[TicketTrackerStatus.ItemCalledToPanel], 'Ticket', ticket);

				// ===============================================================================================
				// 1. Assign ticket to ALL calling panels
				//   (because we are in scroll mode and ALL panels show everything)
				// ===============================================================================================
				this._callingPanels.forEach(callingPanel => {
					// Initialize tickets list if undefined
					if (isNullOrUndefined(callingPanel.tickets)) { callingPanel.tickets = []; }

					// ===============================================================================================
					// Adds the new ticket to the list of tickets of the panel
					// 	  Rules for pushing:
					// 		- If ticket doesn't exist in the ticket queue
					// 		  > Must use the recordId_ (the text interpretation of the long number), otherwise some
					// 		    tickets are discarded and don't appear in panel
					// 		  	- Correction by Carlos.Moreira @ 2019/01/11
					// 		- If ticket exists but is already shown in a panel
					// 		- If ticket exists and isn't shown yet don't added it to list (to avoid duplication)
					// ===============================================================================================
					if (callingPanel.tickets.findIndex(
						ticketToFind =>
							ticketToFind.recordId_ === ticket.recordId_ &&
							ticketToFind.subRecordId === ticket.subRecordId) === -1) {

						// Date of the ticket in the tickets queue
						ticket.date = moment();

						// Push it to the end of the list
						callingPanel.tickets.push(ticket);

						// Indicate that a new ticket has been added and needs to be shown
						this.hasTickets.next(true);
					}
				});
			});
	}
}
