/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class SmsSettingsComponent
 * @description
 * Sms Settings Component
 * Created by Iuri.cunha @ 2020/03/25
 */
// Angular Components
import { Component, ViewChild, OnInit, Injector, ElementRef, Renderer2 } from '@angular/core';
import { share } from 'rxjs/operators';

// Third party Components
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import swal from 'sweetalert2';

// Ticket Tracker Components
import { TicketAddFromOtherDeviceUI } from '../../../core/models/ticket/ticket-add-from-other-device-ui.interface';
import { BaseEntityCustomization } from '../base-entity-customization/base-entity-customization';
import { MyItemsService } from '../../../core/services/items/my-items.service';
import { EntityCustomizationType } from '../../../core/enums/customization/entity-customization-type.enum';

// Libraries Components
import { DispenserErrors, ChannelEnum, PrintedTicket, TakeTicketRule } from 'qore.models';
import { StringUtilities, isNullOrUndefined } from 'qevo.utilities';

@Component({
	selector: 'qoala-tt-sms-settings',
	templateUrl: './sms-settings.component.html',
	styleUrls: ['./sms-settings.component.scss']
})
export class SmsSettingsComponent extends BaseEntityCustomization implements OnInit {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Checkbox
	termsCheck: boolean;

	// Phone number input
	phoneNumber = '';

	// Phone number validation
	isPhoneNumberValid: boolean;

	// Service Terms and Conditions
	@ViewChild('serviceTermsAndConditionsPopup') private _serviceTermsAndConditionsPopup: SwalComponent;
	@ViewChild('serviceTermsAndConditionsIFrame') private _serviceTermsAndConditionsIFrame: ElementRef;

	// Loading flag
	public serviceTermsAndConditionsIsLoading: boolean;

	// Iframe Error
	serviceTermsAndConditionsIFrameLoadError = false;

	// Timeout for checking if iFrame is loaded correctly
	private _serviceTermsAndConditionsTimeout: NodeJS.Timeout;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		private _myItemsService: MyItemsService,
		injector: Injector) {
		super('SmsSettingsComponent', injector, EntityCustomizationType.Tickets);
	}

	ngOnInit() {
		// Apply customization
		this.applyCustomization(this._ticketTrackerService.ticketTrackerSettings, true);

		// Set terms and conditions url
		this.setTermsAndConditionsUrl(this._ticketTrackerService.termsAndConditionsUrl);
	}

	/**
	 * ================================================================================================================================
	 * Get New Ticket
	 * ================================================================================================================================
	 */

	/**
	 * Gets a new ticket and if ok moves to the ticket tracking page
	 */
	getNewTicket() {
		// Generate ticket
		this.generateNewTicket();
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */

	/**
	 * Detects when the terms checkbox value is changed
	 * @param newValue New value
	 */
	termsChanged(newValue: any): void {
		this.termsCheck = newValue.target.checked;
	}

	/**
	 * Opens the Service Terms and Conditions
	 * @param event$ event data
	 */
	openServiceTermsAndConditions(event$: any) {
		this._logger.debug(`${this.componentName}:openServiceTermsAndConditions`,
			'Terms and Conditions Url', this.termsAndConditionsUrl?.replace('{langCode}', this.curLangCode));

		this.serviceTermsAndConditionsIFrameLoadError = false;

		// Show loading
		this.serviceTermsAndConditionsIsLoading = true;

		this._serviceTermsAndConditionsPopup.ngOnInit();
		this._serviceTermsAndConditionsPopup.fire();

		clearTimeout(this._serviceTermsAndConditionsTimeout);
		this._serviceTermsAndConditionsTimeout = setTimeout(() => {
			const iframeElement = this._serviceTermsAndConditionsIFrame?.nativeElement;

			// Check to see if there are errors
			const isLoaded = isNullOrUndefined(iframeElement) ? 0 :
				iframeElement.getAttribute('data-serviceTermsAndConditionsLoaded');

			this._logger.debug(`${this.componentName}:toggleServiceTermsAndConditionsVisibility`,
				'Check to see if Terms and Conditions iFrame is loaded correctly ?', isLoaded);

			this.isServiceTermsAndConditionsLoaded(isLoaded);
		}, 3000);
	}

	/**
	 * Closes the Service Terms and Conditions
	 * @param event$ event data
	*/
	closeServiceTermsAndConditions(event$: any) {
		// Show loading
		this.serviceTermsAndConditionsIsLoading = false;

		this._serviceTermsAndConditionsPopup.dismiss();
	}

	/**
	 * Check Service Terms and Conditions iframe load event
	 */
	onServiceTermsAndConditionsIframeLoad() {
		this._logger.debug(`${this.componentName}:onServiceTermsAndConditionsIframeLoad`,
			'Loading Terms and Conditions iFrame');

		this.serviceTermsAndConditionsIFrameLoadError = false;
		const iframeElement = this._serviceTermsAndConditionsIFrame?.nativeElement;

		// Request entity customization
		this.applyServiceTermsAndConditionsCustomization(document.getElementById('serviceTermsAndConditionsLoader'));

		window.addEventListener('message', (event) => {
			// if message is from Service Terms and Conditions iFrame, set flag as loaded
			if (this.termsAndConditionsUrl?.replace('{langCode}', this.curLangCode) === event.data) {
				iframeElement.setAttribute('data-serviceTermsAndConditionsLoaded', '1');

				this.isServiceTermsAndConditionsLoaded('1');
			}
		});
	}

	/**
	 * Detects when the phone number value is changed
	 * @param newValue New value
	 */
	phoneNumberInput(newValue: any): void {
		this.phoneNumber = newValue.target.value;

		// Basic validation
		this.isPhoneNumberValid = this.phoneNumber.length > 0 ? true : false;
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Generate new ticket
	 */
	private generateNewTicket() {
		if (isNullOrUndefined(this._ticketTrackerService.tickets.ticketRequestInfo)) {
			this._logger.warn(`${this.componentName}:generateNewTicket`,
				'No Entity and Store id stored to take ticket with SMS alerts.');

			// Translate error code from resources
			const errorMessage: string = this._translateService.instant(`ERRORS.NoInfoToTakeTicketWithSMSAlerts`);

			// Send error message to UI
			swal.fire({
				title: '',
				text: errorMessage,
				icon: 'warning',
				customClass: {
					popup: 'msg-box'
				}
			});

		} else {
			this._logger.debug(`${this.componentName}:generateNewTicket`,
				'Entity id', this._ticketTrackerService.tickets.ticketRequestInfo.entityId,
				'Store id', this._ticketTrackerService.tickets.ticketRequestInfo.storeId);

			const ticketRequest: TicketAddFromOtherDeviceUI = this._ticketTrackerService.tickets.ticketRequestInfo;

			// Check to see if we can take a ticket
			if (this._myItemsService.canTakeTicket(ticketRequest.entityId, ticketRequest.storeId, ticketRequest.serviceId,
				ticketRequest.takeTicketRule, ticketRequest.maxNumberOfTakenTickets)) {

				// Get a new ticket
				this._ticketTrackerService.tickets.getNewTicket(null, this.phoneNumber, ChannelEnum.SMS)
					.pipe(share())
					.subscribe(
						(result: PrintedTicket) => {
							this._logger.info(`${this.componentName}:generateNewTicket`, 'Result returned', result);

							if (!isNullOrUndefined(result)) {
								this._ticketTrackerService.gotoPage([result.ticketUniqueId]);
							} else {
								// Translate error code from resources
								const errorMessage: string = this._translateService.instant(`ERRORS.GettingTicketError`);

								// Send error message to UI
								swal.fire({
									title: '',
									text: errorMessage,
									icon: 'error',
									customClass: {
										popup: 'msg-box'
									}
								});
							}
						},
						error => {
							this._logger.error(`${this.componentName}:generateNewTicket`, 'Result returned', error);

							// Translate error code from resources
							const errorMessage: string =
								this._translateService.instant(`ERRORS.${DispenserErrors[error.details]}`, { errorCode: error.details });

							// Send error message to UI
							swal.fire({
								title: '',
								text: errorMessage,
								icon: 'error',
								customClass: {
									popup: 'msg-box'
								}
							});
						}
					);

			} else {
				// Translate error code from resources
				const infoMessage: string = this._translateService.instant(
					// eslint-disable-next-line max-len
					`ERRORS.YOU_HAVE_REACHED_MAX_NUMBER_TICKETS${StringUtilities.replaceLowerToUpper(TakeTicketRule[ticketRequest.takeTicketRule], '_')}`,
					{ maxNumberOfTakenTickets: ticketRequest.maxNumberOfTakenTickets });

				// Send error message to UI
				swal.fire({
					title: '',
					text: infoMessage,
					icon: 'info',
					customClass: {
						popup: 'msg-box'
					}
				});
			}
		}
	}

	/**
	 * Show iFrame or Error according to loading state of terms
	 * @param isLoaded 1 for loaded and <> 1 for not loaded
	 */
	private isServiceTermsAndConditionsLoaded(isLoaded: string) {
		if (isLoaded !== '1') {
			this.serviceTermsAndConditionsIFrameLoadError = true;

		} else {
			this.serviceTermsAndConditionsIFrameLoadError = false;
		}

		// Set and send event to parent to show/hide loading
		this.serviceTermsAndConditionsIsLoading = false;

		clearTimeout(this._serviceTermsAndConditionsTimeout);
	}
}
