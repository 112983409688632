/* eslint-disable @typescript-eslint/prefer-for-of */
/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* BaseEntityCustomization
* @description
* Base Class to apply Entity Customizations
* Created by André.Pinho @ 2021/08/12
*/

// Angular Components
import { ElementRef, Injector, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// Third party Components
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

// Ticket Tracker Components
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';
import { EntityCustomizationType } from '../../../core/enums/customization/entity-customization-type.enum';

// Libraries Components
import {
	TicketTrackerSettings, TicketTrackerImagesConfiguration, TicketTrackerTicketsColorsConfiguration,
	TicketTrackerBookingsColorsConfiguration, TicketTrackerTicketsCustomSettings, TicketTrackerBookingsCustomSettings
} from 'qore.models';
import { MultiLanguage, isNullOrUndefined } from 'qevo.utilities';
import { LoggerService, LanguageService } from 'qevo.services';
import { TermsAndConditions } from 'qevo.models';

export class BaseEntityCustomization implements OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Base Properties
	 * ********************************************************************************************************************************
	*/
	protected _renderer: Renderer2;
	protected _elRef: ElementRef;
	protected _languageService: LanguageService;
	protected _translateService: TranslateService;

	protected _logger: LoggerService;
	protected _ticketTrackerService: TicketTrackerService;
	protected _cookieService: CookieService;

	// Settings with the customizations
	ticketTrackerSettings: TicketTrackerSettings;

	// Terms and Conditions object
	termsAndConditions: TermsAndConditions;

	// Language Change Subscription
	protected _onLanguageChangeSub: Subscription;

	// Current language
	public curLangCode: string;

	/**
	 * ================================================================================================================================
	 * Customization settings - Start
	 * ================================================================================================================================
	*/
	// Font Color of labels
	fontColor: string;

	// Font color of the link
	linkFontColor: string = null;

	// Font Color for drop down labels
	dropdownFontColor: string;

	// Button Colors
	buttonFontColor: string;
	buttonBackgroundColor: string;

	// Font Color and BackgroundColor for back button
	backFontColor: string;
	backBackgroundColor: string;

	// Logo
	showLogo = false;
	logoSrc: string;

	// Service Label and place holder
	servicesLabel: string;
	servicesPlaceHolder: string;

	// Store Label and place holder
	storesLabel: string;
	storesPlaceHolder: string;

	// Terms And Conditions url
	termsAndConditionsUrl: string;
	safeResourceTermsAndConditionsUrl: SafeResourceUrl;

	// Sanitizer
	protected _sanitizer: DomSanitizer;

	/**
	 * ================================================================================================================================
	 * Customization settings - End
	 * ================================================================================================================================
	*/

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(protected componentName: string, private _injector: Injector,
		protected customizationType: EntityCustomizationType = EntityCustomizationType.None) {

		// Get services here with interceptor
		this._elRef = this._injector.get(ElementRef);
		this._renderer = this._injector.get(Renderer2);
		this._languageService = this._injector.get(LanguageService);
		this._translateService = this._injector.get(TranslateService);
		this._cookieService = this._injector.get(CookieService);
		this._logger = this._injector.get(LoggerService);
		this._sanitizer = this._injector.get(DomSanitizer);

		// Ticket Tracker Services
		this._ticketTrackerService = this._injector.get(TicketTrackerService);

		// Watch for language changes
		this._onLanguageChangeSub = this._languageService.onLanguageChange$.subscribe(language => {
			// Set new language code
			if (language) {
				this.curLangCode = language.code;
			}

			// Apply entity customization
			this.applyLangRelatedCustomizations();

			// Update terms and conditions url because language changed
			this.setTermsAndConditionsUrl(this.termsAndConditionsUrl);
		});
	}

	ngOnDestroy(): void {
		// Unsubscribe
		if (!isNullOrUndefined(this._onLanguageChangeSub)) { this._onLanguageChangeSub.unsubscribe(); }

		// Clear Customization
		this.clearCustomization();
	}

	/**
	 * ================================================================================================================================
	 * Methods
	 * ================================================================================================================================
	*/

	/**
	 * Set Terms And Conditions URL
	 * @param url
	 */
	protected setTermsAndConditionsUrl(url: string) {
		// Save the url on class
		this.termsAndConditionsUrl = url;

		// Sanitize the url
		this.safeResourceTermsAndConditionsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
			url?.replace('{langCode}', this.curLangCode));
	}

	/**
	 * Apply Customization
	 * @param ticketTrackerSettings Ticket Tracker Settings
	 * @param includeMenu Include menu customizations
	 */
	protected applyCustomization(ticketTrackerSettings?: TicketTrackerSettings, includeMenu: boolean = false) {
		this._logger.debug(`${this.componentName}:applyCustomization`, 'Apply customization', ticketTrackerSettings,
			'Include menu?', includeMenu);

		// Assign new ticket tracker settings for customizations (if passed)
		if (!isNullOrUndefined(ticketTrackerSettings)) {
			this.ticketTrackerSettings = ticketTrackerSettings;
		}

		// If no settings are set... then exit
		if (isNullOrUndefined(this.ticketTrackerSettings)) { return; }

		// Set ticket tracker settings in service ... for use everywhere
		this._ticketTrackerService.setTicketTrackerSettings(this.ticketTrackerSettings);

		// Set color configurations source variable
		const colorsSrc = this.getCustomColorsSettings();

		// Set images configurations source variable
		const imagesSrc = this.getCustomImagesConfigurations();

		// Get page view html element
		const view: HTMLElement = this._elRef.nativeElement.querySelector('#view');

		// If color configurations exist then proceed
		if (!isNullOrUndefined(colorsSrc)) {

			// If Tickets customization
			if (this.customizationType === EntityCustomizationType.Tickets) {
				// Change color for the header font Color
				const title: any = this._elRef.nativeElement.querySelector('#main-title');
				this._renderer.setStyle(title, 'color', colorsSrc.headerFontColor);

				// Change main font color
				this.fontColor = colorsSrc.fontColor;

				// Change color for the dropdown labels
				this.dropdownFontColor = (colorsSrc as TicketTrackerTicketsColorsConfiguration).dropdownLabelFontColor;

				// Change the font Color of link
				this.linkFontColor = colorsSrc.linkFontColor;
			}

			// Change Background Color
			this._renderer.setStyle(view, 'background-color', colorsSrc.backgroundColor);
		}

		// Apply background image customizations
		if (!isNullOrUndefined(imagesSrc)) {

			// Static background image placement styles
			if (!isNullOrUndefined(imagesSrc.backgroundStatic)) {
				this._renderer.setStyle(view, 'background-image', `url('${imagesSrc.backgroundStatic}')`);
				this._renderer.setStyle(view, 'background-size', 'cover');
				this._renderer.setStyle(view, 'background-repeat', 'round');
			}

			// Background image source and styles
			if (!isNullOrUndefined(imagesSrc.background)) {
				const imgView: HTMLImageElement = document.createElement('img');
				imgView.src = imagesSrc.background;
				this._renderer.appendChild(this._elRef.nativeElement, imgView);

				imgView.addEventListener('load', () => {
					this._renderer.setStyle(view, 'background-image', `url('${imagesSrc.background}')`);
					this._renderer.setStyle(view, 'background-size', 'cover');
					this._renderer.setStyle(view, 'background-repeat', 'round');
					this._renderer.removeChild(this._elRef.nativeElement, imgView);
				});
			}
		}

		// If menu exists then apply custom styles
		if (includeMenu) {
			this.applyMenuCustomization();
		}

		// Language related customizations
		this.applyLangRelatedCustomizations();
	}

	/**
	 * Apply Menu Customization
	 */
	protected applyMenuCustomization() {
		this._logger.debug(`${this.componentName}:applyMenuCustomization`, 'Apply menu customization', this.ticketTrackerSettings);

		// Check to see if there are settings
		if (isNullOrUndefined(this.ticketTrackerSettings)) { return; }

		// Get component parent html element
		const parentView = this._elRef.nativeElement.parentElement;

		// Set color configurations source variable
		const colorsSrc = this.getCustomColorsSettings();

		// Set images configurations source variable
		const imagesSrc = this.getCustomImagesConfigurations();

		// If color customizations are set then proceed
		if (!isNullOrUndefined(colorsSrc)) {

			// If ticket customization
			if (this.customizationType === EntityCustomizationType.Tickets) {
				// Change color of the buttons
				this.buttonFontColor = colorsSrc.buttons.buttonFontColor;
				this.buttonBackgroundColor = colorsSrc.buttons.buttonBackgroundColor;

				// Change color of back button
				this.backFontColor = colorsSrc.buttons.backButtonFontColor;
				this.backBackgroundColor = colorsSrc.buttons.backButtonBackgroundColor;

				// Change the font Color of link
				this.linkFontColor = colorsSrc.linkFontColor;
			}

			// If button colors are set
			if (!isNullOrUndefined(colorsSrc.buttons)) {
				setTimeout(() => {
					// Get and apply custom to navigation buttons
					[
						parentView.querySelector('#home'),
						parentView.querySelector('#sound'),
						parentView.querySelector('#idiom'),
						parentView.querySelector('#back'),
						parentView.querySelector('#next'),
						parentView.querySelector('#conclude')
					].filter(button => !isNullOrUndefined(button))
						.forEach(button => {
							this._renderer.setStyle(button, 'color', colorsSrc.buttons.buttonFontColor);
							this._renderer.setStyle(button, 'background', colorsSrc.buttons.buttonBackgroundColor);
						});
				}, 10);
			}

			// Change logo svg according to the settings
			const qevoLogo: HTMLImageElement = document.getElementById('qevo-logo') as HTMLImageElement;
			if (!isNullOrUndefined(qevoLogo)) {
				if (colorsSrc.qevoColor.toLowerCase() === '#fff') {
					qevoLogo.src = './assets/img/qevo-logo-w.svg';
				} else {
					qevoLogo.src = './assets/img/qevo-logo-b.svg';
				}
			}
		}

		// If there settings and background images are set for tickets specific customizations
		if (!isNullOrUndefined(imagesSrc)) {
			// Set an entity logo if it exists
			if (!isNullOrUndefined(imagesSrc.logo)) {
				this.showLogo = true;
				this.logoSrc = imagesSrc.logo;
			}
		}

		// Apply language resources to options menus
		this.applyLangRelatedCustomizations();
	}

	/**
	 * Apply language pop up customization
	 * @param languagesElement Popup language element
	 * @param ticketTrackerSettings Ticket Tracker Settings
	 */
	protected applyLanguagePopupCustomization(languagesElement: any, ticketTrackerSettings?: TicketTrackerSettings) {

		// Assign new ticket tracker settings for customizations
		this.ticketTrackerSettings = ticketTrackerSettings;

		// If no settings are set then exit
		if (isNullOrUndefined(this.ticketTrackerSettings)) { return; }

		// Set color configurations source variable
		const colorsSrc = this.getCustomColorsSettings();

		// If there are settings and ticket card related colors are set
		if (!isNullOrUndefined(colorsSrc)) {
			// Get buttons html elements
			const languageButtons: HTMLCollectionOf<HTMLElement> =
				languagesElement.querySelectorAll('button.-language') as HTMLCollectionOf<HTMLElement>;

			// Set colors for each one
			for (let i = 0; i < languageButtons.length; i++) {
				languageButtons[i].style.color = colorsSrc.buttons.buttonFontColor;
				languageButtons[i].style.background = colorsSrc.buttons.buttonBackgroundColor;
			}

			// Get close button html elements
			const closeLanguagePopUpButton: HTMLCollectionOf<HTMLElement> =
				languagesElement.querySelectorAll('button.-close') as HTMLCollectionOf<HTMLElement>;

			// Set colors for each one
			for (let i = 0; i < closeLanguagePopUpButton.length; i++) {
				closeLanguagePopUpButton[i].style.color = colorsSrc.buttons.buttonBackgroundColor;
				closeLanguagePopUpButton[i].style.background = colorsSrc.buttons.buttonFontColor;
				closeLanguagePopUpButton[i].style.borderColor = colorsSrc.buttons.buttonBackgroundColor;
			}
		}
	}

	/**
	 * Applies customization that are related to the language selected and must be updated on every change
	 */
	protected applyLangRelatedCustomizations() {
		// If not tickets
		if (this.customizationType !== EntityCustomizationType.Tickets) { return; }

		// Set color configurations source variable (always tickets)
		const customSettings: TicketTrackerTicketsCustomSettings = (this.getCustomSettings() as TicketTrackerTicketsCustomSettings);

		// Apply resources customizations on service and store dropdown select elements
		// Stores
		if (!isNullOrUndefined(customSettings?.navigation?.storesLabel)) {
			this.storesLabel =
				MultiLanguage.getValue(customSettings.navigation.storesLabel, this.curLangCode);
		} else {
			this.storesLabel = this._translateService.instant('NEW_TICKET.STORE');
		}

		if (!isNullOrUndefined(customSettings?.navigation?.storesPlaceHolder)) {
			this.storesPlaceHolder =
				MultiLanguage.getValue(customSettings.navigation.storesPlaceHolder, this.curLangCode);
		} else {
			this.storesPlaceHolder = this._translateService.instant('NEW_TICKET.CHOOSE_STORE');
		}

		// Services
		if (!isNullOrUndefined(customSettings?.navigation?.servicesLabel)) {
			this.servicesLabel =
				MultiLanguage.getValue(customSettings.navigation.servicesLabel, this.curLangCode);
		} else {
			this.servicesLabel = this._translateService.instant('NEW_TICKET.SERVICE');
		}

		if (!isNullOrUndefined(customSettings?.navigation?.servicesLabel)) {
			this.servicesPlaceHolder =
				MultiLanguage.getValue(customSettings.navigation.servicesPlaceHolder, this.curLangCode);
		} else {
			this.servicesPlaceHolder = this._translateService.instant('NEW_TICKET.CHOOSE_SERVICE');
		}
	}

	/**
	 * Applies customization to Service Terms And Conditions (loader element at this time)
	 * @param serviceTermsAndConditionsLoaderHost
	 * @returns
	 */
	protected applyServiceTermsAndConditionsCustomization(serviceTermsAndConditionsLoaderElement: HTMLElement) {
		// If no settings are set then exit
		if (isNullOrUndefined(this.ticketTrackerSettings)) { return; }

		// Set color configurations source variable
		const colorsSrc = this.getCustomColorsSettings();

		// If it exists then change elements color
		if (!isNullOrUndefined(serviceTermsAndConditionsLoaderElement) && !isNullOrUndefined(colorsSrc)) {
			// Balls animation
			const balls = serviceTermsAndConditionsLoaderElement.querySelectorAll('label') ?? [];
			balls.forEach(ball => {
				if (!isNullOrUndefined(ball)) {
					this._renderer.setStyle(ball, 'color', isNullOrUndefined(colorsSrc.bodyEnabledColor) ?
						colorsSrc.headerFontColor : colorsSrc.bodyEnabledColor);
				}
			});

			// Text
			this._renderer.setStyle(serviceTermsAndConditionsLoaderElement.querySelector('.loading-message'),
				'color', colorsSrc.fontColor);
		}
	}

	/**
	 * Clear Customization
	 */
	protected clearCustomization() {
		this._logger.debug(`${this.componentName}:clearCustomization`);

		// Clear colors
		this.fontColor = null;
		this.backFontColor = null;
		this.backBackgroundColor = null;
		this.buttonBackgroundColor = null;
		this.buttonFontColor = null;
		this.dropdownFontColor = null;

		// Get view html element
		const view: any = this._elRef.nativeElement.querySelector('#view');

		// Clear title colors
		const title: any = view.querySelector('#main-title');
		if (!isNullOrUndefined(title)) {
			this._renderer.removeStyle(title, 'color');
		}

		// Clear background styles
		this._renderer.removeStyle(view, 'background-color');
		this._renderer.removeStyle(view, 'background-size');
		this._renderer.removeStyle(view, 'background-image');

		// Reset footer logo
		const qevoLogo: HTMLImageElement = document.getElementById('qevo-logo') as HTMLImageElement;
		if (!isNullOrUndefined(qevoLogo)) {
			qevoLogo.src = './assets/img/qevo-logo-b.svg';
		}
		if (!isNullOrUndefined(document)) {
			// Get and reset navigation buttons
			[
				document.querySelector('#home'),
				document.querySelector('#sound'),
				document.querySelector('#idiom'),
				document.querySelector('#back'),
				document.querySelector('#next'),
				document.querySelector('#conclude')
			].filter(button => !isNullOrUndefined(button))
				.forEach(button => {
					this._renderer.removeStyle(button, 'color');
					this._renderer.removeStyle(button, 'background');
				});
		}

		// Clear logos
		this.showLogo = false;
		this.logoSrc = null;
	}

	/**
	 * Clear Customization applied to language pop up
	 * @param languagesElement Popup language element
	 */
	protected clearLanguagePopupCustomization(languagesElement: any) {
		const languageButtons: HTMLCollectionOf<HTMLElement> =
			languagesElement.querySelectorAll('button.-language') as HTMLCollectionOf<HTMLElement>;

		for (let i = 0; i < languageButtons.length; i++) {
			languageButtons[i].style.removeProperty('color');
			languageButtons[i].style.removeProperty('background');
		}

		const closeLanguagePopUpButton: HTMLCollectionOf<HTMLElement> =
			languagesElement.querySelectorAll('button.-close') as HTMLCollectionOf<HTMLElement>;

		for (let i = 0; i < closeLanguagePopUpButton.length; i++) {
			closeLanguagePopUpButton[i].style.removeProperty('color');
			closeLanguagePopUpButton[i].style.removeProperty('background');
			closeLanguagePopUpButton[i].style.removeProperty('borderColor');
		}
	}

	/**
	 * Gets the Tickets, Bookings or none configurations
	 * @returns
	 */
	private getCustomSettings(): TicketTrackerTicketsCustomSettings | TicketTrackerBookingsCustomSettings {
		return this.customizationType === EntityCustomizationType.None ? null :
			this.customizationType === EntityCustomizationType.Tickets ? this.ticketTrackerSettings?.tickets :
				this.ticketTrackerSettings?.bookings;
	}

	/**
	 * Gets the Tickets, Bookings or none colors configurations
	 * @returns
	 */
	private getCustomColorsSettings(): TicketTrackerTicketsColorsConfiguration | TicketTrackerBookingsColorsConfiguration {
		return this.customizationType === EntityCustomizationType.None ? null :
			this.customizationType === EntityCustomizationType.Tickets ? this.ticketTrackerSettings?.tickets?.colors :
				this.ticketTrackerSettings?.bookings?.colors;
	}

	/**
	 * Gets the Tickets, Bookings or none image custom configurations
	 * @returns
	 */
	private getCustomImagesConfigurations(): TicketTrackerImagesConfiguration {
		return this.customizationType === EntityCustomizationType.None ? null :
			this.customizationType === EntityCustomizationType.Tickets ? this.ticketTrackerSettings?.tickets?.images :
				this.ticketTrackerSettings?.bookings?.images;
	}
}
