/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * QRCodeType
 * @description
 * QR Code Type enumerator
 * Created by Carlos.Moreira @ 2019/07/17
 */

export enum QRCodeType {
	// Ticket
	Ticket = 0,
	// Flyer to take a Ticket
	FlyerNewTicket = 1,
	// Flyer to take a Booking
	FlyerNewBooking = 2
}
