/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class Routings
 * @description
 * Routings for the Qoala Ticket Tracker App
 * Created by Carlos.Moreira @ 2018/05/07
 */

// Angular Components
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Ticket Tracker Components
import { ErrorComponent } from './core/components/error/error.component';
import { ItemTrackingComponent } from './ticket-tracker/components/item-tracking/item-tracking.component';
import { TrackMenuComponent } from './ticket-tracker/components/track-menu/track-menu.component';
import { TicketSelectorComponent } from './ticket-tracker/components/ticket-selector/ticket-selector.component';
import { MainMenuComponent } from './ticket-tracker/components/main-menu/main-menu.component';
import { NewTicketComponent } from './ticket-tracker/components/new-ticket/new-ticket.component';
import { MyItemsComponent } from './ticket-tracker/components/my-items/my-items.component';
import { SmsSettingsComponent } from './ticket-tracker/components/sms-settings/sms-settings.component';
import { TakeTicketMenuComponent } from './ticket-tracker/components/take-ticket-menu/take-ticket-menu.component';
import { QRCodeScannerComponent } from './ticket-tracker/components/qr-code-scanner/qr-code-scanner.component';
import { ScheduleBookingMenuComponent } from './ticket-tracker/components/schedule-booking-menu/schedule-booking-menu.component';
import { NewBookingComponent } from './ticket-tracker/components/new-booking/new-booking.component';
import { EditBookingComponent } from './ticket-tracker/components/edit-booking/edit-booking.component';
import { ValidateBookingComponent } from './ticket-tracker/components/validate-booking/validate-booking.component';

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// IMPORTANT
//    - ALL paths must be all lowercase
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const routes: Routes = [
	// Menu Path and Ticket reader and Ticket selector options
	{
		path: '',
		component: MainMenuComponent
	},
	{
		path: 'track-menu',
		component: TrackMenuComponent
	},
	{
		path: 'ticket-selector',
		component: TicketSelectorComponent
	},
	{
		path: 'new-ticket',
		component: NewTicketComponent
	},
	{
		path: 'new-ticket/:entity',
		component: NewTicketComponent
	},
	{
		path: 'new-ticket/:entity/:store',
		component: NewTicketComponent
	},
	{
		path: 'new-ticket/:entity/:store/:service',
		component: NewTicketComponent
	},
	{
		path: 'schedule-booking-menu',
		component: ScheduleBookingMenuComponent
	},
	{
		path: 'new-booking',
		component: NewBookingComponent
	},
	{
		path: 'new-booking/:entity',
		component: NewBookingComponent
	},
	{
		path: 'new-booking/:entity/:store',
		component: NewBookingComponent
	},
	{
		path: 'new-booking/:entity/:store/:service',
		component: NewBookingComponent
	},
	{
		path: 'reschedule-booking',
		component: NewBookingComponent
	},
	{
		path: 'my-items',
		component: MyItemsComponent
	},
	{
		path: 'sms-settings',
		component: SmsSettingsComponent
	},
	{
		path: 'take-ticket-menu',
		component: TakeTicketMenuComponent
	},
	{
		path: 'qr-code-scanner/:type',
		component: QRCodeScannerComponent
	},
	{
		path: 'edit-booking',
		component: EditBookingComponent
	},

	// Error paths (make send in this App?)
	{ path: 'error', component: ErrorComponent },
	{ path: 'error/:errorcode', component: ErrorComponent },
	{ path: 'error/:errorcode/:errormessage', component: ErrorComponent },


	// For Bookings
	{
		path: 'booking/:bookingcode',
		redirectTo: ':bookingcode/3'
	},
	{
		path: 'validate-booking/:bookingcode',
		component: ValidateBookingComponent
	},
	{
		path: ':bookingcode/:servicetype',
		component: ItemTrackingComponent
	},
	// Ticket Tracking Paths
	// For Tickets
	{
		path: ':ticketuniqueid',
		component: ItemTrackingComponent
	},
	// In case of parsing url to lowercase in Azure
	{ path: 'tickettracker/:ticketuniqueid', redirectTo: ':ticketuniqueid' },
	{ path: 'tickettracker/:bookingcode/:servicetype', redirectTo: ':bookingcode/:servicetype' },
	{ path: 'tickettracker/booking/:bookingcode', redirectTo: ':bookingcode/:servicetype' },
	{ path: 'tickettracker', redirectTo: '' },

	// Otherwise redirect to 404
	{ path: '**', redirectTo: 'error/404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { enableTracing: false })],
	exports: [RouterModule]
})
export class QoalaTicketTrackerRoutingModule { }
