<!-- CALLING PANEL -->
<div class="service-name-container" [class.animate-truncate]="animateServiceName">
	<!-- SERVICE NAME -->
	<div class="service-name" [attr.data]="(ticket.serviceNameOnDisplay | qmultilanguage:curLangCode:false:true).length"
		[style.animation-duration.s]="serviceAnimationDuration" #serviceNameContainer>
		{{ticket.serviceNameOnDisplay | qmultilanguage:curLangCode:false:true}}
	</div>
</div>

<!-- CALLING INFO -->
<div class="ticket-info-container">
	<div id="center">
		<!-- TICKET NUMBER OR NAME ON DISPLAY + ANIMATION IF NEEDED -->
		<div class="ticket-number" [class.animate]="animateNameToDisplay" [class.is-name-to-display]="!isTicketNumber">
			<span [style.animation-duration.s]="ticketAnimationDuration" #nameToDisplayElement>{{nameToDisplay}}</span>
		</div>
		<!-- COUNTER -->
		<div class="ticket-counter" *ngIf="showCounter===true">
			<span>{{ticket.counterNameOnDisplay | qmultilanguage:curLangCode:false:true | qtruncate: 5}}</span>
		</div>
	</div>
</div>

<!-- TIME -->
<div class="ticket-calling-time-container center-block-helper">
	<div class="center-block">
		<div class="time">{{ticket.callingHour | qmoment:'LT':curLangCode}}</div>
	</div>
</div>