/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class TicketTrackerService
 * @description
 * Ticket Tracer Service
 * Created by Carlos.Moreira @ 2018/07/04
 */

// Angular Components
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

// Third Party Components
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertIcon } from 'sweetalert2';

// Environment
import { environment } from '../../../environments/environment.local';

// Ticket Tracker Components
import { TicketTrackerState } from '../models/state/ticket-tracker-state.interface';
import { TicketTrackerErrors } from '../enums/errors/ticket-tracker-errors.interface';
import { TicketTrackerStatus } from '../enums/state/ticket-tracker-status.interface';
import { TicketsService } from './tickets/tickets.service';
import { BookingsService } from './bookings/bookings.service';
import { TicketTrackerMenuButton } from '../enums/menu/ticket-tracker-menu-button.interface';
import { TicketTrackerMenu } from '../models/menu/ticket-tracker-menu.interface';
import { TicketTrackerErrorHandling } from '../others/errors/ticket-tracker-error-handling';

// Libraries Components
import {
	LoggerService, BaseEmptyService, NotificationService, UIMessagesService,
	QoreNotification, NotificationStatus, Parameter, ConnectionType, NotificationNotification
} from 'qevo.services';
import {
	TicketTrackerConfiguration, RecordStatus, TicketTrackerSettings, EntityAppsSettings, ApiErrors
} from 'qore.models';
import { isNullOrUndefined } from 'qevo.utilities';

@Injectable()
export class TicketTrackerService extends BaseEmptyService<any> {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Share Ticket Request Settings for the action buttons below page
	ticketTrackerSettings: TicketTrackerSettings;

	// Share Terms And conditions url for the app
	termsAndConditionsUrl: string;

	// Internal services reference
	public get tickets(): TicketsService {
		return this._ticketsService;
	}
	public get bookings(): BookingsService {
		return this._bookingsService;
	}

	/**
	 * ================================================================================================================================
	 * Display State (contains information of settings and current status)
	 * ================================================================================================================================
	 */
	private _ticketTrackerState: BehaviorSubject<TicketTrackerState>;
	public ticketTrackerState$: Observable<TicketTrackerState>;

	// Display State current value
	public get ticketTrackerState(): TicketTrackerState {
		return this._ticketTrackerState.getValue();
	}

	public set ticketTrackerState(newState: TicketTrackerState) {
		// Set new display state
		const newTicketTrackerState: TicketTrackerState =
			Object.assign({}, this._ticketTrackerState.getValue(), newState);

		// If no error is passe reset it
		if (isNullOrUndefined(newState.error)) {
			newTicketTrackerState.error = TicketTrackerErrors.OK;
		}

		this._logger.debug(`${this.serviceName}:ticketTrackerState`, 'Set Status', TicketTrackerStatus[newTicketTrackerState.status],
			'New State', newState);

		// Push new state
		this._ticketTrackerState.next(newTicketTrackerState);
	}

	/**
	 * ================================================================================================================================
	 * Is Sound Active Property and events
	 * ================================================================================================================================
	 */

	private _isSoundActive: BehaviorSubject<boolean>;
	public isSoundActive$: Observable<boolean>;

	/**
	 * Sets the is Sound Active property and updates respective observable
	 */
	public set isSoundActive(isActive: boolean) {
		this._isSoundActive.next(isActive);
	}

	/**
	 * ================================================================================================================================
	 * Server Notifications
	 * ================================================================================================================================
	 */
	private _ticketTrackerStateSub: Subscription;

	// Notifications Notifications subscription
	private _notificationNotificationsSub: Subscription;

	// Notifications Status subscription
	private _notificationStateSub: Subscription;

	// Notification isOnline observable
	private _isNotificationOnline: BehaviorSubject<boolean>;
	public isNotificationOnline$: Observable<boolean>;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		http: HttpClient, logger: LoggerService, private _notificationService: NotificationService,
		private _router: Router, private _ticketsService: TicketsService, private _bookingsService: BookingsService) {
		super(http as any, logger);

		// Set serviceName
		this.serviceName = 'TicketTrackerService';

		// Initialize ticket tracker status
		this._ticketTrackerState = new BehaviorSubject<TicketTrackerState>(
			{
				isInitialize: false,
				ticketUniqueId: '',
				status: TicketTrackerStatus.Home
			}
		);

		this.ticketTrackerState$ = this._ticketTrackerState.asObservable();

		// Initialize is sound active observable and initial value
		this._isSoundActive = new BehaviorSubject<boolean>(false);
		this.isSoundActive$ = this._isSoundActive.asObservable();

		// Initialize is notification online observable and initial value
		this._isNotificationOnline = new BehaviorSubject<boolean>(false);
		this.isNotificationOnline$ = this._isNotificationOnline.asObservable();

		// Start watching server notifications
		this.startWatchServerNotifications();
	}

	/**
	 * Cleans the display service
	 */
	destroy() {
		// Disconnect from notification service
		this.disconnectFromNotificationService();

		// Destroy notification service
		this._notificationService.destroy();

		// Unsubscribe
		if (!isNullOrUndefined(this._notificationStateSub)) { this._notificationStateSub.unsubscribe(); }
		if (!isNullOrUndefined(this._ticketTrackerStateSub)) { this._ticketTrackerStateSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	 */

	/**
	 * ================================================================================================================================
	 * Ticket tracking and watch for calling notifications
	 * ================================================================================================================================
	 */

	/**
	 * Gets the ticket information
	 * @param ticketUniqueId Ticket Unique Identification
	 * @param debugMode Debug mode
	 */
	getTicketInformation(ticketUniqueId: string, debugMode?: boolean): void {
		this._logger.info(`${this.serviceName}:getTicketInformation`, 'Status', TicketTrackerStatus[this.ticketTrackerState.status],
			'Ticket Unique Id', ticketUniqueId, 'Debug Mode', debugMode, 'Can Connect?', this._notificationService.canConnect);

		//+ Only connect if we can connect
		//+ By checking the status of the notification service, otherwise service will be in loggedOut state
		//+ without recovery
		if (this._notificationService.canConnect) {
			// Start item tracking status + set ticket unique id
			this.ticketTrackerState = {
				ticketUniqueId: ticketUniqueId,
				status: TicketTrackerStatus.StartItemTracking
			};
		}
	}

	/**
	 * Set TicketTrackerSettings for actions menu
	 * @param entitySettings
	 */
	setTicketTrackerSettings(entitySettings: TicketTrackerSettings) {
		// Set ticket tracker settings
		this.ticketTrackerSettings = entitySettings;
	}

	/**
	 * Set terms and conditions object
	 * @param termsAndConditions
	 */
	setTermsAndConditionsUrl(termsAndConditionsUrl: string) {
		// Set the ticket tracker terms and conditions for Entity
		this.termsAndConditionsUrl = termsAndConditionsUrl;
	}

	/**
	 * Reset Ticket and Booking Info
	 */
	resetTicketAndBookingInfo() {
		this._logger.debug(`${this.serviceName}:resetTicketAndBookingInfo`, 'Status', TicketTrackerStatus[this.ticketTrackerState.status],
			'Ticket Unique Id', this.ticketTrackerState.ticketUniqueId, 'Ticket Info', this.ticketTrackerState.ticketInfo,
			'Booking Info', this.ticketTrackerState.bookingInfo);

		// Clear Ticket and Booking Info
		this.ticketTrackerState = {
			ticketInfo: null,
			bookingInfo: null,
			ticketUniqueId: null
		};
	}

	/**
	 * ================================================================================================================================
	 * Navigation
	 * ================================================================================================================================
	 */

	/**
	 * Goes to a specific page
	 * @param commands extra commands to page
	 * @param newState new state to be assigned if navigation is done correctly
	 */
	gotoPage(commands?: any[], newState?: TicketTrackerState) {
		// Define page
		const page: any[] = isNullOrUndefined(commands) ? ['./'] : ['./'].concat(commands);

		this._logger.debug(`${this.serviceName}:gotoPage`, 'Page', page, 'Commands', commands);

		// Navigate to page
		this._router.navigate(page)
			.then(
				value => {
					// Is home page
					const isHome = page.join() === './';

					this._logger.debug(`${this.serviceName}:gotoPage`, `Navigated '${page.join()}' ?`, value,
						'New State', newState, 'Is Home (if true assign "Home" state)?', isHome);

					// If new state exists, assign it
					if (!isNullOrUndefined(newState)) {
						this.ticketTrackerState = newState;
					}

					// If navigating to home page, set respective Home status
					if (isHome) {
						this.ticketTrackerState = {
							status: TicketTrackerStatus.Home
						};
					}
				},
				error => {
					this._logger.error(`${this.serviceName}:gotoPage`, `Navigated '${page.join()}' ? `, error);
				});
	}

	/**
	 * Returns if search should be disabled to work better in small mobile devices
	 *  - true  > if mobile and height lower than 500px
	 *  - false > if not mobile or mobile with height higher tat 500px
	 */
	disableSearch(): boolean {
		// If window.orientation exists use it ... otherwise try firefox way :/
		// Added by Carlos.Moreira @ 2020/03/05
		const minWidth = 320;
		const maxWidth = 960;

		// Minimum height to enable search
		const minHeightToEnableSearch = 500;
		let disableTheSearch: boolean;

		if (!isNullOrUndefined(window.orientation)) {
			disableTheSearch = window.innerWidth >= minWidth && window.innerWidth <= maxWidth ?
				window.innerHeight < minHeightToEnableSearch : false;

			this._logger.debug(`${this.serviceName}:disableSearch`,
				`Available Width: ${window.innerWidth} and Height: ${window.innerHeight} so disable search ? ${disableTheSearch}`);

		} else {
			disableTheSearch = window.screen.availWidth >= minWidth && window.screen.availWidth <= maxWidth ?
				window.screen.availHeight < minHeightToEnableSearch : false;

			this._logger.debug(`${this.serviceName}:disableSearch`,
				`Available Width: ${window.screen.availWidth} and Height: ${window.screen.height} so disable search ? ${disableTheSearch}`);
		}

		return disableTheSearch;
	}

	/**
	 * Sets menu options
	 * @param menu new menu options
	 * @param override override everything
	 */
	setMenu(menu: TicketTrackerMenu, override: boolean = false) {
		this._logger.debug(`${this.serviceName}:setMenu`, 'New Menu', menu, 'Override?', override);

		// Set menu options
		if (override) {
			// by overriding all menu settings
			this.ticketTrackerState = {
				menu: menu,
				menuButton: null
			};
		} else {
			// by merging with existing ones
			this.ticketTrackerState = {
				menu: {
					...this.ticketTrackerState.menu,
					...menu,
				},
				menuButton: null
			};
		}

		this._logger.info(`${this.serviceName}:setMenu`, 'New Menu', menu, 'Override?', override,
			'Menu', this.ticketTrackerState.menu, 'TicketTrackerState', this.ticketTrackerState);
	}

	/**
	 * Set menu button clicked action
	 * @param button
	 */
	setButtonClicked(button: TicketTrackerMenuButton) {
		this._logger.debug(`${this.serviceName}:setButtonClicked`, 'Button', button);

		// Set and emit new button clicked
		this.ticketTrackerState = {
			menuButton: button,
			isBookingFormInEditMode: button === TicketTrackerMenuButton.Edit ? true :
				button === TicketTrackerMenuButton.Cancel ? false : this.ticketTrackerState.isBookingFormInEditMode
		};

		// Clean last button pressed
		this.ticketTrackerState.menuButton = null;
	}

	/**
	 * ================================================================================================================================
	 * Errors
	 * ================================================================================================================================
	*/

	/**
	 * Handles the error or ok messages to UI
	 * @param error Error|Information|Warning
	 */
	public handleErrors(uiMessagesService: UIMessagesService, translateService: TranslateService, error: any, defaultMessage: string = null) {
		// Get error code
		const errorCode: ApiErrors = isNullOrUndefined(error) ? ApiErrors.UnknownError : error.details;

		// Error or ok message
		// Different messages and types of messages to the user
		switch (errorCode) {
			case ApiErrors.Ok:
				break;

			case ApiErrors.Queue_NoSchedules:
				uiMessagesService.showToaster('',
					TicketTrackerErrorHandling.getErrorMessage(errorCode, translateService, defaultMessage), 'info', null, null, null, null,
					'bottom');
				break;

			case ApiErrors.Queue_TicketNotOnCorrectState:
			case ApiErrors.Bookings_ErrorReservingBooking_InvalidData:
			case ApiErrors.Bookings_ErrorAddingBooking_InvalidData:
			case ApiErrors.Bookings_ErrorAddingBooking_Timeslot_Unavailable_Or_Without_Capacity:
			case ApiErrors.Bookings_ErrorConfirmingBooking_InvalidData:
				uiMessagesService.showToaster('',
					TicketTrackerErrorHandling.getErrorMessage(errorCode, translateService), 'warning', null, null, null, null,
					'bottom');
				break;

			case ApiErrors.Timeslots_ErrorGettingDailyAvailability:
			case ApiErrors.Bookings_ErrorReservingBooking:
			case ApiErrors.Bookings_ErrorAddingBooking:
			case ApiErrors.Bookings_ErrorAddingBooking_NotInReservedState:
			case ApiErrors.Bookings_ErrorUpdatingBooking:
			case ApiErrors.Bookings_ErrorUpdatingBooking_NotInAnAllowedState:
			case ApiErrors.Bookings_ErrorUpdatingBooking_NoMoreChangesAllowed:
			case ApiErrors.Bookings_ErrorConfirmingBooking:
			case ApiErrors.Bookings_ErrorConfirmingBooking_NotInAnAllowedState:
			case ApiErrors.Bookings_ErrorCancelingBooking:
			case ApiErrors.Bookings_ErrorCancelingBooking_NotInAnAllowedState:
			case ApiErrors.Bookings_ErrorCheckingInBooking:
			case ApiErrors.Bookings_ErrorCheckingInBooking_NotInAnAllowedState:
			case ApiErrors.Bookings_ErrorCheckingInBooking_NoTicket:
			case ApiErrors.Bookings_ErrorReschedulingBooking:
			case ApiErrors.Bookings_ErrorReschedulingBooking_ErrorDeletingReservation:
			case ApiErrors.Bookings_ErrorCheckingBookingOwnership_Not_Owner:
				uiMessagesService.showToaster('',
					TicketTrackerErrorHandling.getErrorMessage(errorCode, translateService), 'error', null, null, null, null,
					'bottom');
				break;

			default:
				let icon: SweetAlertIcon;

				//+ Information:
				//+		- All queue and service errors
				//+		- All booking errors
				//+ Error:
				//+		- all other not above

				if (errorCode >= 90000 && errorCode <= 99999 || (errorCode >= 501000 && errorCode <= 501999)) {
					icon = 'info';
				} else {
					icon = 'error';
				}

				uiMessagesService.showToaster('',
					TicketTrackerErrorHandling.getErrorMessage(errorCode, translateService, defaultMessage),
					icon, null, null, null, 'bottom');
				break;
		}
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * ================================================================================================================================
	 * Server Notifications
	 * ================================================================================================================================
	 */

	/**
	 * Watches notification service status
	 */
	private watchNotificationStatus() {
		// ===============================================================================================
		// Start watching for notification service status
		// ===============================================================================================

		// Clean previous subscription
		// Added by Carlos.Moreira @ 2019/09/19
		if (!isNullOrUndefined(this._notificationStateSub)) { this._notificationStateSub.unsubscribe(); }

		// ===============================================================================================
		// Start watching for notification service status
		// Subscribe notification observable to only watch for FULL "Notification State Changes"
		// ===============================================================================================
		this._notificationStateSub =
			this._notificationService.notificationState$
				.pipe(
					distinctUntilChanged(),
					filter(notificationState =>
						!isNullOrUndefined(notificationState) &&
						this._isNotificationOnline.value !== notificationState.isOnline)
				)
				.subscribe(notificationState => {
					// ===============================================================================================
					// New status, so process it
					// ===============================================================================================
					this._logger.info(`${this.serviceName}:watchNotificationStatus`,
						'Watching Notification State', 'New State', JSON.stringify(notificationState),
						'New Status', NotificationStatus[notificationState.notificationStatus],
						'Is Online?', notificationState.isOnline);

					// Assign new online state and emit observable with notification online status
					// To emit warning in case the connection ends and it shouldn't :(
					// Added by Carlos.Moreira @ 2020/04/06
					this._isNotificationOnline.next(notificationState.isOnline);

					// Is Online ?
					if (notificationState.isOnline) {
						// Set flag isInitialize to true
						this.ticketTrackerState.isInitialize = true;

						// If Online ... start watching notification notifications
						this.watchNotificationNotifications();

						this._logger.info(`${this.serviceName}:watchNotificationStatus`,
							'Start watching notification service notifications');

					} else {
						this._logger.info(`${this.serviceName}:watchNotificationStatus`,
							'Stop watching notification service notifications - unsubscribe');

						// If Offline ... stop watching notification notifications
						if (!isNullOrUndefined(this._notificationNotificationsSub)) { this._notificationNotificationsSub.unsubscribe(); }
					}
				});
	}

	/**
	 * Watches notifications that came from the server (trow notification service)
	 */
	private watchNotificationNotifications() {

		// Clean previous subscription
		// Added by Carlos.Moreira @ 2019/09/19
		if (!isNullOrUndefined(this._notificationNotificationsSub)) { this._notificationNotificationsSub.unsubscribe(); }

		// ===============================================================================================
		// Start watching for notifications that came from the server
		// ===============================================================================================
		this._notificationNotificationsSub =
			this._notificationService.notificationNotifications$
				.pipe(
					filter(notification => !isNullOrUndefined(notification))
				)
				.subscribe((notification: NotificationNotification) => {
					// ===============================================================================================
					// Received notification from server ... so do stuff ...
					// ===============================================================================================
					this._logger.debug(`${this.serviceName}:watchNotificationNotifications`, 'Watch',
						'Notification', notification);

					// Notification received can be already in QoreNotification format or in text format
					const notificationReceived: QoreNotification =
						typeof notification.notification === 'string' ?
							JSON.parse(notification.notification) as QoreNotification : notification.notification as QoreNotification;

					switch (notificationReceived.action) {
						case 'configuration':
							// Receive the configuration
							this._logger.info(`${this.serviceName}:watchNotificationNotifications`, 'Watch',
								'Configuration', 'Ticket Tracker Configurations', notificationReceived);

							let param: Parameter;
							if (!isNullOrUndefined(notificationReceived.details)) {
								param = JSON.parse(notificationReceived?.details as string) as Parameter;
							}

							// Check to see if we have ticket information and set next state
							if ((isNullOrUndefined(param) || isNullOrUndefined(param.parameter) || param.parameter === '')
								&& isNullOrUndefined(this.ticketTrackerState.ticketInfo)) {

								//+ Ticket not found because no configurations have been received or no ticket info exists

								//+ Set TicketNotFound state
								this.ticketTrackerState = {
									status: TicketTrackerStatus.TicketNotFound
								};

								// If ticket isn't found, disconnects from notification service
								// Added by Carlos.Moreira @ 2020/01/03
								this.disconnectFromNotificationService();

							} else {
								//+ Ticket is found

								// Parse Configurations
								let ticketTrackerConfiguration: TicketTrackerConfiguration;

								if (!isNullOrUndefined(param?.parameter)) {
									ticketTrackerConfiguration = JSON.parse(param?.parameter);

									// Ticket tracker configurations
									let ticketTrackerSettings: TicketTrackerSettings;

									// Parse settings string into settings object (only if exists)
									if (!isNullOrUndefined(ticketTrackerConfiguration.entitySettings.settings)) {
										ticketTrackerConfiguration.entitySettings.settings =
											JSON.parse(ticketTrackerConfiguration.entitySettings.settings as string) as EntityAppsSettings;

										// Get ticket tracking settings
										ticketTrackerSettings =
											(ticketTrackerConfiguration.entitySettings.settings as EntityAppsSettings).ticketTrackerSettings;
									}

									//+ Set ShowAndTrackItem or ShowItem state according to status of ticket (that can associated with a booking)
									this.ticketTrackerState = {
										status: ticketTrackerConfiguration.ticketInfo.statusId !== RecordStatus.Finished ?
											TicketTrackerStatus.ShowAndTrackItem : TicketTrackerStatus.ShowItem,

										ticketUniqueId: ticketTrackerConfiguration.ticketInfo.ticketUniqueId,

										entitySettings: ticketTrackerConfiguration.entitySettings,
										storeSettings: ticketTrackerConfiguration.storeSettings,
										ticketInfo: ticketTrackerConfiguration.ticketInfo,
										ticketLayout: ticketTrackerConfiguration.ticketLayout,

										// Hide Home Button (entity settings override)
										menu: {
											...this.ticketTrackerState.menu,
											...{
												homeButton: isNullOrUndefined(ticketTrackerSettings) ||
													// eslint-disable-next-line max-len
													isNullOrUndefined(ticketTrackerSettings.hideHomeButton) ? true : !ticketTrackerSettings.hideHomeButton
											}
										}
									};

									// Check current state
									if (this.ticketTrackerState.status === TicketTrackerStatus.ShowAndTrackItem) {
										// If tickets have been already called, emit "n" status event to show each ticket
										// Only emit if ticket isn't already finished
										// Added by Carlos.Moreira @ 2019/07/26
										if (!isNullOrUndefined(ticketTrackerConfiguration.lastTicketsCalled)) {
											// Reverse ticket order so that last ticket called is the first in UI panels
											// Added by Iuri.Cunha @ 2019/08/05
											ticketTrackerConfiguration.lastTicketsCalled.reverse();

											ticketTrackerConfiguration.lastTicketsCalled.forEach((ticket, index) => {
												// Add delay to allow every ticket to be correctly consumed :)
												setTimeout(() => {
													// Ticket called state event
													this.ticketTrackerState = {
														status: TicketTrackerStatus.ItemCalledToPanel,
														lastTicket: ticket
													};
												}, 500 * index);
											});
										}
									} else {
										// If ticket is finished, disconnects from notification service
										// Added by Carlos.Moreira @ 2022/12/07
										this.disconnectFromNotificationService();
									}
								}
							}
							break;

						case 'ticket':
							// Indicates that the a new ticket has been sent to be displayed
							// Message is like this  {"action":"ticket","details":"{"type":2,"parameter":\"{ticketId:\"123\", ... \"}"}
							this._logger.info(`${this.serviceName}:watchNotificationNotifications`, 'Watch',
								'Ticket', 'Ticket to Show', notificationReceived);

							// Update ticket tracker status with new ticketed received
							this.ticketTrackerState = {
								status: TicketTrackerStatus.ItemCalledToPanel,
								lastTicket: JSON.parse((JSON.parse(notificationReceived.details as string) as Parameter).parameter)
							};

							// If ticket or booking called is ours emit ticket called state
							// Added by Carlos.Moreira @ 2019/07/30
							if (this.ticketTrackerState.ticketInfo.ticketUniqueId === this.ticketTrackerState.lastTicket.ticketUniqueId) {
								this.ticketTrackerState = {
									status: TicketTrackerStatus.ItemCalled
								};
							}

							// Deletes the state (because it was already consumed)
							this._notificationService.notificationsNullState();
							break;

						case 'refresh':
						case 'reset':
							this._logger.warn(`${this.serviceName}:watchNotificationNotifications`, 'Watch',
								'Refresh and Reset', 'Not implemented !');
							break;

						default:
							this._logger.warn(`${this.serviceName}:watchNotificationNotifications`, 'Watch',
								'Unknown', 'Notification', notificationReceived);
					}
				});
	}

	/**
	 * Starts watching server notifications
	 */
	private startWatchServerNotifications() {

		try {
			// ===============================================================================================
			// 0. Watch Notification service status
			// ===============================================================================================
			this.watchNotificationStatus();

			// ===============================================================================================
			// 1. If ticket tracker state is initialized and with ticket tracker object ...
			//	  connect to notification server
			// ===============================================================================================
			this._ticketTrackerStateSub = this._ticketTrackerState
				.subscribe(
					ticketTrackerState => {
						this._logger.info(`${this.serviceName}:startWatchServerNotifications`, 'Ticket Tracker Ticket UniqueId',
							ticketTrackerState.ticketUniqueId, 'State', TicketTrackerStatus[ticketTrackerState.status],
							'Is notification online ?', this._isNotificationOnline.value);

						switch (ticketTrackerState.status) {
							case TicketTrackerStatus.StartItemTracking:
								// If already connected to notifications, exit (this occurs whe doing a re-initialization)
								if (this._isNotificationOnline.value) { return; }

								// ===============================================================================================
								// 1. Reset number of retries in notification server
								// ===============================================================================================

								this._notificationService.resetNumberOfRetries();

								// ===============================================================================================
								// 2. Connect to notification server
								// ===============================================================================================
								this._notificationService.connect(
									{
										identification: ticketTrackerState.ticketUniqueId,
										type: ConnectionType.Ticket
									}
								);

								this._logger.info(`${this.serviceName}:startWatchServerNotifications`, 'Connecting',
									'Ticket Tracker Ticket UniqueId', ticketTrackerState.ticketUniqueId);
								break;

							case TicketTrackerStatus.ShowItem:
							case TicketTrackerStatus.ShowAndTrackItem:
							case TicketTrackerStatus.ItemCalled:
							case TicketTrackerStatus.PleaseGotoCallingArea:
							case TicketTrackerStatus.ItemCalledToPanel:
								// Do nothing
								break;

							default:
								// If already offline from notifications, exit
								if (!this._isNotificationOnline.value) { return; }

								this._logger.info(`${this.serviceName}:startWatchServerNotifications`, 'Disconnecting',
									'Ticket Tracker Ticket UniqueId', ticketTrackerState.ticketUniqueId);

								// Ask for disconnection of notification service
								if (!isNullOrUndefined(this._notificationService)) {
									this._notificationService.disconnect()
										.finally(() => {
											// Reset ticket tracker state (cleaning also the previous settings and ticket if they exist)
											this.ticketTrackerState = {
												isInitialize: false,

												entitySettings: null,
												storeSettings: null,

												ticketUniqueId: '',
												ticketInfo: null,
												ticketLayout: null,
												lastTicket: null,
											};
										});
								}
						}
					});

		} catch (error) {
			this._logger.error(`${this.serviceName}:startWatchServerNotifications`, 'Error', error);
		}
	}

	/**
	 * Disconnects from Notification Service
	 */
	private disconnectFromNotificationService() {
		this._logger.debug(`${this.serviceName}:disconnectFromNotificationService`,
			'Is notification online ?', this._isNotificationOnline.value, 'Notification service', this._notificationService);

		// If already offline from notifications, exit
		if (!this._isNotificationOnline.value) { return; }

		// Ask for disconnection of notification service
		if (!isNullOrUndefined(this._notificationService)) { this._notificationService.disconnect(); }
	}
}
