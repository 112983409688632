<div id="view">
	<!-- HEADER -->
	<h2 id="main-title">{{'MY_TICKETS.TITLE' | translate}}</h2>

	<!-- CLEAR BUTTON -->
	<button type="button" id="clear-button" (click)="removeExpiredItems()" title="clear-button"><i class="fa fa-trash"
			aria-hidden="true"></i></button>

	<!-- INSTRUCTIONS PLACEHOLDER -->
	<div *ngIf="myItemsExist" id="instruction">{{'MY_TICKETS.INSTRUCTION' | translate}}</div>

	<div id="main-container" [class.activate-scroll]="myItemsExist && !isLoading">
		<!-- TICKET -->
		<div class="card" *ngFor="let item of myItems" (click)="gotoItemTracking(item.uniqueId, item.type)">
			<!-- HEADER NOTIFICATIONS -->
			<div class="header-notifications">
				<div class="icon">
					<i class="fa" [ngClass]="item.type !== ServiceTypeEnum.Booking ? 'fa-ticket' : 'fa-book'"></i>
					<span *ngIf="item.type !== ServiceTypeEnum.Booking">{{item.ticketFormated}}</span>
				</div>
				<div class="status" *ngIf="item.status !== undefined">
					{{'MY_TICKETS.' + (!item.bookingCode ? 'TICKET_STATUS' : 'BOOKING_STATUS') + '.STATUS_' +
					item.status | translate}}
				</div>
			</div>

			<!-- HEADER -->
			<div class="header">
				<!-- TITLES -->
				<div class="titles">
					<div class="entity-name">{{item.entityName}}</div>
					<div class="store-name">
						<i class="fa fa-home"></i>
						{{item.storeName | qmultilanguage:curLangCode:false:true}}
					</div>
				</div>
				<!-- TIME -->
				<div class="time">
					<div class="hours">
						<span *ngIf="!item.bookingCode; else bookingTime">
							{{item.printingHour | qmoment:'LT':curLangCode}}
						</span>
						<ng-template #bookingTime>
							<span>{{item.start | qmoment:'local-time':curLangCode}} -
								{{item.end | qmoment:'local-time':curLangCode}}</span>
						</ng-template>
					</div>
					<div class="date">
						{{(!item.bookingCode ? item.printingHour : item.start) | qmoment:'L':curLangCode}}
					</div>
				</div>
			</div>

			<!-- INFO -->
			<div class="info">
				<div class="service" *ngIf="item.serviceName">
					<i class="fa fa-play"></i>{{item.serviceName | qmultilanguage:curLangCode:false:true}}
				</div>
				<div class="user" *ngIf="item.userName">
					<i class="fa fa-user"></i>{{item.userName}}
				</div>
				<div class="booking-resume" *ngIf="item.resume">
					<i class="fa fa-address-book"></i>{{item.resume}}
				</div>
			</div>
		</div>

		<!-- LOADER -->
		<qevo-loader loadingMessage="GLOBAL.LOADING" *ngIf="isLoading"></qevo-loader>

		<!-- NO TICKETS PLACEHOLDER -->
		<qevo-no-data *ngIf="!myItemsExist && !isLoading && !isError" text="MY_TICKETS.NO_ITEMS_FOUND"></qevo-no-data>

		<!-- ERROR PLACEHOLDER -->
		<qevo-no-data *ngIf="isError" id="error" text="MY_TICKETS.ERROR"></qevo-no-data>

		<!-- ERROR REFRESH BUTTON -->
		<div *ngIf="isError" id="error-try-again" (click)="refreshEvent()">{{'MY_TICKETS.TRY_AGAIN' | translate}}</div>
	</div>
</div>