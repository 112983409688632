/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class ScheduleBookingMenuComponent
 * @description
 * Scheduling Booking Menu Component
 * Created by André.Pinho @ 2022/09/26
 */

// Angular Components
import { Component } from '@angular/core';

@Component({
	selector: 'qoala-tt-schedule-booking-menu',
	templateUrl: './schedule-booking-menu.component.html',
	styleUrls: ['./schedule-booking-menu.component.scss']
})
export class ScheduleBookingMenuComponent {
	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor() { }
}
