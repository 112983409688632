/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @module CoreModuleCore
 * @description
 * Core Module Core
 * Created by Carlos.Moreira @ 2019/07/19
 */
// Angular Components
import { NgModule, SkipSelf, Optional, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, UrlSerializer } from '@angular/router';
import { HttpClient } from '@angular/common/http';

// Translation
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TicketTrackerHttpLoaderFactory } from './others/translation.factory';

// For responsive specific components
// import { ResponsiveModule } from 'ngx-responsive';

// Cookies
import { CookieService } from 'ngx-cookie-service';

// Core Components
import { ErrorComponent } from './components/error/error.component';
import { CoreConfig } from './core.config';
import { TicketTrackerService } from './services/ticket-tracker.service';
import { MyItemsService } from './services/items/my-items.service';
import { TicketsService } from './services/tickets/tickets.service';
import { BookingsService } from './services/bookings/bookings.service';
import { TicketTrackerBookingsMngProviderService } from './services/bookings/ticket-tracker-bookings-mng-provider.service';

// Qore Environments
import { environment } from '../../environments/environment.local';

// Libraries Components
import {
	LoggerService, QevoServicesModule, QevoServicesConfiguration,
	UIMessagesService, LowerCaseUrlSerializer
} from 'qevo.services';
import { QevoUtilitiesModule } from 'qevo.utilities';
import { QoreServicesModule, QoreServicesConfiguration } from 'qore.services';
import { ManagerBookingsMngProviderService } from 'qore.bookings.agendas';

// String Extensions
import 'qevo.utilities';

// Qevo Services Configuration Library Configurations
// NOTE:
// 	- Needs to be here because of the environment variable above that isn't recognize in a
// 	  separated file
// Added by Carlos.Moreira @ 2019/07/03
export const QevoServicesLibraryConfiguration: QevoServicesConfiguration = {
	loggerConfig: environment.configurations.logger,
	serviceConfiguration: {
		url: '',
		authenticationUrl: environment.configurations.authenticationUrl,
		listDefaults: environment.configurations.listDefaults
	},
	notificationConfig: {
		url: environment.configurations.notificationUrl,
		retries: {
			maxNumberOfRetries: environment.configurations.notification.maxNumberOfRetries,
			minTimeSpanLoggedToResetMaxNumberOfRetries: environment.configurations.notification.minTimeSpanLoggedToResetMaxNumberOfRetries,
			timeBetweenRetries: environment.configurations.notification.timeBetweenRetries,
			maxNumberOfRecoverRetries: environment.configurations.notification.maxNumberOfRecoverRetries,
			timeBetweenRecoverRetries: environment.configurations.notification.timeBetweenRecoverRetries,
			reloadAfterRecoverRetriesFailed: environment.configurations.notification.reloadAfterRecoverRetriesFailed,
			maxNumberOfReloadAfterFailedRecoverRetries: environment.configurations.notification.maxNumberOfReloadAfterFailedRecoverRetries
		}
	},
	authConfig: {
		url: environment.configurations.authenticationUrl,
		urlsToAddAuthenticationToken: [
			environment.configurations.authenticationUrl
		]
	},
	thirdParty: null
};

@NgModule({
	declarations: [
		ErrorComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		// ResponsiveModule,
		RouterModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (TicketTrackerHttpLoaderFactory),
				deps: [HttpClient],
			},
			isolate: false
		}),
		QoreServicesModule.forRoot(QevoServicesLibraryConfiguration as QoreServicesConfiguration),
		QevoUtilitiesModule,
		QevoServicesModule.forRoot(QevoServicesLibraryConfiguration),
	],
	exports: [
		TranslateModule,
		FormsModule,
		RouterModule
	]
})
export class CoreModuleCore {
	constructor(@Optional() @SkipSelf() parentModule: CoreModuleCore) {
		if (parentModule) {
			throw new Error(
				'CoreModuleCore is already loaded. Import it in the QoalaTicketTrackerModule only!');
		}
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: CoreModuleCore,
			providers: [
				LoggerService,
				CoreConfig,
				UIMessagesService,
				TicketTrackerService,
				TicketsService,
				BookingsService,
				{
					provide: UrlSerializer,
					useClass: LowerCaseUrlSerializer
				},
				MyItemsService,
				TicketTrackerBookingsMngProviderService,
				{
					provide: ManagerBookingsMngProviderService,
					useExisting: TicketTrackerBookingsMngProviderService,
				},
				CookieService
			]
		};
	}

	static forChild(): ModuleWithProviders {
		return {
			ngModule: CoreModuleCore
		};
	}
}
