<div id="entity">
  <label>{{'NEW_TICKET.ENTITY' | translate}}</label>

  <div class="select-container">
    <ng-select class="ng-entity-select" [searchable]="true && !disableSearch" [isOpen]="true"
      [items]="availableEntities" [virtualScroll]="true" bindValue="id" bindLabel="name"
      placeholder="{{'NEW_TICKET.CHOOSE_ENTITY' | translate }}" notFoundText="{{'GLOBAL.NO_ITEMS_FOUND' | translate}}"
      (change)="itemSelected($event)" (clear)="clearEntity()" [(ngModel)]="entity" [loading]="loadingEntities"
      #entitiesSelect>
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <img alt="public logo" class="ng-value-image white-space"
          [src]="isNullOrUndefined(item.publicLogo) ? './assets/img/empty.png' : item.publicLogo" />
        <span class="ng-value-label">{{item.name}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="id">
        <img alt="public logo" class="ng-option-image white-space"
          [src]="isNullOrUndefined(item.publicLogo) ? './assets/img/empty.png' : item.publicLogo" />
        <span class="ng-option-label">{{item.name}}</span>
        <span class="ng-option-description">{{item.description}}</span>
      </ng-template>
    </ng-select>
  </div>
</div>
