<div id="view">

	<h2 id="main-title">{{ 'TAKE_TICKET_MENU.SELECT_AN_OPTION' | translate }}</h2>

	<div id="main-container">

		<div id="center">
			<div id="scan-flyer">
				<button [routerLink]="['../qr-code-scanner/1']">{{ 'TAKE_TICKET_MENU.SCAN_FLYER_QR_CODE' | translate }}</button>
				<p> {{ 'TAKE_TICKET_MENU.SCAN_FLYER_QR_CODE_INFO' | translate }}</p>
			</div>

			<div id="find-service">
				<button [routerLink]="['../new-ticket']">{{ 'TAKE_TICKET_MENU.SELECT_SERVICE_MANUALLY' | translate }}</button>
				<p> {{ 'TAKE_TICKET_MENU.SELECT_SERVICE_MANUALLY_INFO' | translate }}</p>
			</div>
		</div>

	</div>
</div>
