/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * Main
 * @description
 * Starts the App
 * Created by Carlos.Moreira @ 2019/07/02
 */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { QoalaTicketTrackerModule } from './app/qoala-ticket-tracker.module';
import { environment } from './environments/environment.local';

import { hmrBootstrap } from './hmr';

// Need because of Hot Module Reload in Angular 9 after changing code or library components
// Added by Carlos.Moreira @ 2020/05/28
import '@angular/compiler';

if (environment.production) {
	enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(QoalaTicketTrackerModule);

if (environment.hmr) {
	// eslint-disable-next-line @typescript-eslint/dot-notation
	if (module['hot']) {
		hmrBootstrap(module, bootstrap);
	} else {
		console.error('HMR is not enabled for webpack-dev-server!');
		console.log('Are you using the --hmr flag for ng serve?');
	}
} else {
	bootstrap().then(() => {
		if ('serviceWorker' in navigator && environment.production) {
			console.log('Registering service worker for Qoala TT!');
			navigator.serviceWorker.register('./ngsw-worker.js');
		}
	});
}
