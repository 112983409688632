<div id="view" [class.no-window]="!showEntityMenu">
	<!-- HEADER -->
	<div id="view-header" *ngIf="showEntityMenu" [class.with-back-button]="!showEntityMenu && !showLogo">
		<h2 id="main-title">
			{{ 'NEW_BOOKING.' + (isReschedule ? 'RESCHEDULE_TITLE' : 'TITLE') | translate }}
		</h2>
	</div>

	<!-- SELECT ENTITY -->
	<div id="entity" *ngIf="showEntityMenu">
		<qoala-entity-selector-menu [entityId]="entityId" [getBookingsEntities]="true"
			(selectedEntity)="selectedEntity($event)">
		</qoala-entity-selector-menu>
	</div>

	<!-- Open wizard -->
	<qore-bookings-bookings-wizard *ngIf="!showEntityMenu" [class.fullscreen]="isWizardFullscreen"
		[class.is-customization]="!showEntityMenu && !showLogo" [bookingWizardUI]="bookingWizardUI"
		[deactivateSelectSearch]="true" [hideUnavailableSlots]="true" [curLangCode]="curLangCode"
		[languagesList]="languagesList" [hideNavigationElements]="true" [actions$]="actions$"
		[customSettings]="wizardCustomSettings" [disableCalendarInteraction]="true" (closeEvent)="closeEvent($event)"
		(enableOrDisableNextButtonEvent)="enableOrDisableNextButtonEvent($event)"
		(closeCancelModalEmitter)="enableFooter()" (stepIsFinalEvent)="setStepAsFinal($event)"
		(openCancelModalEmitter)="disableFooter()" (stepIsDoneEvent)="setStepIsDone($event)"
		(canReserveEvent)="canReserveEvent($event)" (modalEvent)="modalEvent($event)"
		(currentStepTypeEvent)="currentStepTypeEvent($event)">
	</qore-bookings-bookings-wizard>
</div>
