<!-- MAIN CARD -->
<ng-container *ngIf="!isNullOrUndefined(cardInfo)">
	<div id="card" [class.minimize]="isScrollingUp" [class.maximize]="!cardInfo.ticketUniqueId"
		[ngClass]="(cardInfo.status !== '' && cardInfo.status !== undefined) ? 'card-status' + cardInfo.statusLevel : null"
		[class.no-notification]="cardInfo.status === ''" [class.is-booking]="cardInfo.type === ServiceTypeEnum.Booking"
		[class.is-booking-and-ticket]="cardInfo.type === ServiceTypeEnum.Booking && cardInfo.ticketFormated">

		<!-- HEADER -->
		<div id="header" class="center-block-helper" [class.reduction]="cardInfo.isMyTicket && cardInfo.etsString">
			<div class="center-block">
				<!-- BOOKING ICON -->
				<span id="icon" class="fa-stack" *ngIf="cardInfo.type === ServiceTypeEnum.Booking">
					<i class="fa fa-circle fa-stack-2x"></i>
					<i class="fa fa-book fa-stack-1x fa-inverse"></i>
				</span>
				<!-- TITLES -->
				<div id="titles">
					<!-- ENTITY NAME -->
					<span id="entity-name">{{cardInfo.entityName}}</span>
					<!-- STORE NAME -->
					<span id="store-location">{{cardInfo.storeName | qmultilanguage:curLangCode:false:true}}</span>
				</div>
				<!-- TIME -->
				<div id="time" *ngIf="cardInfo.ticketPrintingHour">
					<span id="hours">{{cardInfo.ticketPrintingHour | qmoment:'LT':curLangCode}}</span>
					<span id="date">{{cardInfo.ticketPrintingHour | qmoment:'L':curLangCode}}</span>
				</div>
			</div>
		</div>

		<!-- CALLING INFO -->
		<div id="calling-info" class="center-block-helper" [class.has-animation]="cardInfo.animateNameToDisplay">
			<div class="center-block">
				<div id="animation-container">
					<!-- TICKET NUMBER OR NAME ON DISPLAY + ANIMATION IF NEEDED -->
					<div id="ticket-number" *ngIf="cardInfo.nameToDisplay"
						[class.is-name-to-display]="!cardInfo.isTicketNumber"
						[class.animate]="cardInfo.animateNameToDisplay"
						[style.animation-duration.s]="cardInfo.animationDuration" #nameToDisplayElement>
						{{cardInfo.nameToDisplay}}
					</div>

					<div id="booking-start" *ngIf="cardInfo.type === ServiceTypeEnum.Booking">
						<span id="date">
							<div id="weekday">{{cardInfo.start | qmoment:'dddd, ':curLangCode}}</div>
							{{cardInfo.start | qmoment:'DD MMMM':curLangCode}}
						</span>
						<span id="start-time">
							<i class="fa fa-clock-o"></i>{{cardInfo.start | qmoment:'local-time':curLangCode}}
						</span>
					</div>

					<!-- SERVICE NAME -->
					<span id="service-name">
						{{cardInfo.serviceName | qmultilanguage:curLangCode:false:true}}
					</span>

					<div id="info" *ngIf="cardInfo.userName || cardInfo.resume">
						<!-- USER NAME -->
						<span id="user-name" *ngIf="cardInfo.userName">
							<i class="fa fa-user" aria-hidden="true"></i>{{cardInfo.userName}}
						</span>
						<!-- RESUME -->
						<span id="resume" *ngIf="cardInfo.resume">
							<i class="fa fa-address-book" aria-hidden="true"></i>{{cardInfo.resume}}
						</span>
					</div>
				</div>
			</div>
		</div>

		<!-- STATUS -->
		<div id="notifications" *ngIf="cardInfo.status !== ''" class="center-block-helper">
			<div class="center-block">
				<span id="notification">
					<i class="fa fa-bell"></i>{{cardInfo.statusResource | translate}}
				</span>
				<span *ngIf="cardInfo.etsString" id="ticket-ets">
					{{cardInfo.etsString}}
				</span>
			</div>
		</div>

		<!-- OWNERSHIP -->
		<div id="ownership-button" *ngIf="cardInfo.isMyItem" class="center-block-helper">
			<div class="center-block">
				<span id="ownership" (click)="showOwnershipModal($event)">
					<span id="icon">
						<i class="fa fa-certificate"></i>
						<i class="fa fa-check"></i>
					</span>
					{{'TICKET.SHOW_OWNERSHIP' | translate}}
				</span>
			</div>
		</div>

		<!-- Hot Button -->
		<div id="hot-button"
			*ngIf="cardInfo.type === ServiceTypeEnum.Booking && !cardInfo.ticketUniqueId && cardInfo.hotButton"
			class="center-block-helper">
			<div class="center-block">
				<span id="action-button" (click)="doAction(cardInfo.hotButton.event)"
					[class.disabled]="cardInfo.disableActions">
					<i class="fa" [class.fa-check]="cardInfo.hotButton.type === BookingActionEnum.CheckIn"
						[class.fa-book]="cardInfo.hotButton.type === BookingActionEnum.Reschedule"
						[class.fa-times]="cardInfo.hotButton.type === BookingActionEnum.Cancel"></i>
					{{cardInfo.hotButton.label | translate}}
				</span>
			</div>
		</div>

		<!-- More Actions -->
		<div id="more-actions" *ngIf="cardInfo.type === ServiceTypeEnum.Booking" class="center-block-helper">
			<div class="center-block">
				<span id="more-actions-button" (click)="doAction(MoreActionEnum.MoreActions)"
					[class.disabled]="cardInfo.disableActions">
					<i class="fa fa-ellipsis-h"></i>
					{{'TICKET_TRACKER.ELEMENTS.HOT_BUTTON.TYPE.MORE_ACTIONS' | translate}}
				</span>
			</div>
		</div>
	</div>
</ng-container>

<!-- OWNERSHIP MODAL -->
<swal #ownershipModal [showCancelButton]="false" [showConfirmButton]="false" [showCloseButton]="false"
	customClass="ownership-modal-container">
	<div *swalPortal id="ownership-modal">
		<div id="view">
			<!-- TITLE -->
			<h2 id="main-title">{{'TICKET.OWNERSHIP' | translate}}</h2>
			<div id="main-container">
				<div id="center">
					<!-- TICKET -->
					<div id="modal-ticket-number">
						<p>
							{{cardInfo.type === ServiceTypeEnum.Booking ? ('TICKET.BOOKING_TITLE' | translate )
							:('TICKET.TITLE' | translate)}}
						</p>
						<span *ngIf="cardInfo.type !== ServiceTypeEnum.Booking">{{cardInfo.nameToDisplay}}</span>
					</div>
					<div id="modal-ownership-id">
						{{cardInfo.ownershipId}}<span>{{cardInfo.ownershipIdHighlight}}</span>
					</div>
					<button id="back-ownership" (click)="closeOwnershipModal()">{{'TICKET.BACK' |
						translate}}</button>
				</div>
			</div>
		</div>
	</div>
</swal>

<!-- ACTIONS MODAL -->
<swal #actionsModal id="action-popup" [showCancelButton]="false" [showConfirmButton]="false" [showCloseButton]="false"
	customClass="actions-modal-container msg-box">
	<div *swalPortal id="actions-modal">
		<div id="view">
			<!-- TITLE -->
			<h2 id="main-title">{{'TICKET.MORE_ACTIONS' | translate}}</h2>
			<div id="main-container">
				<div class="center-block" *ngFor="let action of cardInfo.actions">
					<span id="action-button" (click)="doAction(action.event)"
						[class.disabled]="cardInfo.disableActions">
						<i class="fa" [class.fa-check]="action.type === BookingActionEnum.CheckIn"
							[class.fa-book]="action.type === BookingActionEnum.Reschedule"
							[class.fa-times]="action.type === BookingActionEnum.Cancel"
							[class.fa-pencil]="action.type === BookingActionEnum.Update"></i>
						{{action.label | translate}}
					</span>
				</div>
				<div class="center-block">
					<span id="ownership" (click)="showOwnershipModal($event)">
						<span id="icon" class="fa-stack">
							<i class="fa fa-certificate fa-stack-2x"></i>
							<i class="fa fa-check fa-stack-1x"></i>
						</span>
						{{'TICKET.SHOW_OWNERSHIP' | translate}}
					</span>
				</div>

				<div id="close-modal" class="center-block">
					<span id="close" (click)="closeActionsModal($event)">
						{{'TICKET.CLOSE' | translate}}
					</span>
				</div>
			</div>
		</div>
	</div>
</swal>