/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class BookingsService
 * @description
 * Bookings Service
 * Created by André.Pinho @ 2022/09/28 and Modified by Carlos.Moreira @ 2022/11/23
 */

// Angular Components
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';

// Shared Components
import { CoreConfig } from '../../core.config';
import { TakeBookingRules } from '../../models/booking/take-booking-rules.interface';
import { MyItemsService } from '../items/my-items.service';

// Libraries Components
import { LoggerService, ErrorHandling, BaseEmptyService } from 'qevo.services';
import {
	BookingCancel, BookingDetails, BookingReserve, BookingReserved, BookingChecked,
	TimeslotSearch, ServiceDetailsByChannel, BookingAdd, BookingUpdate, UsersWithAssignation
} from 'qore.bookings.models';
import { PaginatedList } from 'qevo.models';
import { isNullOrUndefined } from 'qevo.utilities';
import { EntityAppsSettings } from 'qore.models';

@Injectable()
export class BookingsService extends BaseEmptyService<any> {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */

	constructor(http: HttpClient, logger: LoggerService, private _config: CoreConfig, private _myItemsService: MyItemsService) {
		super(http as any, logger);

		this.serviceName = 'BookingsService';

		// Bookings URL
		this._baseUrl = this._config.configurations.bookingsGatewayUrl +
			(this._config.configurations.bookingsGatewayUrl.endsWith('/') ? '' : '/');
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	 */

	/**
	 * Returns the list of services
	 * @param storeId Store Id
	 */
	getListOfServices(storeId: number): Observable<PaginatedList<ServiceDetailsByChannel>> {
		// Url with parameters
		const url = `${this._baseUrl}v1/ticketTracker/stores/${storeId}/bookings/services`;

		this._logger.debug(`${this.serviceName}:getListOfServices`, 'Url', url);

		return this._http.get<PaginatedList<ServiceDetailsByChannel>>(url, { headers: this._headers })
			.pipe(
				share(),
				map(response => {
					this._logger.debug(`${this.serviceName}:getListOfServices`, 'Url', url,
						'Response', response);
					return response;
				}),
				catchError(error => ErrorHandling.handleError(error, this._logger))
			);
	}

	/**
	 * Returns the users associated to a service with assignation
	 * @param storeId
	 * @param serviceId
	 * @returns
	 */
	getListOfUsersWithAssignation(storeId: number, serviceId: number): Observable<UsersWithAssignation> {
		// Url with parameters
		const url = `${this._baseUrl}v1/ticketTracker/stores/${storeId}/bookings/services/${serviceId}/usersWithAssignation`;

		this._logger.debug(`${this.serviceName}:getListOfUsersWithAssignation`, 'Url', url);

		return this._http.get<UsersWithAssignation>(url, { headers: this._headers })
			.pipe(
				share(),
				map(response => {
					this._logger.debug(`${this.serviceName}:getListOfUsersWithAssignation`, 'Url', url,
						'Response', response);
					return response;
				}),
				catchError(error => ErrorHandling.handleError(error, this._logger))
			);
	}

	/**
	 * Get Daily Availabilities
	 * @param timeslotSearch
	 */
	getDailyAvailabilityList(timeslotSearch: TimeslotSearch): Observable<any> {
		// Url with parameters
		const url = `${this._baseUrl}v1/ticketTracker/timeslots/GetDailyAvailability`;

		this._logger.debug(`${this.serviceName}:getAvailabilityList`, 'Url', url, 'Timeslot Search', timeslotSearch);

		// eslint-disable-next-line max-len
		return this._http.post(url,
			timeslotSearch, { headers: this._headers })
			.pipe(
				share(),
				map(response => {
					this._logger.debug(`${this.serviceName}:getAvailabilityList`, 'Url', url, 'Search parameters:', JSON.stringify(timeslotSearch),
						'Response', response);
					return response;
				}),
				catchError(error => ErrorHandling.handleError(error, this._logger)));
	}

	/**
	 * Get Booking Detail
	 * @param bookingCode
	 */
	getBookingDetailByCode(bookingCode: string): Observable<any | BookingDetails> {
		// Url with parameters
		const url = `${this._baseUrl}v1/ticketTracker/bookings/byCode/${bookingCode}`;

		this._logger.debug(`${this.serviceName}:getBookingDetailByCode`, 'Url', url, 'Booking Code', bookingCode);

		return this._http
			.get(url, { headers: this._headers })
			.pipe(
				share(),
				map(response => {
					this._logger.info(`${this.serviceName}:getBookingDetailByCode`, 'Url', url, 'Booking Code', bookingCode,
						'Response', response);
					return response;
				}),
				catchError(error => ErrorHandling.handleError_(error, this._logger))
			);
	}

	/**
	 * Reserve a booking
	 * @param bookingToReserve
	 */
	reserveBooking(bookingToReserve: BookingReserve): Observable<any | BookingReserved> {
		// Url with parameters
		const url = `${this._baseUrl}v1/ticketTracker/bookings/reserve`;

		this._logger.debug(`${this.serviceName}:reserveBooking`, 'Url', url, 'Booking', bookingToReserve);

		return this._http.post(url, bookingToReserve, { headers: this._headers }).pipe(
			share(),
			map(response => {
				this._logger.info(`${this.serviceName}:reserveBooking`, 'Url', url, 'Response', response);

				return response;
			}),
			catchError(error => ErrorHandling.handleError_(error, this._logger))
		);
	}

	/**
	 * Cancel Booking
	 * @param bookingToCancel
	 */
	cancelBooking(bookingToCancel: BookingCancel): Observable<any> {
		// Url with parameters
		const url = `${this._baseUrl}v1/ticketTracker/bookings/${bookingToCancel.id}/cancel`;

		this._logger.debug(`${this.serviceName}:cancelBooking`, 'Url', url, 'Booking', bookingToCancel);

		// Make the call and return results
		return this._http.post(url, bookingToCancel, { headers: this._headers })
			.pipe(
				share(),
				map(response => {
					this._logger.info(`${this.serviceName}:cancelBooking`, 'Url', url, 'Response', response);

					return response;
				}),
				catchError(error => ErrorHandling.handleError_(error, this._logger))
			);
	}

	/**
	 * Add Booking
	 * @param bookingToAdd
	 */
	addBooking(bookingToAdd: BookingAdd): Observable<any | BookingDetails> {
		// Url with parameters
		const url = `${this._baseUrl}v1/ticketTracker/bookings`;

		this._logger.debug(`${this.serviceName}:addBooking`, 'Url', url, 'Booking', bookingToAdd);

		// Make the call and return results
		return this._http.post(url, bookingToAdd, { headers: this._headers })
			.pipe(
				share(),
				map(response => {
					this._logger.info(`${this.serviceName}:addBooking`, 'Url', url, 'Response', response);

					return response;
				}),
				catchError(error => ErrorHandling.handleError_(error, this._logger))
			);
	}

	/**
	 * Check-in Booking
	 * @param bookingId
	 */
	checkInBooking(bookingId: number): Observable<any | BookingChecked> {
		// Url with parameters
		const url = `${this._baseUrl}v1/ticketTracker/bookings/${bookingId}/checkin`;

		this._logger.debug(`${this.serviceName}:checkInBooking`, 'Url', url, 'Booking Id', bookingId);

		// Make the call and return results
		return this._http.post(url, null, { headers: this._headers })
			.pipe(
				share(),
				map(response => {
					this._logger.info(`${this.serviceName}:checkInBooking`, 'Url', url, 'Response', response);

					return response;
				}),
				catchError(error => ErrorHandling.handleError_(error, this._logger))
			);
	}

	/**
	 * Update booking
	 * @param bookingToUpdate
	 */
	updateBooking(bookingToUpdate: BookingUpdate): Observable<any | BookingDetails> {
		// URL with parameters
		const url = `${this._baseUrl}v1/ticketTracker/bookings/${bookingToUpdate.id}`;

		this._logger.debug(`${this.serviceName}:updateBooking`, 'Url', url, 'Booking', bookingToUpdate);

		// Make the call and return results
		return this._http.put(url, bookingToUpdate, { headers: this._headers })
			.pipe(
				share(),
				map(response => {
					this._logger.info(`${this.serviceName}:updateBooking`, 'Url', url, 'Response', response);

					return response;
				}),
				catchError(error => ErrorHandling.handleError_(error, this._logger))
			);
	}

	/**
	 * Validate booking ownership
	 * @param controlDigits
	 * @param validationDigits
	 */
	validateOwnership(controlDigits: string, validationDigits: string): Observable<string | any> {
		// URL with parameters
		const url = `${this._baseUrl}v1/ticketTracker/bookings/validateOwnership/${controlDigits}/${validationDigits}`;

		this._logger.debug(`${this.serviceName}:validateOwnership`, 'Url', url);

		// Make the call and return results
		return this._http.post(url, null, { headers: this._headers, responseType: 'text' })
			.pipe(
				share(),
				map(response => {
					this._logger.info(`${this.serviceName}:validateOwnership`, 'Url', url, 'Response', response);

					return response;
				}),
				catchError(error => ErrorHandling.handleError_(error, this._logger))
			);
	}

	/**
	 * Check to see if the entity has specific rules for take booking and use them or assign the default ones
	 * @param entityApplicationSettings Entity Application Settings (in string format)
	 */
	setEntityTakeBookingsRules(entityApplicationSettings: string): TakeBookingRules {
		this._logger.debug(`${this.serviceName}:setEntityTakeBookingsRules`, 'Start',
			'Entity Application Settings', entityApplicationSettings);

		let takeBookingRules: TakeBookingRules;

		// Parse settings string into settings object (only if exists)
		if (!isNullOrUndefined(entityApplicationSettings)) {
			const entityAppsSettings: EntityAppsSettings = JSON.parse(entityApplicationSettings as string) as EntityAppsSettings;

			// Get booking tracking settings, if they exist and only consider if exists a take booking rule
			if (!isNullOrUndefined(entityAppsSettings.ticketTrackerSettings?.bookings?.takeBookingRule)) {
				// Assign specific entity ones
				takeBookingRules = {
					maxNumberOfTakenBookings: entityAppsSettings.ticketTrackerSettings.bookings.maxNumberOfTakenBookings,
					takeBookingRule: entityAppsSettings.ticketTrackerSettings.bookings.takeBookingRule
				};

			} else {
				// Assign default ones
				takeBookingRules = {
					maxNumberOfTakenBookings: this._myItemsService.maxNumberOfTakenBookings,
					takeBookingRule: this._myItemsService.takeBookingRule
				};
			}

		} else {
			// Assign default ones
			takeBookingRules = {
				maxNumberOfTakenBookings: this._myItemsService.maxNumberOfTakenBookings,
				takeBookingRule: this._myItemsService.takeBookingRule
			};

		}

		this._logger.debug(`${this.serviceName}:setEntityTakeBookingsRules`, 'End', 'Take Booking Rules', takeBookingRules,
			'Entity Application Settings', entityApplicationSettings);

		return takeBookingRules;
	}
}
