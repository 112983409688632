/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * TicketTrackerErrors
 * @description
 * Ticket Tracker Errors
 * Created by Carlos.Moreira @ 2019/07/04
 */


export enum TicketTrackerErrors {
	OK = 0,
	UnknownError = -1,
	GenericError = -2,
	TicketNotFound = -4,
	BookingNotFound = -5,
	PageNotFound = 404
}
