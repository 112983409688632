/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class TakeTicketMenuComponent
 * @description
 * Take Ticket Menu Component
 * Created by Carlos.Moreira @ 2020/04/17
 */
// Angular Components
import { Component, OnInit } from '@angular/core';

// Ticket Tracker Components
import { MyItemsService } from '../../../core/services/items/my-items.service';
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';

// Libraries Components
import { UIMessagesService } from 'qevo.services';

@Component({
	selector: 'qoala-tt-take-ticket-menu',
	templateUrl: './take-ticket-menu.component.html',
	styleUrls: ['./take-ticket-menu.component.scss']
})
export class TakeTicketMenuComponent implements OnInit {
	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		private _myItemsService: MyItemsService,
		private _uiMessagesService: UIMessagesService,
		private _ticketTrackerService: TicketTrackerService
	) { }

	ngOnInit(): void {
		this._uiMessagesService.initialize();

		if (!this._myItemsService.checkCookieSize()) {
			this._uiMessagesService.showConfirmBoxWithReturn(
				'MY_TICKETS.FULL_COOKIE_STORAGE_WARNING', 'MY_TICKETS.FULL_COOKIE_STORAGE_MESSAGE',
				null, null, null, 'msg-box').then(result => {
					if (result) {
						this._ticketTrackerService.gotoPage(['my-items']);
					} else {
						this._ticketTrackerService.gotoPage();
					}
				});
		}
	}
}
