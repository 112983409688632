/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class Error Component
 * @description
 * Error page
 * Created by Carlos.Moreira @ 2019/07/02
 */
// Angular Components
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Third Party Services
import { TranslateService } from '@ngx-translate/core';

// Ticket Tracker Components
import { TicketTrackerErrors } from '../../enums/errors/ticket-tracker-errors.interface';
import { TicketTrackerService } from '../../services/ticket-tracker.service';

// Libraries Components
import { isNullOrUndefined } from 'qevo.utilities';

@Component({
	selector: 'qoala-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
	/**
	 * ********************************************************************************************************************************
	 *  Properties
	 * ********************************************************************************************************************************
	 */
	today: number;
	errorMessage: string;

	/**
	 * ********************************************************************************************************************************
	 *  Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		private _activatedRoute: ActivatedRoute,
		private _translateService: TranslateService,
		private _ticketTrackerService: TicketTrackerService) {
	}

	ngOnInit() {
		// 1. Current date
		this.today = Date.now();

		// 3. Show Error Message
		this.showErrorMessage();
	}

	/**
	 * Goto Home Page
	 */
	gotoHome() {
		this._ticketTrackerService.gotoPage();
	}

	/**
	 * ********************************************************************************************************************************
	 *  Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Show Error Message
	 */
	private showErrorMessage() {
		// Show message
		this._activatedRoute.params.subscribe(params => {
			// Get passed (if any) error message
			let errorMessage: string = params.errormessage;
			// Check to see if a error code is passed
			const noErrorCode = isNullOrUndefined(params.errorcode);

			// If no message is passed ... get one based on error ...
			if (isNullOrUndefined(errorMessage) || errorMessage === '') {
				errorMessage = 'ERRORS.' +
					TicketTrackerErrors[
					noErrorCode ? TicketTrackerErrors.GenericError : params.errorcode as number];
			}

			this._translateService.get(errorMessage, { errorCode: params.errorcode ?? '' })
				.subscribe((data: string) => {
					this.errorMessage = noErrorCode ? data.substring(0, data.length - 3) : data;
				});
		});
	}
}
