/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * TicketTrackerStatus
 * @description
 * Ticket Tracker Status enumerator
 * Created by Carlos.Moreira @ 2019/07/04 and Modified by Carlos.Moreira @ 2022/11/28
 */

export enum TicketTrackerStatus {
	// ===============================================================================================
	// Navigation
	// ===============================================================================================

	// Home
	Home = -1,

	// Menus
	Menus = 0,

	// ===============================================================================================
	// Items - Tickets or Bookings
	// ===============================================================================================

	// Start item tracking (ticket not finished or booking after check-in)
	StartItemTracking = 10,

	// Shows and tracks a item
	ShowAndTrackItem = 11,

	// Show a item
	ShowItem = 12,

	// Item goto calling area
	PleaseGotoCallingArea = 13,

	// Item called in panel
	ItemCalledToPanel = 14,

	// Item called
	ItemCalled = 15,

	// Item Finished
	ItemFinished = 16,

	// My Items
	MyItems = 17,

	// ===============================================================================================
	// Tickets
	// ===============================================================================================

	// Ticket not found
	TicketNotFound = 20,

	// New Ticket
	NewTicket = 21,

	// Scan Qr Code for taking a new ticket
	ScanQrFlyerNewTicket = 22,

	// Scan Qr Code for tracking a ticket
	ScanTicketQrCode = 23,

	// Ticket selection
	TicketSelection = 24,

	// ===============================================================================================
	// Bookings
	// ===============================================================================================

	// Booking not found
	BookingNotFound = 30,

	// Add Booking - Booking Wizard
	NewBooking = 31,

	// Reschedule Booking - Booking Wizard
	RescheduleBooking = 32,

	// Edit Booking - Form
	EditBooking = 33,

	// Scan Qr Code for taking a new booking
	ScanQrFlyerNewBooking = 34,

	// Validate Booking
	ValidateBooking = 35,

	// ===============================================================================================
	// Others
	// ===============================================================================================

	// Any kind of modal window is displaying
	ModalOpen = 98,

	// Reinitialize
	Reinitialize = 99
}
