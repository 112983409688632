/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class NewBookingComponent
 * @description
 * New Booking Component
 * Created by André.Pinho @ 2022/09/27
*/

// Angular Components
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

// Third party Components
import swal from 'sweetalert2';

// Shared Components
import { TicketTrackerBookingsMngProviderService } from '../../../core/services/bookings/ticket-tracker-bookings-mng-provider.service';
import { BaseEntityCustomization } from '../base-entity-customization/base-entity-customization';
import { MyItemsService } from '../../../core/services/items/my-items.service';
import { TicketTrackerStatus } from '../../../core/enums/state/ticket-tracker-status.interface';
import { TicketTrackerState } from '../../../core/models/state/ticket-tracker-state.interface';
import { TicketTrackerMenuButton } from '../../../core/enums/menu/ticket-tracker-menu-button.interface';
import { TakeBookingRules } from '../../../core/models/booking/take-booking-rules.interface';
import { EntityCustomizationType } from '../../../core/enums/customization/entity-customization-type.enum';

// Bookings Components
import {
	BookingDetails, BookingWizardInitialStepUIEnum, BookingWizardUIStepInitialization,
	ServiceAndUser, WizardListItem, BookingWizardActionEnum
} from 'qore.bookings.models';
import { WizardCanReserve, BookingWizardStepType, WizardCustomSettings } from 'qore.bookings.agendas';

// Libraries Components
import { isNullOrUndefined, StringUtilities } from 'qevo.utilities';
import {
	OperationalEntity, TicketTrackerNavigationSettings, TicketTrackerNavigationMenu,
	ChannelEnum, TakeBookingRule, TicketTrackerBookingsCustomSettings
} from 'qore.models';
import { ServiceTypeEnum } from 'qevo.models';

@Component({
	selector: 'qoala-tt-new-booking',
	templateUrl: './new-booking.component.html',
	styleUrls: ['./new-booking.component.scss']
})
export class NewBookingComponent extends BaseEntityCustomization implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Route params
	private _routeParams: any;

	// User selection EntityId > StoreId > ServiceId
	entityId: number;
	entityName: string;
	entityLogo: SafeUrl;

	// Entity Selected
	private _entity: OperationalEntity;

	// Entity Settings
	navigationSettings: TicketTrackerNavigationSettings;

	// This will be the initial Settings coming from server
	navigationMenus: TicketTrackerNavigationMenu[] = [];

	// Set new entity selection menu
	public showEntityMenu = true;

	// Booking Wizard Settings
	bookingWizardUI: BookingWizardUIStepInitialization;

	// Flag to check if comes from QRCode and which params
	fromStoreQRCode = false;
	fromEntityQRCode = false;

	// Variables to help with the QRCode interaction
	hideEntityIfQrCodeRead: boolean;
	hideStoreIfQrCodeRead: boolean;

	// Is wizard in full screen mode
	isWizardFullscreen: boolean;

	// Is reschedule?
	isReschedule: boolean;

	public actions$: Observable<BookingWizardActionEnum>;
	private _actions: BehaviorSubject<BookingWizardActionEnum>;

	// Ticket Tracker State subscription
	private _ticketTrackerStateSub: Subscription;

	// Wizard custom settings
	wizardCustomSettings: WizardCustomSettings;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(injector: Injector, private _ticketTrackerBookingsMngProviderService: TicketTrackerBookingsMngProviderService,
		private _myItemsService: MyItemsService, private _activatedRoute: ActivatedRoute) {
		super('NewBookingComponent', injector, EntityCustomizationType.Bookings);

		// Initialize Observables
		this._actions = new BehaviorSubject<BookingWizardActionEnum>(null);
		this.actions$ = this._actions.asObservable();
	}

	ngOnInit() {
		// Gets route params
		this.getRouteParams();

		// Apply customization
		this.applyCustomization();

		// Watches for ticket tracker state changes and more specifically entity settings overrides
		// and acts accordingly (after selecting a user)
		this._ticketTrackerStateSub = this._ticketTrackerService.ticketTrackerState$
			.pipe(
				distinctUntilChanged(),
				filter(ticketTrackerState =>
					!isNullOrUndefined(ticketTrackerState) &&
					(
						ticketTrackerState.status === TicketTrackerStatus.NewBooking ||
						ticketTrackerState.status === TicketTrackerStatus.RescheduleBooking
					) &&
					!isNullOrUndefined(ticketTrackerState.menuButton)))
			.subscribe((ticketTrackerState: TicketTrackerState) => {
				// Ticket Tracker State changed
				this._logger.debug(`${this.componentName}:ngOnInit`,
					'Ticket Tracker Status', TicketTrackerStatus[ticketTrackerState.status],
					'Menu', ticketTrackerState.menu, 'Menu Button', ticketTrackerState.menuButton);

				switch (ticketTrackerState.menuButton) {
					case TicketTrackerMenuButton.Next:
						this._actions.next(BookingWizardActionEnum.Next);
						break;

					case TicketTrackerMenuButton.Back:
						this._actions.next(BookingWizardActionEnum.Back);
						break;

					case TicketTrackerMenuButton.Home:
						if (this.showEntityMenu) {
							// Go to home page
							this._ticketTrackerService.gotoPage();

						} else {
							this._actions.next(BookingWizardActionEnum.Cancel);
						}
						break;
				}
			});
	}

	ngOnDestroy() {
		// Unsubscribe
		if (!isNullOrUndefined(this._ticketTrackerStateSub)) { this._ticketTrackerStateSub.unsubscribe(); }

		// Clear Customization
		this.clearCustomization();
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */

	/**
	 * Selected Entity Event
	 * @param entity
	 */
	selectedEntity(entity: OperationalEntity) {
		// logger
		this._logger.debug(`${this.componentName}:selectedEntity`, 'Entity', entity);

		// Initialize wizard after selected entity
		if (isNullOrUndefined(this.entityId)) {
			// Initialize settings for wizard component
			this.bookingWizardUI = {
				initialStep: BookingWizardInitialStepUIEnum.Store,
				hideLanguageStep: true
			};
		}

		// Move the screen to wizard and save the selected entity
		this.showEntityMenu = false;
		this.entityId = entity.id;
		this.entityLogo = entity.publicLogo;
		this.entityName = entity.name;
		this._entity = entity;

		// Load entity settings
		this.loadSettings(entity.settings as string, entity.termsAndConditionsUrl);

		// Set booking settings
		if (!isNullOrUndefined(entity.settings)) {
			// Set wizard custom settings
			const bookingsCustomSettings: TicketTrackerBookingsCustomSettings = this.ticketTrackerSettings?.bookings;

			if (isNullOrUndefined(bookingsCustomSettings)) {
				this.wizardCustomSettings = null;
			} else {
				this.wizardCustomSettings = {
					images: bookingsCustomSettings.images,
					colors: bookingsCustomSettings.colors,
				};
			}
		}

		// Initialize keys with the selected entity
		this._ticketTrackerBookingsMngProviderService.initialize(
			[
				{
					key: 'entityId',
					value: this.entityId
				},
				{
					key: 'inputChannelId',
					value: ChannelEnum.QoalaApp
				}
			]
		);

		// Activate back and next button
		this._ticketTrackerService.setMenu({
			backButton: true,
			nextButton: true,
			languageButton: false,
			soundButton: false,
			qevo: false
		});
	}

	/**
	 * Close wizard event
	 * @param bookingDetails
	 */
	closeEvent(bookingDetails?: BookingDetails) {
		this._logger.debug(`${this.componentName}:closeEvent`,
			'Menu Button', this._ticketTrackerService.ticketTrackerState.menuButton, 'Booking Details', bookingDetails);

		// If we received a booking details add to service and navigate to booking info
		// If we received a home click go to home page.. otherwise go back to select entity
		if (!isNullOrUndefined(bookingDetails)) {
			this._myItemsService.addItem({
				id: bookingDetails?.bookingCode,
				type: ServiceTypeEnum.Booking,
				entityId: bookingDetails.entityId,
				storeId: bookingDetails.storeId,
				serviceId: bookingDetails.serviceId
			});
			this._ticketTrackerService.gotoPage([bookingDetails.bookingCode, ServiceTypeEnum.Booking]);

		} else {
			const menuButton: TicketTrackerMenuButton = this._ticketTrackerService.ticketTrackerState.menuButton;

			if (isNullOrUndefined(menuButton) || menuButton === TicketTrackerMenuButton.Home || menuButton === TicketTrackerMenuButton.Cancel) {
				// Go to home page
				this._ticketTrackerService.gotoPage();
			}
		}

		// Reset all flags to get back to the selection of entity menu
		// And disable buttons and also put the original Qoala buttons back
		this.showEntityMenu = true;
		this.entityId = null;

		// Clean actions to send to booking wizard component
		this._actions.next(null);

		// Reset menu
		this._ticketTrackerService.setMenu({
			homeButton: true,
			nextButton: false,
			backButton: false,
			languageButton: true,
			qevo: true,
			soundButton: true
		}, true);

		// Clear customization
		this.clearCustomization();
	}

	/**
	 * Receive event to enable or disable next button
	 * @param isDone
	 */
	setStepIsDone(isDone: boolean) {
		// logger
		this._logger.debug(`${this.componentName}:setStepIsDone`, 'Is Done', isDone);

		this._ticketTrackerService.setMenu({
			disableNextButton: !isDone
		});
	}

	/**
	 * Receive event to set or unset final button
	 * @param isFinal
	 */
	setStepAsFinal(isFinal: boolean) {
		// logger
		this._logger.debug(`${this.componentName}:setStepAsFinal`, 'Is Final', isFinal);

		this._ticketTrackerService.setMenu(
			{
				nextButton: !isFinal,
				confirmButton: isFinal
			}
		);
	}

	/**
	 * Receives a modal action and hides/shows the menu accordingly
	 * 	- True - open
	 * 	- False - closed
	 * @param isOpen
	 */
	modalEvent(isOpen: boolean) {
		this._ticketTrackerService.setMenu(
			{
				hide: isOpen
			}
		);

		// Set menu styles after modal is gone
		if (!isOpen) {
			this.applyMenuCustomization();
		}
	}

	/**
	 * Current step type event - indicates the type of step
	 * @param type
	 */
	currentStepTypeEvent(type: BookingWizardStepType) {
		// If reschedule we need to hide the back button if in the first step
		if (this.isReschedule && type === BookingWizardStepType.Store) {
			this._ticketTrackerService.setMenu({
				backButton: false
			});

		} else if (this.hideEntityIfQrCodeRead && type === BookingWizardStepType.Store) {
			this._ticketTrackerService.setMenu({
				backButton: false
			});

		} else if (this.hideStoreIfQrCodeRead && type === BookingWizardStepType.ServiceAndUser) {
			// Show previous steps because settings required to show store step
			this.bookingWizardUI = {
				...this.bookingWizardUI,
				showPreviousSteps: true
			};

			this._ticketTrackerService.setMenu({
				backButton: false
			});

		} else {
			this._ticketTrackerService.setMenu({
				backButton: true
			});

		}
	}

	/**
	 * Events that occurs when all assets are selected and before timeslot selection
	 *+ Note:
	 *+ 	- If we send cancel = true, means the reserve action is cancelled and wizard closes
	 * @param canReserve assets selected
	 */
	canReserveEvent(canReserve: WizardCanReserve) {
		this._logger.debug(`${this.componentName}:canReserveEvent`, 'Check if the user can take a new booking', canReserve);

		// Check to see if the user can take a booking
		const canTakeBooking: boolean = this.canTakeBooking(canReserve.entityId, canReserve.storeId, canReserve.serviceId);

		canReserve.cancel = !canTakeBooking;
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Gets route parameters (Parameters can be EntityId, StoreId and ServiceId)
	 */
	private getRouteParams() {
		// Check route
		this._activatedRoute.params.subscribe(params => {
			// 1. Store route info
			this._routeParams = params;

			// 2. Get Booking Information (if exists means we are rescheduling otherwise we are editing)
			const bookingInfo: BookingDetails = this._ticketTrackerService.ticketTrackerState.bookingInfo;
			this.isReschedule = !isNullOrUndefined(bookingInfo);

			// 3. Set ticket tracker state
			this._ticketTrackerService.ticketTrackerState = {
				status: this.isReschedule ?
					TicketTrackerStatus.RescheduleBooking : TicketTrackerStatus.NewBooking
			};

			// Read url params
			if (this._routeParams.entity && this._routeParams.store && this._routeParams.service) {
				this.entityId = this._routeParams.entity;
				this.showEntityMenu = true;
				this.fromStoreQRCode = true;
				this.bookingWizardUI = {
					initialStep: BookingWizardInitialStepUIEnum.Service,
					store: {
						id: parseInt(this._routeParams.store, 10),
						name: null
					},
					serviceAndUser: {
						service: {
							id: parseInt(this._routeParams.service, 10),
							name: null
						},
						user: null
					},
					hideLanguageStep: true
				};

			} else if (this._routeParams.entity && this._routeParams.store) {
				this.entityId = this._routeParams.entity;
				this.showEntityMenu = true;
				this.fromStoreQRCode = true;
				this.bookingWizardUI = {
					initialStep: BookingWizardInitialStepUIEnum.Service,
					store: {
						id: parseInt(this._routeParams.store, 10),
						name: null
					},
					hideLanguageStep: true
				};

			} else if (this._routeParams.entity) {
				this.showEntityMenu = true;
				this.fromEntityQRCode = true;
				this.entityId = this._routeParams.entity;
				this.bookingWizardUI = {
					initialStep: BookingWizardInitialStepUIEnum.Store,
					hideLanguageStep: true
				};

			}

			// If is reschedule
			if (this.isReschedule) {
				// If reschedule use booking info object entity id
				this.entityId = bookingInfo.entityId;

				// Get store data
				const store: WizardListItem = {
					id: bookingInfo.storeId,
					name: bookingInfo.storeName
				};

				// Get service and user step data
				const serviceAndUser: ServiceAndUser = {
					service: {
						id: bookingInfo.serviceId,
						name: bookingInfo.serviceName
					},
					user: {
						id: bookingInfo.userId ?? -1,
						name: bookingInfo.userName
					}
				};

				// Initialize booking wizard
				this.bookingWizardUI = {
					initialStep: BookingWizardInitialStepUIEnum.Store,
					languageCode: this.curLangCode,
					store: store,
					serviceAndUser: serviceAndUser,
					formFields: bookingInfo.formFields,
					toBeDeletedBookingId: bookingInfo.id,
					isReschedule: true,
					hideLanguageStep: true
				};
			}
		});
	}

	/**
	 * Load Settings
	 *
	 * Notes:
	 * 	- Now settings can be null, because we will have always terms and conditions
	 *    Modified by Carlos.Moreira @ 2024/09/03
	 *
	 * @param settings Entity Settings
	 * @param termsAndConditionsUrl Terms and Conditions
	 */
	private loadSettings(settings: string, termsAndConditionsUrl: string) {
		this._logger.debug(`${this.componentName}:loadSettings`, 'Settings', settings, 'Terms and Conditions Url', termsAndConditionsUrl);

		if (isNullOrUndefined(settings)) {
			// Save terms and conditions on service
			this._ticketTrackerService.setTermsAndConditionsUrl(termsAndConditionsUrl);

		} else {
			// Read JSON
			this.ticketTrackerSettings = JSON.parse(settings).ticketTrackerSettings;

			// Save Terms and Conditions on service
			this._ticketTrackerService.setTermsAndConditionsUrl(termsAndConditionsUrl);

			if (this.fromEntityQRCode && !isNullOrUndefined(this.ticketTrackerSettings?.bookings)) {
				// Read settings to hide entity if defined on custom settings
				this.hideEntityIfQrCodeRead = this.ticketTrackerSettings.bookings.hideEntityIfQrCodeRead;

			} else if (this.fromStoreQRCode && !isNullOrUndefined(this.ticketTrackerSettings?.bookings)) {
				// Read settings to hide entity if defined on custom settings
				this.hideEntityIfQrCodeRead = this.ticketTrackerSettings.bookings.hideEntityIfQrCodeRead;

				// Read settings to hide store if defined on custom settings
				this.hideStoreIfQrCodeRead = this.ticketTrackerSettings.bookings.hideStoreIfQrCodeRead;

				// Hide previous steps in case we want to hide store if qr code read
				this.bookingWizardUI = {
					...this.bookingWizardUI,
					showPreviousSteps: !this.hideStoreIfQrCodeRead
				};
			}

			// Apply customization (assigning new ticket tracker settings to be saved in service)
			this.applyCustomization(this.ticketTrackerSettings, true);
		}
	}

	/**
	 * Checks to see if user can take a booking
	 * @param entityId entity id
	 * @param storeId store id
	 * @param serviceId service id
	 */
	private canTakeBooking(entityId: number, storeId: number, serviceId: number): boolean {
		// Get and fill specific entity take booking rules if exist
		const takeBookingRules: TakeBookingRules = this._ticketTrackerService.bookings.setEntityTakeBookingsRules(this._entity.settings as string);

		// Check to see if we can take a booking
		if (this._myItemsService.canTakeBooking(entityId, storeId, serviceId, takeBookingRules.takeBookingRule,
			takeBookingRules.maxNumberOfTakenBookings, this._ticketTrackerService.ticketTrackerState.bookingInfo)) {
			return true;

		} else {
			// Translate error code from resources
			const infoMessage: string = this._translateService.instant(
				// eslint-disable-next-line max-len
				`ERRORS.YOU_HAVE_REACHED_MAX_NUMBER_BOOKINGS${StringUtilities.replaceLowerToUpper(TakeBookingRule[takeBookingRules.takeBookingRule], '_')}`,
				{ maxNumberOfTakenBookings: takeBookingRules.maxNumberOfTakenBookings });

			// Clear customization
			this.clearCustomization();

			// Send error message to UI
			swal.fire({
				title: '',
				text: infoMessage,
				icon: 'info',
				customClass: {
					popup: 'msg-box'
				}
			}).finally(() => {
				this._ticketTrackerService.gotoPage();
			});

			return false;
		}
	}
}
