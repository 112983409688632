/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class TicketTrackerBookingsMngProviderService
* @description
* Qoala Ticket Tracker Bookings Management Provider Service
* Created by André.Pinho && Iuri.Cunha @ 2022/09/27 and Modified by Carlos.Moreira @ 2022/11/23
*/

// Angular Components
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

// Shared Components
import { BookingsService } from './bookings.service';
import { TicketsService } from '../tickets/tickets.service';

// Agendas components
import { BookingsMngProviderService } from 'qore.bookings.agendas';
import {
	BookingCancel, BookingDetails, BookingReserve, BookingReserved, CancelBooking, DailyAvailabilities,
	FilterSearchIds, GetBookingDetailByCode, GetDailyAvailabilities, GetServicesList, GetStoresList,
	ReserveBooking, ServiceDetailsByChannel, StoreList, TimeslotSearch, AddBooking,
	BookingAdd, BookingCheckIn, BookingChecked, CheckInBooking, UpdateBooking, BookingUpdate,
	UsersWithAssignation, GetUsersListWithAssignation
} from 'qore.bookings.models';

// Libraries Components
import { KeyValue } from 'qore.models';
import { PaginatedList } from 'qevo.models';

@Injectable()
export class TicketTrackerBookingsMngProviderService
	extends BookingsMngProviderService
	implements GetStoresList, GetServicesList, GetUsersListWithAssignation, GetDailyAvailabilities, GetBookingDetailByCode, ReserveBooking,
	CancelBooking, AddBooking, CheckInBooking, UpdateBooking {

	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	*/

	// Bookings and Tickets service
	private _bookingsService: BookingsService;
	private _ticketsService: TicketsService;

	// Keys
	private _inputChannelId: number;
	private _partnerId: number;
	private _entityId: number;
	private _pageSize: number;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/

	constructor(private injector: Injector) {
		super('TicketTrackerBookingsMngProviderService', injector);

		// Get Service Injection
		this._ticketsService = this.injector.get(TicketsService);
		this._bookingsService = this.injector.get(BookingsService);
	}

	/**
	 * Call super to initialize keys and them create identification object
	 * @param keys
	 */
	public initialize(keys: KeyValue[]) {
		super.initialize(keys);
	}

	/**
	 * Gets stores list
	 * @param pageNumber
	 * @param searchValue
	 * @param filterSearchIds
	 * @returns
	 */
	getStoresList(pageNumber: number, searchValue?: string,
		filterSearchIds?: FilterSearchIds): Observable<PaginatedList<StoreList>> {

		// Make request and return observable
		return this._ticketsService.getListOfStores(this._entityId, searchValue, pageNumber, this._pageSize);
	}

	/**
	 * Get Services List
	 * @param pageIndex
	 * @param searchValue
	 * @param filterSearchIds
	 * @returns
	 */
	getServicesList(pageIndex: number, searchValue: string,
		filterSearchIds: FilterSearchIds): Observable<PaginatedList<ServiceDetailsByChannel>> {
		// Make request and return observable
		return this._bookingsService.getListOfServices(filterSearchIds.storeIds[0]);
	}

	/**
	 * Return the users associated to a service with assignation
	 * @param pageIndex
	 * @param searchValue
	 * @param filterSearchIds
	 * @returns
	 */
	getUsersListWithAssignation(pageIndex: number, searchValue: string,
		filterSearchIds: FilterSearchIds): Observable<UsersWithAssignation> {
		// Make request and return observable
		return this._bookingsService.getListOfUsersWithAssignation(filterSearchIds.storeIds[0], filterSearchIds.serviceIds[0]);
	}

	/**
	 * Get Daily Availabilities
	 * @param timeslotSearch
	 */
	getDailyAvailabilities(timeslotSearch: TimeslotSearch): Observable<DailyAvailabilities> {
		//Insert keys
		timeslotSearch.partnerId = this._partnerId;
		timeslotSearch.entityId = this._entityId;
		timeslotSearch.inputChannelId = this._inputChannelId;

		// Make API call
		return this._bookingsService.getDailyAvailabilityList(timeslotSearch);
	}

	/**
	 * Retrieves the detail of a booking
	 * @param bookingCode
	 */
	getBookingDetailByCode(bookingCode: string): Observable<BookingDetails> {
		// Make API Call
		return this._bookingsService.getBookingDetailByCode(bookingCode);
	}

	/**
	 * Reserve Booking
	 * @param bookingToReserve
	 */
	reserveBooking(bookingToReserve: BookingReserve): Observable<BookingReserved> {
		// Complete object with keys
		bookingToReserve.entityId = this._entityId;
		bookingToReserve.inputChannelId = this._inputChannelId;

		// Make API call
		return this._bookingsService.reserveBooking(bookingToReserve);
	}

	/**
	 * Cancel Booking
	 * @param bookingToCancel
	 */
	cancelBooking(bookingToCancel: BookingCancel): Observable<void> {
		// Complete object with keys
		bookingToCancel.inputChannelId = this._inputChannelId;

		// Make API call
		return this._bookingsService.cancelBooking(bookingToCancel);
	}

	/**
	 * Add Booking
	 * @param bookingToAdd
	 */
	addBooking(bookingToAdd: BookingAdd): Observable<BookingDetails> {
		// Complete object with keys
		bookingToAdd.inputChannelId = this._inputChannelId;

		// Make API call
		return this._bookingsService.addBooking(bookingToAdd);
	}

	/**
	 * Check-in Bookings
	 * @param bookingToCheckIn
	 */
	checkInBooking(bookingToCheckIn: BookingCheckIn): Observable<BookingChecked> {
		// Complete object with keys
		bookingToCheckIn.inputChannelId = this._inputChannelId;

		// Make API call
		return this._bookingsService.checkInBooking(bookingToCheckIn.id);
	}

	/**
	 * Update booking
	 * @param bookingToUpdate
	 */
	updateBooking(bookingToUpdate: BookingUpdate): Observable<BookingUpdate> {
		// Complete object with keys
		bookingToUpdate.inputChannelId = this._inputChannelId;

		// Make API call
		return this._bookingsService.updateBooking(bookingToUpdate);
	}
}
