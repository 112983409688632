/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * TicketTrackerMenuButton
 * @description
 * Ticket Tracker Menu Button enumerator
 * Created by Carlos.Moreira @ 2022/11/29
 */

export enum TicketTrackerMenuButton {
    Home = 0,
    Back = 1,
    Next = 2,
    Edit = 3,
    Cancel = 4,
    Confirm = 5,
    Sound = 6,
    Language = 7,
    Version = 8
}
