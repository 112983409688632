<div id="black"></div>
<div id="background"></div>

<div id="error">
	<div id="error-message">{{errorMessage | translate}}</div>

	<div id="home" (click)="gotoHome()" title="{{ 'GLOBAL.GOTO_HOME' | translate }}">
		<i class="fa fa-home"></i>
	</div>

	<div id="qevo">
		<a href="https://qevo.pt" target="_blank" rel="noopener">
			<img id="qevo-logo" src="./assets/img/qevo-logo-w.svg" alt="Qevo - logo">
		</a>
	</div>
</div>