/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class CardComponent
 * @description
 * Ticket Component
 * Created by Carlos.Moreira @ 2019/07/24
 */

// Angular Components
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

// Third Party Components
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TranslateService } from '@ngx-translate/core';

// Ticket Tracker Components
import { CardStatusLevel } from '../../../core/enums/card/card-status-level.enum';
import { MyItemsService } from '../../../core/services/items/my-items.service';
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';
import { TicketTrackerBookingsActionsStateMachine } from '../../../core/others/bookings/ticket-tracker-booking-actions-state-machine';
import { TicketTrackerErrors } from '../../../core/enums/errors/ticket-tracker-errors.interface';
import { CardInfo } from '../../../core/models/card/card-info.interface';
import { TicketTrackerStatus } from '../../../core/enums/state/ticket-tracker-status.interface';
import { TicketTrackerState } from '../../../core/models/state/ticket-tracker-state.interface';

// Libraries Components
import { RecordStatus, EtsDisplayType } from 'qore.models';
import { EtsService } from 'qore.services';
import { isNullOrUndefined, TicketUtilities } from 'qevo.utilities';
import { BookingActionEnum, BookingCancel, BookingChecked, BookingCheckIn, BookingDetails, BookingStatusEnum } from 'qore.bookings.models';
import { ServiceTypeEnum } from 'qevo.models';
import { UIMessagesService, LoggerService } from 'qevo.services';
import { ChannelEnum, TicketTrackerTicketDetail } from 'qore.models';

// More action enum
enum MoreActionEnum {
	/**
	 * More actions
	 */
	MoreActions = -99999
}

@Component({
	selector: 'qoala-tt-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */
	// Component
	protected componentName: string;

	// Ticket Tracker State subscription
	private _ticketTrackerStateSub: Subscription;

	// Card Information to show (can be a ticket or booking)
	cardInfo: CardInfo;

	// Language
	@Input() curLangCode: string;

	// Scroll
	@Input() isScrollingUp = false;

	// Is Item Called?
	@Input() set isCalled(value: boolean) {
		if (!isNullOrUndefined(value)) {
			this.setCardStatus(this.cardInfo.type === ServiceTypeEnum.Booking ? BookingStatusEnum.Called : RecordStatus.Called);
		}
	}

	// Show ETS overrides ?
	@Input() showETS: EtsDisplayType;

	// Ownership modal
	@ViewChild('ownershipModal') private _ownershipModal: SwalComponent;

	// More Actions modal
	@ViewChild('actionsModal') private _actionsModal: SwalComponent;

	// Notify parent of the form edit to hide the calling area
	@Output() showFormEmitter: EventEmitter<boolean> = new EventEmitter<any>();

	// Characters threshold to animate service name
	// eslint-disable-next-line @typescript-eslint/no-inferrable-types
	private _truncateThreshold: number = 18;

	// HTML element
	@ViewChild('nameToDisplayElement') nameToDisplayElement: ElementRef;

	// UI function exports
	BookingActionEnum: typeof BookingActionEnum = BookingActionEnum;
	MoreActionEnum: typeof MoreActionEnum = MoreActionEnum;
	ServiceTypeEnum: typeof ServiceTypeEnum = ServiceTypeEnum;
	isNullOrUndefined: typeof isNullOrUndefined = isNullOrUndefined;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		private _etsService: EtsService, private _translateService: TranslateService, private _ticketTrackerService: TicketTrackerService,
		private _myItemsService: MyItemsService, private _uiMessagesService: UIMessagesService, private _activatedRoute: ActivatedRoute,
		private _router: Router, private _logger: LoggerService, private _cdRef: ChangeDetectorRef) {

		// Gets the components name
		this.componentName = 'CardComponent';

		// Default values
		this.cardInfo = null;
	}

	ngOnInit() {
		// Watch for language changes to update UI
		this._translateService.onLangChange.subscribe(() => {
			// Sets the Ets value with the current language
			this.setCardEtsValueToDisplay(this._ticketTrackerService.ticketTrackerState.ticketInfo);
		});

		// Watches for ticket tracker state changes to get ticket or booking to display
		this._ticketTrackerStateSub = this._ticketTrackerService.ticketTrackerState$
			.pipe(
				distinctUntilChanged(),
				filter((ticketTrackerState: TicketTrackerState) =>
					!isNullOrUndefined(ticketTrackerState?.ticketInfo) || !isNullOrUndefined(ticketTrackerState?.bookingInfo)
				))
			.subscribe((ticketTrackerState: TicketTrackerState) => {
				// Ticket Tracker State changed
				this._logger.debug(`${this.componentName}:ngOnInit`,
					'Ticket Tracker Status', TicketTrackerStatus[ticketTrackerState.status],
					'Menu', ticketTrackerState.menu);

				// Get information of booking or ticket, convert into a card information and show it
				this.convertTicketOrBookingToCard(this._ticketTrackerService.ticketTrackerState.ticketInfo,
					this._ticketTrackerService.ticketTrackerState.bookingInfo);
			});
	}

	ngOnDestroy() {
		// Unsubscribe
		if (!isNullOrUndefined(this._ticketTrackerStateSub)) { this._ticketTrackerStateSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */

	/**
	 * Shows ownership modal
	 * @param event$ event data
	 */
	showOwnershipModal(event$: any) {
		// Disable actions
		this.cardInfo.disableActions = true;

		this._ownershipModal.fire()
			.finally(() => {
				// On close call close modal function
				this.closeOwnershipModal(null);
			});
	}

	/**
	 * Closes ownership modal
	 * @param event$ event data
	 */
	closeOwnershipModal(event$: any) {
		this._ownershipModal.dismiss();

		// Disable actions
		this.cardInfo.disableActions = false;

		// If it is a booking... then we came in the more actions modal so we need to go back there
		if (this.cardInfo.type === ServiceTypeEnum.Booking) {
			this.doAction(MoreActionEnum.MoreActions);
		}
	}

	/**
	 * Do Action
	 * @param event
	 */
	doAction(event: BookingActionEnum | MoreActionEnum) {
		switch (event) {
			case BookingActionEnum.CheckIn:
				this._uiMessagesService.showConfirmBoxWithReturn(
					this._translateService.instant('GLOBAL.CONFIRM_BOX.CHECK_IN_TITLE'),
					null,
					this._translateService.instant('GLOBAL.CONFIRM_BOX.YES'),
					this._translateService.instant('GLOBAL.CONFIRM_BOX.NO'),
					null,
					'msg-box')
					.then((result: boolean) => {
						if (result) {
							// Check-in object
							const bookingCheckIn: BookingCheckIn = {
								id: this._ticketTrackerService.ticketTrackerState.bookingInfo.id,
								counterIdentification: null,
								inputChannelId: ChannelEnum.QoalaApp
							};

							// Disable actions
							this.cardInfo.disableActions = true;

							// Fire loading toast. This toast will be replaced by a success or error toast
							this._uiMessagesService.toggleLoadingToaster(true);

							// Make API call
							this._ticketTrackerService.bookings.checkInBooking(bookingCheckIn.id)
								.subscribe((bookingChecked: BookingChecked) => {

									// Update ticketTrackerService booking info
									const bookingInfo: BookingDetails =
										Object.assign(this._ticketTrackerService.ticketTrackerState.bookingInfo, bookingChecked);
									bookingInfo.statusId = BookingStatusEnum.Present;

									// Set new state, booking info and ticket unique id
									this._ticketTrackerService.ticketTrackerState = {
										status: TicketTrackerStatus.ShowAndTrackItem,
										bookingInfo: bookingInfo,
										ticketUniqueId: bookingInfo.ticketUniqueId
									};

									// Convert booking checked in to card
									this.convertTicketOrBookingToCard(null, bookingInfo);

									// Success toast
									this._uiMessagesService.showToaster('', this._translateService.instant('GLOBAL.CONFIRM_BOX.SUCCESS'),
										'success', null, null, null, null, 'bottom');

									// Enable actions
									this.cardInfo.disableActions = false;

								}, (error: any) => {
									this._logger.error(`${this.componentName}:doAction`, 'Check-in', 'Error', error);

									// Error toast
									this._ticketTrackerService.handleErrors(this._uiMessagesService, this._translateService,
										error, error.error); // 'GLOBAL.CONFIRM_BOX.ERROR'

									// Enable actions
									this.cardInfo.disableActions = false;
								});
						}
					});
				break;

			case BookingActionEnum.Cancel:
				this._uiMessagesService.showAlertWithInput(this._translateService.instant('GLOBAL.CONFIRM_BOX.CANCEL_TITLE'),
					'text', null, null, 'GLOBAL.CONFIRM_BOX.OPTIONAL', 'GLOBAL.CONFIRM_BOX.REASON', null,
					this._translateService.instant('GLOBAL.CONFIRM_BOX.YES'),
					this._translateService.instant('GLOBAL.CONFIRM_BOX.NO'), false, 'msg-box')
					.then((result) => {
						// Check-in object
						if (result && result.isConfirmed) {
							const bookingToCancel: BookingCancel = {
								id: this._ticketTrackerService.ticketTrackerState.bookingInfo.id,
								inputChannelId: ChannelEnum.QoalaApp
							};

							// Disable actions
							this.cardInfo.disableActions = true;

							// Fire loading toast. This toast will be replaced by a success or error toast
							this._uiMessagesService.toggleLoadingToaster(true);

							// Make API call
							this._ticketTrackerService.bookings.cancelBooking(bookingToCancel)
								.subscribe(() => {
									// Update ticketTrackerService booking info
									const bookingInfo: BookingDetails = this._ticketTrackerService.ticketTrackerState.bookingInfo;
									bookingInfo.statusId = BookingStatusEnum.Cancelled;

									this._ticketTrackerService.ticketTrackerState = {
										bookingInfo: bookingInfo
									};

									// Convert booking checked in to card
									this.convertTicketOrBookingToCard(null, bookingInfo);

									// Success toast
									this._uiMessagesService.showToaster('', this._translateService.instant('GLOBAL.CONFIRM_BOX.SUCCESS'),
										'success', null, null, null, null, 'bottom');

									// Enable actions
									this.cardInfo.disableActions = false;

								}, (error: any) => {
									this._logger.error(`${this.componentName}:doAction`, 'Check-in', 'Error', error);

									// Error toast
									this._ticketTrackerService.handleErrors(this._uiMessagesService, this._translateService,
										error, error.error); // 'GLOBAL.CONFIRM_BOX.ERROR'

									// Enable actions
									this.cardInfo.disableActions = false;

								});
						}
					});
				break;

			case BookingActionEnum.Reschedule:
				this._uiMessagesService.showConfirmBoxWithReturn(
					this._translateService.instant('GLOBAL.CONFIRM_BOX.RESCHEDULE_TITLE'),
					null,
					this._translateService.instant('GLOBAL.CONFIRM_BOX.YES'),
					this._translateService.instant('GLOBAL.CONFIRM_BOX.NO'),
					null,
					'msg-box')
					.then((result: boolean) => {
						if (result) {
							// Go to new booking page and set new reschedule state
							this._ticketTrackerService.gotoPage(
								['reschedule-booking'],
								{
									status: TicketTrackerStatus.RescheduleBooking
								});
							this._actionsModal.dismiss();
						}
					});
				break;

			case BookingActionEnum.Update:
				// Go to edit booking
				this._ticketTrackerService.gotoPage(['edit-booking'],
					{
						status: TicketTrackerStatus.EditBooking
					});
				this._actionsModal.dismiss();
				break;

			case MoreActionEnum.MoreActions:
				// Display more actions
				this._actionsModal.fire();
				break;
		}
	}

	/**
	 * Close Actions Modal
	 */
	closeActionsModal() {
		this._actionsModal.dismiss();

		// Enable actions
		this.cardInfo.disableActions = false;
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Calculates and sets the new card status
	 * @param newStatus New ticket or booking status (if null or undefined use the current card status, otherwise update it and use)
	 * @returns
	 */
	private setCardStatus(newStatus?: RecordStatus | BookingStatusEnum) {
		this._logger.debug(`${this.componentName}:setCardStatus`, 'New Status', newStatus);

		// If doesn't exist yet ... exit
		if (isNullOrUndefined(this.cardInfo)) { return; }

		// If exists, update it and use
		if (!isNullOrUndefined(newStatus)) {
			this.cardInfo.status = newStatus;
		}

		// Set status level and resource based on the type
		if (this.cardInfo.type === ServiceTypeEnum.Booking) {
			this.cardInfo.statusLevel = isNullOrUndefined(this.cardInfo.status) ||
				this.cardInfo.status === BookingStatusEnum.Booked || this.cardInfo.status === BookingStatusEnum.Confirmed ||
				this.cardInfo.status === BookingStatusEnum.Present || this.cardInfo.status === BookingStatusEnum.Reschedule ||
				this.cardInfo.status === BookingStatusEnum.Reserved ? null :
				this.cardInfo.status === BookingStatusEnum.Called || this.cardInfo.status === BookingStatusEnum.InAssistance ||
					this.cardInfo.status === BookingStatusEnum.RecoverGiveUp || this.cardInfo.status === BookingStatusEnum.RecoverPaused ?
					CardStatusLevel.Information : this.cardInfo.status === BookingStatusEnum.Paused ?
						CardStatusLevel.Warning : this.cardInfo.status === BookingStatusEnum.Finished ?
							CardStatusLevel.Disabled : CardStatusLevel.Alert;

			this.cardInfo.statusResource = isNullOrUndefined(this.cardInfo.status) ?
				'TICKET_TRACKER.ELEMENTS.BOOKING.STATUS.Waiting' :
				`TICKET_TRACKER.ELEMENTS.BOOKING.STATUS.${BookingStatusEnum[this.cardInfo.status]}`;

		} else {
			this.cardInfo.statusLevel = isNullOrUndefined(this.cardInfo.status) ? null :
				this.cardInfo.status === RecordStatus.Called || this.cardInfo.status === RecordStatus.InAssistance ||
					this.cardInfo.status === RecordStatus.RecoverGiveUp || this.cardInfo.status === RecordStatus.RecoverPaused ?
					CardStatusLevel.Information : this.cardInfo.status === RecordStatus.Paused ?
						CardStatusLevel.Warning : this.cardInfo.status === RecordStatus.Finished ?
							CardStatusLevel.Disabled : CardStatusLevel.Alert;

			this.cardInfo.statusResource = isNullOrUndefined(this.cardInfo.status) ?
				'TICKET_TRACKER.ELEMENTS.TICKET.STATUS.Waiting' :
				`TICKET_TRACKER.ELEMENTS.TICKET.STATUS.${RecordStatus[this.cardInfo.status]}`;
		}

		this._logger.info(`${this.componentName}:setCardStatus`, 'New Status', newStatus,
			'Status Level', this.cardInfo.statusLevel, 'Status Resource', this.cardInfo.statusResource);
	}

	/**
	 * Calculates and sets the ETS value to display
	 * @param ticketInfo Ticket Information
	 */
	private setCardEtsValueToDisplay(ticketInfo: TicketTrackerTicketDetail) {
		// If doesn't exist yet ... exit
		if (isNullOrUndefined(this.cardInfo) || isNullOrUndefined(ticketInfo)) { return; }

		// ETS value exists
		// Not hide always
		// Is auto and Ets was printed on ticket true
		if (ticketInfo.etsValue && (
			this.showETS === EtsDisplayType.ShowAlways ||
			(this.showETS === EtsDisplayType.Auto && ticketInfo.printOnTicket) ||
			(this.showETS === EtsDisplayType.HideAfterTicketIsCalled && isNullOrUndefined(ticketInfo.statusId)) ||
			(this.showETS === EtsDisplayType.HideAfterTicketIsTerminated && (ticketInfo.statusId !== RecordStatus.Finished &&
				ticketInfo.statusId !== RecordStatus.Paused && ticketInfo.statusId !== RecordStatus.GiveUp)))
		) {
			this.cardInfo.etsString = this._etsService.generateETSString(ticketInfo.etsValue,
				ticketInfo.etsValueDisplayType, this.curLangCode,
				'TICKET_TRACKER.ELEMENTS.TICKET.ETS.ETS_TIME_0_TYPE',
				'TICKET_TRACKER.ELEMENTS.TICKET.ETS.ETS_TIME_1_TYPE',
				'TICKET_TRACKER.ELEMENTS.TICKET.ETS.ETS_ASSISTED_SOON',
				ticketInfo.maxETSValue);
		} else {

			this.cardInfo.etsString = '';
		}
	}

	/**
	 * Set Possible Actions
	 * @param status
	 * @param hasHotButton
	 */
	private setPossibleActions(status: BookingStatusEnum, hasHotButton?: boolean) {
		// Reset variables
		this.cardInfo.hotButton = null;
		this.cardInfo.actions = [];

		// Initialize possible actions
		const possibleActions: BookingActionEnum[] = TicketTrackerBookingsActionsStateMachine
			.getPossibleActions(status, this.cardInfo.hasForm);

		// Is always possible to see the booking form ... if it exists so add it if it is missing
		if (this.cardInfo.hasForm && !possibleActions.includes(BookingActionEnum.Update)) {
			possibleActions.push(BookingActionEnum.Update);
		}

		if (hasHotButton && possibleActions.length > 0 && status !== BookingStatusEnum.Present) {
			// Initialize hot button
			this.cardInfo.hotButton = {
				type: possibleActions[0],
				event: possibleActions[0],
				label: `TICKET_TRACKER.ELEMENTS.HOT_BUTTON.TYPE.${BookingActionEnum[possibleActions[0]]}`,
			};
		}

		if (status === BookingStatusEnum.Present) {
			// Populate actions buttons
			for (const action of possibleActions) {
				this.cardInfo.actions.push({
					type: action,
					event: action,
					label: `TICKET_TRACKER.ELEMENTS.HOT_BUTTON.TYPE.${BookingActionEnum[action]}`,
				});
			}
		} else {
			// Populate actions buttons
			for (let i = 1; i < possibleActions.length; i++) {
				this.cardInfo.actions.push({
					type: possibleActions[i],
					event: possibleActions[i],
					label: `TICKET_TRACKER.ELEMENTS.HOT_BUTTON.TYPE.${BookingActionEnum[possibleActions[i]]}`,
				});
			}
		}

	}

	/**
	 * Converts a ticket or booking to a card information object
	 * @param ticketInfo Ticket information
	 * @param bookingInfo Booking information
	 */
	private convertTicketOrBookingToCard(ticketInfo?: TicketTrackerTicketDetail, bookingInfo?: BookingDetails) {
		// If doesn't exist yet ... exit
		if (isNullOrUndefined(ticketInfo) && isNullOrUndefined(bookingInfo)) { return; }

		this._logger.debug(`${this.componentName}:convertTicketOrBookingToCard`, 'Ticket Info:', ticketInfo,
			'Booking Info', bookingInfo);

		// Is ticket?
		if (!isNullOrUndefined(ticketInfo)) {
			// Set card information
			this.cardInfo = {
				id: ticketInfo.ticketUniqueId,
				type: ticketInfo.ticketIsFastLane ?
					ServiceTypeEnum.FastLane : ticketInfo.ticketIsPriority ?
						ServiceTypeEnum.Priority : ServiceTypeEnum.Normal,
				status: ticketInfo.statusId,
				entityName: ticketInfo.entityName,
				serviceName: ticketInfo.serviceNameOnDisplay,
				storeName: ticketInfo.storeName,

				ticketUniqueId: ticketInfo.ticketUniqueId,
				ticketFormated: ticketInfo.ticketFormated,
				ticketPrintingHour: ticketInfo.ticketPrintingHour,

				// Show the real "Ticket Formatted" or the "Name To Display" if it exists
				nameToDisplay: TicketUtilities.getTicketInfoToDisplay(ticketInfo.ticketFormated, (ticketInfo.info as string)),

				// Cut ticket unique id in to two parts for UI
				ownershipId: ticketInfo.ticketUniqueId?.substring(0, ticketInfo.ticketUniqueId.length - 4),
				ownershipIdHighlight: ticketInfo.ticketUniqueId?.substring(ticketInfo.ticketUniqueId.length - 4),
				isMyItem: this._myItemsService.isMyItem(ticketInfo.ticketUniqueId)
			};

			this.setCardEtsValueToDisplay(ticketInfo);
		}

		// Is booking?
		if (!isNullOrUndefined(bookingInfo)) {
			// Check to see if booking exists in my items list
			const isMyItem: boolean = this._myItemsService.isMyItem(bookingInfo.bookingCode);

			// If not my booking, goto error page
			if (!isMyItem) {
				this.gotoErrorPage(TicketTrackerErrors.BookingNotFound);
			}

			// Set card information
			this.cardInfo = {
				id: bookingInfo.bookingCode,
				type: ServiceTypeEnum.Booking,
				status: bookingInfo.statusId,
				entityName: bookingInfo.entityName,
				serviceName: bookingInfo.serviceName,
				storeName: bookingInfo.storeName,

				bookingCode: bookingInfo.bookingCode,
				ticketUniqueId: bookingInfo.ticketUniqueId,
				ticketFormated: bookingInfo.ticketFormated,
				start: bookingInfo.start,
				end: bookingInfo.end,
				userName: bookingInfo.userName,
				resume: bookingInfo.resume,

				hasForm: bookingInfo.hasForm,

				// Show the real "Ticket Formatted" or the "Name To Display" if it exists
				nameToDisplay: TicketUtilities.getTicketInfoToDisplay(bookingInfo.ticketFormated, (bookingInfo.ticketInfo as string)),

				// Cut ticket unique id in to two parts for UI
				ownershipId: bookingInfo.bookingCode.substring(0, bookingInfo.bookingCode.length - 4),
				ownershipIdHighlight: bookingInfo.bookingCode.substring(bookingInfo.bookingCode.length - 4),
				isMyItem: this._myItemsService.isMyItem(bookingInfo.ticketUniqueId)
			};

			// Initialize possible actions
			this.setPossibleActions(this.cardInfo.status as BookingStatusEnum, true);
		}

		// Both
		this.setCardStatus();

		// If ticket number is different of name to display and length is greater than threshold, animate it
		if (!isNullOrUndefined(this.cardInfo.nameToDisplay)) {
			this.cardInfo.isTicketNumber = this.cardInfo.nameToDisplay === this.cardInfo.ticketFormated;
			this.cardInfo.animateNameToDisplay = this.cardInfo.isTicketNumber && this.cardInfo.nameToDisplay.length > this._truncateThreshold;

			// If it has to be animated calculate the animation duration according with the size of the element with the content
			if (this.cardInfo.animateNameToDisplay) {
				setTimeout(() => {
					// + 5s so we can have a pause between each cycle of animation
					this.cardInfo.animationDuration = this.nameToDisplayElement.nativeElement.offsetWidth / 50 + 5;
				}, 10);
			}
		}

		this._logger.debug(`${this.componentName}:convertTicketOrBookingToCard`, 'Ticket Info:', ticketInfo,
			'Booking Info', bookingInfo, 'Card Info', this.cardInfo);

		// Detect new changes
		this._cdRef.detectChanges();
	}

	/**
	 * Goes to a specific error page (by default goes to the Out-Of-Order page)
	 * @param errorCode error code
	 * @param errorMessage error message
	 */
	private gotoErrorPage(errorCode?: number, errorMessage?: string) {
		// By default if nothing is passed, show cannot get dispenser configuration error code
		let errorPage: any[] = ['error', TicketTrackerErrors.TicketNotFound, ''];

		// if error and message is passed use them
		if (!isNullOrUndefined(errorCode)) {
			errorPage = ['./error', errorCode, isNullOrUndefined(errorMessage) ? '' : errorMessage];
		}

		// Navigate to error page
		this._router.navigate(errorPage, { relativeTo: this._activatedRoute.root })
			.then(
				value => {
					this._logger.debug(`${this.componentName}:gotoErrorPage`, `Navigated '${errorPage.join('/')}' ?`, value);
				},
				error => {
					this._logger.error(`${this.componentName}:gotoErrorPage`, `Navigated '${errorPage.join('/')}' ? `, error);
				});
	}
}

