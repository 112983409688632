<!-- LANDSCAPE BLOCK -->
<div id="landscape">
	<div id="center">
		<div id="icon"></div>
		<p>{{ 'GLOBAL.ROTATE_DEVICE' | translate }}</p>
	</div>
</div>

<main id="main">

	<!-- NAVIGATION ROUTER -->
	<router-outlet></router-outlet>

	<ng-container *ngIf="!isNullOrUndefined(menu)">
		<!-- ACTION BUTTONS -->
		<div id="actions-container" *ngIf="!menu.hide">
			<!-- HOME -->
			<div id="home" *ngIf="menu.homeButton" (click)="gotoHome()" title="{{ 'GLOBAL.GOTO_HOME' | translate }}">
				<i class="fa fa-home"></i>
			</div>

			<!-- SOUND -->
			<div id="sound" *ngIf="menu.soundButton" (click)="isSoundActiveClick()" [class.sound-off]="!isSoundActive">
				<i class="fa" [ngClass]="isSoundActive ? 'fa-volume-up' : 'fa-volume-off'"></i>
				<i class="fa" [ngClass]="isSoundActive ? '' : 'fa-times'"></i>
			</div>

			<!-- IDIOM -->
			<div id="idiom" *ngIf="menu.languageButton" (click)="showLanguages($event)">
				<i class="fa fa-globe"></i>{{ 'GLOBAL.LANGUAGE_ABR' | translate }}
			</div>

			<!-- Wizard Buttons -->
			<div id="wizard-btn" *ngIf="menu.backButton || menu.nextButton || menu.confirmButton">
				<!-- BACK BUTTON -->
				<div id="back" [hidden]="!menu.backButton" (debounceClick)="backButtonClick()">
					<i class="fa fa-chevron-left" aria-hidden="true"></i>
					<span>{{'ADD_BOOKINGS.BACK' | translate}}</span>
				</div>
				<!-- NEXT BUTTON -->
				<div id="next" [class.disabled]="menu.disableNextButton" [hidden]="!menu.nextButton"
					(debounceClick)="nextButtonClick()">
					<span>{{'ADD_BOOKINGS.NEXT' | translate}}</span>
					<i class="fa fa-chevron-right" aria-hidden="true"></i>
				</div>
				<!-- CONFIRM BUTTON -->
				<div id="conclude" [hidden]="!menu.confirmButton" (debounceClick)="nextButtonClick()">
					<i class="fa fa-check" aria-hidden="true"></i>
					<span>{{'ADD_BOOKINGS.CONFIRM' | translate}}</span>
				</div>
			</div>

			<!-- Form Buttons -->
			<div id="form-btn" *ngIf="menu.editButton || menu.saveButton || menu.cancelButton">
				<div id="cancel" *ngIf="menu.cancelButton" (debounceClick)="cancelChanges()">
					<i class="fa fa-times responsive-icon"></i>
					<span class="sub-title">{{'FORM.CANCEL' | translate}}</span>
				</div>
				<div id="save" *ngIf="menu.saveButton" [class.disabled]="menu.disableSaveButton"
					(debounceClick)="confirmChanges()">
					<i class="fa fa-check responsive-icon"></i>
					<span class="sub-title">{{'FORM.SAVE' | translate}}</span>
				</div>
			</div>
			<div id="form-btn" *ngIf="menu.editButton">
				<div id="edit" (debounceClick)="enableEditMode()">
					<i class="fa fa-pencil"></i>{{'FORM.EDIT' | translate}}
				</div>
			</div>
		</div>

		<!-- QEVO -->
		<div id="qevo" [class.hidden]="showVersionDate" *ngIf="menu.qevo">
			<a href="https://qevo.pt" target="_blank" rel="noopener" (click)="toggleVersion()">
				<img id="qevo-logo" src="./assets/img/qevo-logo-b.svg" alt="Qevo - logo">
			</a>
		</div>

		<!-- VERSION -->
		<div id="version" [class.visible]="showVersionDate" *ngIf="menu.qevo">
			<b>{{versionInfo}}</b>
			<span>{{versionDate}}</span>
		</div>
	</ng-container>

	<!-- LANGUAGE SELECTION -->
	<swal #languagesPopup id="languagesPopup" title="{{'TICKET_TRACKER.ELEMENTS.LANGUAGE.SELECT_LANGUAGE' | translate}}"
		[showCancelButton]="false" [showConfirmButton]="false" [showCloseButton]="false" customClass="msg-box"
		(open)="onOpenLanguages($event)" (close)="onCloseLanguages($event)">
		<div *swalPortal class="-languages">
			<button [id]="language.langCode" class="-language" *ngFor="let language of languages"
				(click)="changeLanguage(language.langCode)">{{'TICKET_TRACKER.ELEMENTS.LANGUAGE.LANGUAGES_LIST.'
				+
				language.langCode | translate}}</button>
			<button class="-close" (click)="closeLanguages($event)">{{'TICKET_TRACKER.ELEMENTS.LANGUAGE.CLOSE' |
				translate}}</button>
		</div>
	</swal>
</main>

<!-- COOKIE MODAL -->
<div id="cookie-modal-container" [class.visible]="!cookieCompliance">
	<div id="cookie-modal">
		<div id="cookie-img">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.101 68.043">
				<g fill-rule="evenodd" clip-rule="evenodd">
					<path fill="#2E2E35"
						d="M14.56 7.915c5.728-5.303 12.798-6.547 20.23-6.679 1.633-.029 2.431.562 1.712 2.343-1.104 2.741-.375 5.055 1.939 6.799 1.016.767 1.532 1.525 1.131 2.817-2.214 7.155.938 11.785 7.104 14.975-.244.764-.732 1.259-1.539 1.404-5.427-1.81-9.441-6.331-9.209-11.969.18-4.359-2.209-7.553-3.325-11.298-.638-2.141-2.473-1.367-4.047-1.274-4.553.271-8.249 3.453-12.803 3.673a2.11 2.11 0 0 1-1.193-.791z" />
					<path fill="#2D2D35"
						d="M45.599 29.08l1.077-.909c4.342.016 8.825-.949 12.63 2.357.692.602 2.304.771 3.2.411 4.874-1.952 4.423 1.274 4.279 4.219-.314 6.449-2.07 12.363-6.528 17.249-.465.342-.908.316-1.332-.072-.303-4.227 2.792-7.348 3.65-11.24 1.22-5.527 1.284-5.551-3.9-7.55-3.006-1.159-5.967-2.199-9.24-2.245-1.59-.023-3.248-.294-3.836-2.22z" />
					<path fill="#2D2F39"
						d="M52.337 60.028c-6.812 7.005-20.481 8.897-31.129 4.188-2.381-1.053-5.066-1.739-6.655-4.123.343-.413.777-.68 1.298-.804 1.864-.264 3.418.629 4.992 1.391 8.487 4.106 16.963 3.765 25.449.001 1.73-.767 3.4-1.885 5.454-1.149.198.162.394.329.591.496z" />
					<path fill="#2F313A"
						d="M7.938 53.492c-8.108-9.01-8.984-25.269-1.943-36.088.714.521.968 1.269 1.055 2.102-1.911 7.946-3.744 15.863-1.052 24.059.938 2.854 2.961 5.374 2.761 8.594a2.37 2.37 0 0 1-.821 1.333z" />
					<path fill="#9E6C4A"
						d="M55.524 1.662c.868.563 2.758 1.277 2.574 3.747-.165 2.25-1.455 3.498-3.719 3.512-2.464.015-3.878-1.241-3.961-3.767-.093-2.876 1.733-3.631 5.106-3.492z" />
					<path fill="#AB7046"
						d="M54.212 15.672c-.527 1.358-1.413 2.708-2.829 2.703-1.4-.004-2.669-1.279-2.71-2.721-.042-1.47 1.318-1.879 2.697-1.895 1.394-.015 2.403.448 2.842 1.913z" />
					<path fill="#7C5E4C"
						d="M66.812 20.057c-.104 1.455-.843 2.343-2.335 2.366-1.737.027-2.599-.939-2.472-2.647.111-1.493 1.047-2.208 2.519-2.14 1.502.07 2.205.963 2.288 2.421z" />
					<path fill="#3A373B"
						d="M7.938 53.492l.221-1.469c3.814.787 7.197 4.233 7.759 7.905l-1.365.164c-2.776-1.627-4.968-3.842-6.615-6.6zM58.989 52.29l1.268.116c-1.475 3.751-3.683 6.741-7.92 7.622l.032.033c-.312-4.735 3.404-6.04 6.62-7.771z" />
					<path fill="#323239"
						d="M14.56 7.915l1.342.15c.012 4.333-3.819 5.596-6.325 7.919-.835 0-1.221-.518-1.429-1.24 1.174-3.181 3.735-5.059 6.412-6.829z" />
					<path fill="#333137"
						d="M8.148 14.744c.279.324.558.65.836.976.253 2.174-.659 3.535-2.815 4.024l-.174-2.34c-.54-1.904 1.83-1.454 2.153-2.66z" />
					<path fill="#FBB97E"
						d="M6.169 19.744l2.815-4.024c2.095-2.742 5.364-4.423 6.918-7.655C20.7 6.176 25.38 3.924 30.604 3.352c2.067-.227 2.889-.053 3.671 2.64 1.425 4.904 2.622 9.761 3.479 14.804.698 4.105 3.687 6.977 7.845 8.284 5.433 1.321 10.81 3.077 16.323 3.785 2.84.364 3.03 1.33 2.844 3.471-.506 5.837-3.128 10.897-5.776 15.954-1.921 2.835-5.552 4.213-6.62 7.771-1.562-.709-2.787.259-4.01.909-10.16 5.403-20.249 5.19-30.315-.245-.66-.355-1.417-.535-2.128-.796-1.977-3.233-4.705-5.729-7.759-7.905-5.435-10.465-6.542-21.198-1.989-32.28z" />
					<path fill="#A96F46"
						d="M12.11 31.541c.092-4.505 2.303-6.232 5.495-5.979 2.754.218 4.9 2.158 4.812 5.262-.099 3.431-2.134 5.297-5.595 5.059-3.059-.212-4.941-1.743-4.712-4.342z" />
					<path fill="#B17347"
						d="M37.543 47.979c-.406-3.563 1.601-4.285 4.507-4.397 2.625-.101 4.359 1.394 4.145 3.502-.232 2.274-1.067 5.358-4.417 5.12-2.323-.168-4.234-1.472-4.235-4.225z" />
					<path fill="#9D6A45"
						d="M16.894 44.761c.145-.801 1.325-2.185 3.535-2.043 2.495.159 4.092 1.677 3.947 4.272-.122 2.193-1.541 3.333-3.812 3.384-2.958.067-3.587-1.841-3.67-5.613z" />
					<path fill="#AB7046"
						d="M29.975 20.74c-.558 1.142-1.3 2.331-2.611 2.373-1.542.049-1.953-1.232-1.976-2.603-.03-1.786 1.102-2.016 2.489-1.855 1.213.139 1.991.773 2.098 2.085zM31.978 37.653c-1.48-.487-1.827-1.575-1.754-2.934.081-1.513 1.107-1.74 2.277-1.615 1.336.143 2.604.687 2.138 2.299a3.112 3.112 0 0 1-2.661 2.25z" />
				</g>
			</svg>
		</div>
		<div id="cookie-info">
			<div id="cookie-message">
				{{'TICKET_TRACKER.ELEMENTS.COOKIES.TEXT' | translate}}
			</div>
			<div id="cookie-action">
				<a href="./assets/policies/cookie-policy-{{curLangCode}}.html" target="_blank"
					id="read-more">{{'TICKET_TRACKER.ELEMENTS.COOKIES.KNOW_MORE' | translate}}</a>
				<div id="cookie-button" (click)="acceptCookieCompliance()">
					{{'TICKET_TRACKER.ELEMENTS.COOKIES.ACCEPT' | translate}}
				</div>
			</div>
		</div>
	</div>
</div>

<!-- INSTALL MODAL -->
<div id="install-modal-container" [class.visible]="showInstall && cookieCompliance">
	<div id="install-modal">
		<!-- MESSAGE -->
		<div id="install-message">
			<p>{{ 'TICKET_TRACKER.ELEMENTS.PWA_INSTALL.MESSAGE' | translate}}</p>
			<b>{{ 'TICKET_TRACKER.ELEMENTS.PWA_INSTALL.MESSAGE_ADD_TO_HOME' | translate}}</b>
		</div>

		<!-- NOT NOW BUTTON -->
		<div id="not-now-button" (click)="dontInstallPWA($event)">
			<i class="fa fa-times"></i>{{ 'TICKET_TRACKER.ELEMENTS.PWA_INSTALL.NOT_NOW' | translate}}
		</div>

		<!-- INSTALL BUTTON -->
		<div id="install-button" (click)="installPWA($event)">
			<i class="fa fa-download"></i>{{ 'TICKET_TRACKER.ELEMENTS.PWA_INSTALL.INSTALL' | translate}}
		</div>
	</div>
</div>