/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class TicketsService
 * @description
 * Ticket Service
 * Created by Carlos.Moreira @ 2022/11/23
 */

// Angular Components
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share, map, catchError } from 'rxjs/operators';

// Ticket Tracker Components
import { CoreConfig } from '../../core.config';
import { TicketAddFromOtherDeviceUI } from '../../models/ticket/ticket-add-from-other-device-ui.interface';
import { MyItemsService } from '../items/my-items.service';

// Libraries Components
import { LoggerService, BaseEmptyService, BaseService, ErrorHandling } from 'qevo.services';
import { SearchParameters, PaginatedList, FilterConnector, FieldType, FieldOperator, ServiceTypeEnum } from 'qevo.models';
import {
	StoreList, TicketTrackerTicket, TicketAddFromOtherDevice, ChannelEnum, PrintedTicket, OperationalServiceList,
	OperationalEntity, EntitySearch, EntityAppsSettings
} from 'qore.models';
import { isNullOrUndefined } from 'qevo.utilities';

@Injectable()
export class TicketsService extends BaseEmptyService<any> {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// New ticket request info
	ticketRequestInfo: TicketAddFromOtherDeviceUI;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(http: HttpClient, logger: LoggerService, private _coreConfig: CoreConfig, private _myItemsService: MyItemsService) {
		super(http as any, logger);

		// Set serviceName
		this.serviceName = 'TicketsService';

		// Base url settings
		this._baseUrl = _coreConfig.configurations.gatewayUrl +
			(_coreConfig.configurations.gatewayUrl.endsWith('/') ? '' : '/');
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	 */

	/**
	 * ================================================================================================================================
	 * Listings (ticket manual selection)
	 * ================================================================================================================================
	 */

	/**
	 * Returns the list of entities
	 * @param hasBookings Indicates if we want only entities that allow bookings or not or both (null)
	 */
	getListOfEntities(hasBookings?: boolean | null): Observable<PaginatedList<OperationalEntity>> {

		// Add default parameters + assign hasBookings flag
		const searchParams =
		{
			pageNumber: 1,
			pageSize: BaseService.allRecords,
			searchFields: [
			],
			sortOrder: [{
				item1: 'name',
				item2: this._coreConfig.configurations.listDefaults.sortOrder
			}],
			filterConnector: FilterConnector.Or,
			hasBookings: hasBookings
		};

		//+ Now uses version 6 - Modified by Carlos.Moreira @ 2022/11/11
		return this.getAll(`v6/ticketTracker/entities`, null, searchParams);
	}

	/**
	 * Returns the list of stores
	 * @param entityId Entity Id
	 * @param searchTerm Search term
	 * @param pageIndexNumber Page number
	 * @param pageSize Page size of data to return
	 */
	getListOfStores(entityId: number, searchTerm?: string, pageIndexNumber?: number, pageSize?: number):
		Observable<PaginatedList<any | StoreList>> {

		// Add search parameters
		const searchParams: SearchParameters = {
			pageNumber: pageIndexNumber ?? 1,
			pageSize: pageSize ?? this._coreConfig.configurations.listDefaults.pageSize,
			searchFields: [
			],
			sortOrder: [{
				item1: 'name',
				item2: this._coreConfig.configurations.listDefaults.sortOrder
			}],
			filterConnector: FilterConnector.Or
		};

		// Has search term?
		if (!isNullOrUndefined(searchTerm)) {
			searchParams.searchFields.push({
				name: 'name',
				value: searchTerm,
				type: FieldType.String,
				operator: FieldOperator.StrContains
			});
		}

		return this.getAll(`v1/ticketTracker/entities/${entityId}/Stores`, null, searchParams);
	}

	/**
	 * Returns the list of tickets
	 * @param entityId Entity Id
	 * @param storeId Store Id
	 * @param searchTerm Search term
	 * @param pageIndexNumber Page number
	 */
	getListOfTickets(entityId: number, storeId: number, searchTerm?: string, pageIndexNumber?: number):
		Observable<PaginatedList<TicketTrackerTicket>> {

		// Add search parameters
		const searchParams: SearchParameters = {
			pageNumber: pageIndexNumber ?? 1,
			pageSize: this._coreConfig.configurations.listDefaults.pageSize,
			searchFields: [
			],
			sortOrder: [{
				item1: 'ticketPrintingHour',
				item2: 'desc'
			}],
			filterConnector: FilterConnector.Or
		};

		// Has search term?
		if (!isNullOrUndefined(searchTerm)) {
			searchParams.searchFields.push({
				name: 'ticketFormated',
				value: searchTerm,
				type: FieldType.String,
				operator: FieldOperator.StrContains
			});
		}

		return this.getAll(`v1/ticketTracker/entities/${entityId}/stores/${storeId}/tickets`, null, searchParams);
	}

	/**
	 * Returns a specific ticket using the ticket formatted as the search parameter
	 * @param entityId Entity Id
	 * @param storeId Store Id
	 * @param ticketFormattedInput Ticket formatted to search
	 */
	getTicketByTicketFormatted(entityId: number, storeId: number, ticketFormattedInput: string):
		Observable<TicketTrackerTicket> {

		return this.get(`v1/ticketTracker/entities/${entityId}/stores/${storeId}/tickets/${ticketFormattedInput}`);
	}

	/**
	 * Returns the list of services
	 * @param entityId Entity Id
	 * @param storeId Store Id
	 * @param searchTerm Search term
	 * @param pageIndexNumber Page number
	 * @param pageSize Page size of data to return
	 */
	getListOfServices(entityId: number, storeId: number, searchTerm?: string, pageIndexNumber?: number, pageSize?: number):
		Observable<PaginatedList<any | OperationalServiceList>> {

		// Add search parameters
		const searchParams: SearchParameters = {
			pageNumber: pageIndexNumber ?? 1,
			pageSize: pageSize ?? this._coreConfig.configurations.listDefaults.pageSize,
			searchFields: [
			],
			sortOrder: [{
				item1: 'name',
				item2: this._coreConfig.configurations.listDefaults.sortOrder
			}],
			filterConnector: FilterConnector.Or
		};

		// Has search term?
		if (!isNullOrUndefined(searchTerm)) {
			searchParams.searchFields.push({
				name: 'name',
				value: searchTerm,
				type: FieldType.String,
				operator: FieldOperator.StrContains
			});
		}

		return this.getAll(`v1/ticketTracker/entities/${entityId}/stores/${storeId}/services`, null, searchParams);
	}

	/**
	 * Returns a new ticket using the ticket formatted as the search parameter
	 * @param requestTicket Request ticket object
	 * @param to Contact to send notification
	 * @param outputChannelId Contact channel id of notification
	 */
	getNewTicket(requestTicket?: TicketAddFromOtherDeviceUI, to?: string, outputChannelId?: ChannelEnum): Observable<PrintedTicket | null> {
		// Assign request ticket information
		if (!isNullOrUndefined(requestTicket)) {
			this.ticketRequestInfo = requestTicket;
		}

		// Assign the contact and channel to use to notify
		if (!isNullOrUndefined(to)) {
			this.ticketRequestInfo.to = to;
			this.ticketRequestInfo.outputChannelId = outputChannelId;
		}

		// Get new Ticket
		// eslint-disable-next-line max-len
		return this.post(`v1/ticketTracker/entities/${this.ticketRequestInfo.entityId}/stores/${this.ticketRequestInfo.storeId}/GetQueueTicket`,
			this.ticketRequestInfo as TicketAddFromOtherDevice).pipe(map(
				(data: PrintedTicket) => {
					// eslint-disable-next-line max-len
					this._logger.info(`${this.serviceName}:getNewTicket`, 'Request Ticket', this.ticketRequestInfo, 'Ticket returned', data);

					// If ok and not null or undefined return it :)
					if (!isNullOrUndefined(data)) {

						// Add ticket to my tickets list
						this._myItemsService.addItem({
							id: data.ticketUniqueId,
							type: data.isFastLane ? ServiceTypeEnum.FastLane :
								data.isPriority ? ServiceTypeEnum.Priority : ServiceTypeEnum.Normal,
							entityId: data.entityId,
							storeId: data.storeId,
							serviceId: data.serviceId
						});

						// Return ticket
						return data;
					}

					return null;
				},
				(error: any) => {
					this._logger.error(`${this.serviceName}:getNewTicket`, 'Request Ticket', requestTicket, 'Error', error);
					return null;
				}
			));
	}

	/**
	 * Check to see if the entity has specific rules for take ticket and use them or assign the default ones
	 * @param ticketRequest Request ticket object
	 * @param entityApplicationSettings Entity Application Settings (in string format)
	 */
	setEntityTakeTicketsRules(ticketRequest: TicketAddFromOtherDeviceUI, entityApplicationSettings: string) {
		this._logger.debug(`${this.serviceName}:setEntityTakeTicketsRules`, 'Start', 'Ticket Request', ticketRequest,
			'Entity Application Settings', entityApplicationSettings);

		// Parse settings string into settings object (only if exists)
		if (!isNullOrUndefined(entityApplicationSettings)) {
			const entityAppsSettings: EntityAppsSettings = JSON.parse(entityApplicationSettings as string) as EntityAppsSettings;

			// Get ticket tracking settings, if they exist and only consider if exists a take ticket rule
			if (!isNullOrUndefined(entityAppsSettings.ticketTrackerSettings?.tickets?.takeTicketRule)) {
				// Assign specific entity ones
				ticketRequest.maxNumberOfTakenTickets = entityAppsSettings.ticketTrackerSettings.tickets.maxNumberOfTakenTickets;
				ticketRequest.takeTicketRule = entityAppsSettings.ticketTrackerSettings.tickets.takeTicketRule;

			} else {
				// Assign default ones
				ticketRequest.maxNumberOfTakenTickets = this._myItemsService.maxNumberOfTakenTickets;
				ticketRequest.takeTicketRule = this._myItemsService.takeTicketRule;

			}

		} else {
			// Assign default ones
			ticketRequest.maxNumberOfTakenTickets = this._myItemsService.maxNumberOfTakenTickets;
			ticketRequest.takeTicketRule = this._myItemsService.takeTicketRule;

		}

		this._logger.debug(`${this.serviceName}:setEntityTakeTicketsRules`, 'End', 'Ticket Request', ticketRequest,
			'Entity Application Settings', entityApplicationSettings);
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * ================================================================================================================================
	 * Listings
	 * ================================================================================================================================
	 */

	/**
	 * Gets a list of items (TModelList or TModelComplexList)
	 * @param endpoint Url
	 * @param keys Entity Keys
	 * @param searchParams SearchParameters
	 */
	private getAll(endpoint: string, keys?: any[] | null, searchParams?: SearchParameters | EntitySearch): Observable<PaginatedList<any>> {
		// Assign empty key array if none is passed
		keys = keys || [];

		// Url with parameters and Search parameters in body
		const url = this._baseUrl + endpoint.format(keys);

		this._logger.debug(`${this.serviceName}:getAll`, 'Url', url, 'Search parameters:', JSON.stringify(searchParams),
			searchParams);

		return this._http.post<any>(url, searchParams)
			.pipe(
				share(),
				map(response => {
					this._logger.debug(`${this.serviceName}:getAll`, 'Url', url, 'Search parameters:', JSON.stringify(searchParams),
						'Response', response);
					return response;
				}),
				catchError(error => ErrorHandling.handleError(error, this._logger))
			);
	}
}
