<!-- HEADER TITLE -->
<div id="view-header">
	<h2 id="main-title">{{ 'VALIDATE_BOOKING.TITLE' | translate }}</h2>
</div>

<!-- INSTRUCTIONS PLACEHOLDER -->
<div id="instruction" [innerHTML]="'VALIDATE_BOOKING.INSTRUCTION' | translate"></div>

<div id="digits">
	<div id="align-items">
		<input id="{{ValidateBookingInputPosition.First}}" [(ngModel)]="firstPosition" title="1" type="text"
			maxlength="1" (keyup)="digitsInput($event, ValidateBookingInputPosition.First)"
			(focus)="verifyDigits(ValidateBookingInputPosition.First)" />
		<input id="{{ValidateBookingInputPosition.Second}}" [(ngModel)]="secondPosition" title="2" type="text" maxlength="1"
			(keyup)="digitsInput($event, ValidateBookingInputPosition.Second)"
			(focus)="verifyDigits(ValidateBookingInputPosition.Second)" />
		<input id="{{ValidateBookingInputPosition.Third}}" [(ngModel)]="thirdPosition" title="3" type="text" maxlength="1"
			(keyup)="digitsInput($event, ValidateBookingInputPosition.Third)"
			(focus)="verifyDigits(ValidateBookingInputPosition.Third)" />
		<input id="{{ValidateBookingInputPosition.Fourth}}" [(ngModel)]="fourthPosition" title="4" type="text" maxlength="1"
			(keyup)="digitsInput($event, ValidateBookingInputPosition.Fourth)"
			(focus)="verifyDigits(ValidateBookingInputPosition.Fourth)" />
	</div>
</div>

<div id="actions">
	<button type="button" id="confirm"
		[class.disabled]="!firstPosition || !secondPosition || !thirdPosition || !fourthPosition"
		(click)="validateOwnership()">{{'VALIDATE_BOOKING.CONFIRM' | translate}}</button>
</div>
