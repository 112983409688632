/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class TrackMenuComponent
 * @description
 * Track Menu Component
 * Created by Carlos.Moreira @ 2020/01/19
 */
// Angular Components
import { Component } from '@angular/core';

@Component({
	selector: 'qoala-tt-track-menu',
	templateUrl: './track-menu.component.html',
	styleUrls: ['./track-menu.component.scss']
})
export class TrackMenuComponent {
	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor() { }
}
