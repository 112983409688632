/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class MyItemsComponent
 * @description
 * My Items Component
 * Created by Iuri.cunha @ 2020/03/25
 */
// Angular Components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

// Ticket Tracker Components
import { MyItemsService } from '../../../core/services/items/my-items.service';
import { MyItem } from '../../../core/models/items/my-item.interface';
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';
import { TicketTrackerStatus } from '../../../core/enums/state/ticket-tracker-status.interface';

// Libraries Components
import { LanguageService, UIMessagesService } from 'qevo.services';
import { isNullOrUndefined } from 'qevo.utilities';
import { LoggerService } from 'qevo.services';
import { ServiceTypeEnum } from 'qevo.models';

@Component({
	selector: 'qoala-tt-my-items',
	templateUrl: './my-items.component.html',
	styleUrls: ['./my-items.component.scss']
})
export class MyItemsComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Component Name
	public componentName: string;

	// List of my user items
	myItems: MyItem[];

	// Language Change Subscription
	private _onLanguageChangeSub: Subscription;

	// Current language
	curLangCode: string;

	// If there are items to show for UI
	myItemsExist: boolean;

	// Loading flag
	isLoading: boolean;

	// Error flag
	isError: boolean;

	// UI Enums
	ServiceTypeEnum: typeof ServiceTypeEnum = ServiceTypeEnum;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		private _uiMessagesService: UIMessagesService, private _languageService: LanguageService,
		private _ticketTrackerService: TicketTrackerService, private _myItemsService: MyItemsService,
		private _logger: LoggerService) {

		// Set
		this.componentName = 'MyItemsComponent';
	}

	ngOnInit() {
		// Loading state
		this.isLoading = true;

		// Get and process Items
		this.getItems();

		// Watch for language changes
		this._onLanguageChangeSub = this._languageService.onLanguageChange$.subscribe(language => {
			this.curLangCode = language.code;
		});
	}

	ngOnDestroy(): void {
		// Unsubscribe
		if (!isNullOrUndefined(this._onLanguageChangeSub)) { this._onLanguageChangeSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	 */

	/**
	 * Goes to the requested item tracking page
	 * @param id item id (booking code or ticket unique id)
	 * @param type
	 */
	gotoItemTracking(id: string, type: ServiceTypeEnum) {

		// If is booking then go to booking card... otherwise go to ticket card
		if (type === ServiceTypeEnum.Booking) {
			this._ticketTrackerService.gotoPage([id, ServiceTypeEnum.Booking],
				{
					status: TicketTrackerStatus.ShowItem
				});
		} else {
			this._ticketTrackerService.gotoPage([id],
				{
					ticketUniqueId: id
				});
		}
	}

	/**
	 * Remove expired items
	 */
	removeExpiredItems() {
		// Prompt user before trying to remove old items
		this._uiMessagesService.showConfirmBoxWithReturn('MY_TICKETS.REMOVE_OLD_ITEMS',
			null, null, null, null, 'msg-box').then(
				result => {
					// If consent is given then run action
					if (result) {
						this._myItemsService.removeExpiredItemsAndUpdateStorage(this.myItems);
					}
				}
			);
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */

	/**
	 * Refresh page
	 */
	refreshEvent() {
		window.location.reload();
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Get user list items from server
	 */
	private getItems() {
		// If storage list is empty then exit
		if (this._myItemsService.storageIsEmpty) {
			// Loading state
			this.isLoading = false;

		} else {
			// Load items
			this._myItemsService.getItemsFromServer()
				// always make loading to false
				.pipe(finalize(() => this.isLoading = false))
				.subscribe((myItems: MyItem[]) => {
					this._logger.debug(`${this.componentName}:getItems`, 'Returned My Items List', myItems);

					// Update user items list
					this.myItems = myItems;

					// If there are items show them ... otherwise inform UI there are no items
					this.myItemsExist = !this._myItemsService.storageIsEmpty;

				}, error => {
					this._logger.error(`${this.componentName}:getItems`, 'Error', error);

					// Set user items to empty array
					this.myItems = [];

					// Flag error UI
					this.isError = true;
				});
		}
	}
}
