/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class NewTicketComponent
 * @description
 * New Ticket Component
 * Created by Iuri.Cunha @ 2020/03/25
 */
// Angular Components
import { Component, OnInit, OnDestroy, ViewChild, Injector, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { share, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

// Third party Components
import swal from 'sweetalert2';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgSelectComponent } from '@ng-select/ng-select';

// Ticket Tracker Components
import { TicketAddFromOtherDeviceUI } from '../../../core/models/ticket/ticket-add-from-other-device-ui.interface';
import { MyItemsService } from '../../../core/services/items/my-items.service';
import { BaseEntityCustomization } from '../base-entity-customization/base-entity-customization';
import { EntityCustomizationType } from '../../../core/enums/customization/entity-customization-type.enum';

// Libraries Components
import {
	StoreList, OperationalServiceList, ChannelEnum, DispenserErrors, PrintedTicket, OperationalEntity,
	TakeTicketRule, TicketTrackerNavigationSettings, TicketTrackerNavigationMenu, TicketTrackerSettings,
	TicketTrackerDisplayItem
} from 'qore.models';
import { BaseService } from 'qevo.services';
import { MultiLanguage, StringUtilities, isNullOrUndefined } from 'qevo.utilities';
import { PaginatedList } from 'qevo.models';

@Component({
	selector: 'qoala-tt-new-ticket',
	templateUrl: './new-ticket.component.html',
	styleUrls: ['./new-ticket.component.scss']
})
export class NewTicketComponent extends BaseEntityCustomization implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// Route params
	private _routeParams: any;

	// List of entity stores
	availableStores: StoreList[] = [];
	private _storesNextPageIndex: number; // -1 means that no more pages exist (no more records)
	private _storeSearchTerm: string;
	loadingStores: boolean;
	@ViewChild('storesSelect') storesSelect: NgSelectComponent;

	// Stores search term value changed ... to debounce the search ...
	private _storesSearchDelayTermChanged: Subject<any> = new Subject<any>();

	// List of store services
	availableServices: OperationalServiceList[] = [];
	public _servicesNextPageIndex: number; // -1 means that no more pages exist (no more records)
	private _serviceSearchTerm: string;
	loadingServices: boolean;
	@ViewChild('servicesSelect') servicesSelect: NgSelectComponent;

	// List of Menus
	dynamicMenuModel: TicketTrackerDisplayItem[] = [];

	// Services search term value changed ... to debounce the search ...
	private _servicesSearchDelayTermChanged: Subject<any> = new Subject<any>();

	// User selection EntityId > StoreId > ServiceId
	entityId: number;
	entityName: string;
	entityLogo: SafeUrl;

	storeId: number;
	storeName: string;

	serviceId: number;
	serviceName: string;

	// Menu levels
	selectedMenuIndex = 0;
	showServiceMenu: boolean;

	// Entity Settings
	navigationSettings: TicketTrackerNavigationSettings;

	// This will be the initial Settings coming from server
	navigationMenus: TicketTrackerNavigationMenu[] = [];

	// Settings object
	ticketTrackerSettings: TicketTrackerSettings;

	// Last user full search
	hasLastFullSearchInfo: boolean;

	// Entity Selected
	private _entity: OperationalEntity;

	// Checkbox settings
	hasPriority: boolean;
	hasSmsNotifications: boolean;
	isPrioritySelected: boolean;
	isSmsAlertsSelected: boolean;

	private _lastEntityId: number;
	private _lastStoreName: string; 		// NOT IN USE AT THIS TIME
	private _lastServiceName: string; 		// NOT IN USE AT THIS TIME

	// Delay in milliseconds to close the dropdown after exact search match or only item
	private _timeoutToCloseDropdownDelay = 500;
	// Flag that indicates if it's the first time the page is loaded and we need to take in account the route
	// parameters passed in router
	// Added by Carlos.Moreira @ 2020/04/28
	private _firstTime: boolean;

	// Disable search to avoid showing mobile keyboard
	public disableSearch = false;

	// Mark as readonly if override flag is set
	public markAsReadOnly = false;

	// Variables to help with the QRCode interaction
	entityFromQRCode = false;
	storeFromQRCode = false;

	// Flag to check if comes from QRCode
	fromQRCode = false;

	// Set new entity selection menu
	public showEntityMenu = true;

	// UI function exports
	isNullOrUndefined: typeof isNullOrUndefined = isNullOrUndefined;

	// Swal Component
	@ViewChild('priorityModal') private _priorityModal: SwalComponent;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(private _activatedRoute: ActivatedRoute, private _myItemsService: MyItemsService, injector: Injector) {
		super('NewTicketComponent', injector, EntityCustomizationType.Tickets);
	}

	ngOnInit() {
		// 1. Default values
		this.isPrioritySelected = false;
		this._firstTime = true;
		// this.disableSearch = this._ticketTrackerService.disableSearch();
		this.disableSearch = true;
		this.isSmsAlertsSelected = false;
		this.navigationMenus = [];
		this.navigationSettings = null;

		// 3. Check if we have route params (and load entities from there)
		this.getRouteParams();

		// 4. Add watches

		// 4.1. Watch for stores search term changes
		this._storesSearchDelayTermChanged
			.pipe(
				map(event$ => event$.term),
				debounceTime(600), // wait 600ms after the last event before emitting last event
				distinctUntilChanged() // only emit if value is different from previous value
			).subscribe(searchTerm => {
				// Force search if exists a previous search term used and:
				//  - user has cleaned the dropdown box
				//  - is deleting characters in previous search and there are less than 2 characters
				// So that way we refresh the list with a empty search
				// Modified by Carlos.Moreira @ 2020/04/27
				const forceSearch: boolean = !isNullOrUndefined(this._storeSearchTerm) &&
					(!isNullOrUndefined(searchTerm) &&
						(this._storeSearchTerm.startsWith(searchTerm) && searchTerm.trim().length <= this._storeSearchTerm.trim().length)
					);

				// console.log('->', 'Last search term', this._storeSearchTerm, 'New search term:', searchTerm,
				// 	'User is cleaning stuff ?', forceSearch);

				if ((!isNullOrUndefined(searchTerm) && searchTerm.length >= 2) || forceSearch) {
					this.loadStores(searchTerm);
				}
			});

		// 4.3. Watch for services search term changes
		this._servicesSearchDelayTermChanged
			.pipe(
				map(event$ => event$.term),
				debounceTime(600), // wait 600ms after the last event before emitting last event
				distinctUntilChanged() // only emit if value is different from previous value
			).subscribe(searchTerm => {
				// Force search if exists a previous search term used and:
				//  - user has cleaned the dropdown box
				//  - is deleting characters in previous search and there are less than 2 characters
				// So that way we refresh the list with a empty search
				// Modified by Carlos.Moreira @ 2020/04/27
				const forceSearch: boolean = !isNullOrUndefined(this._serviceSearchTerm) &&
					(!isNullOrUndefined(searchTerm) &&
						(this._serviceSearchTerm.startsWith(searchTerm) &&
							searchTerm.trim().length <= this._serviceSearchTerm.trim().length)
					);

				// console.log('->', 'Last search term', this._serviceSearchTerm, 'New search term:', searchTerm,
				// 	'User is cleaning stuff ?', forceSearch);

				if ((!isNullOrUndefined(searchTerm) && searchTerm.length >= 2) || forceSearch) {
					this.loadServices(searchTerm);
				}
			});

		// 5. Clean previous request ticket information1
		this._ticketTrackerService.tickets.ticketRequestInfo = null;

		// 6. Clean previous ticketTrackerSettings information on the service
		this._ticketTrackerService.ticketTrackerSettings = null;
	}

	ngOnDestroy() {
		// Clear Customization
		this.clearCustomization();
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */

	/**
	 * Detects when the priority checkbox value is changed
	 * @param newValue New value
	 */
	priorityChanged(newValue: any): void {
		if (newValue.target.checked) {
			newValue.target.checked = false;
			this._priorityModal.fire();
		}
		this.isPrioritySelected = newValue.target.checked;
	}

	/**
	 * Detects if user confirm priority
	 *
	 */
	confirmPriority(): void {
		this.isPrioritySelected = true;
		this._priorityModal.dismiss();
	}

	/**
	 * Detects if user cancel priority
	 *
	 */
	cancelPriority(): void {
		this.isPrioritySelected = false;
		this._priorityModal.dismiss();
	}

	/**
	 * Detects when the smsAlerts checkbox value is changed
	 * @param newValue New value
	 */
	smsAlertsChanged(newValue: any): void {
		this.isSmsAlertsSelected = newValue.target.checked;
	}

	/**
	 * ================================================================================================================================
	 * Get New Ticket
	 * ================================================================================================================================
	 */

	/**
	 * Gets a new ticket and if ok moves to the ticket tracking page
	 */
	getNewTicket() {
		// Save last full search
		this.setLastFullSearchInfo();

		// Generate ticket
		this.generateNewTicket();
	}

	/**
	 * ================================================================================================================================
	 * Navigation
	 * ================================================================================================================================
	 */

	/**
	 * Goto SMS pages (and saves sms settings)
	 */
	gotoSmsSettings() {
		// Save last full search
		this.setLastFullSearchInfo();

		// Build requested object info to assign to service
		const ticketRequest: TicketAddFromOtherDeviceUI = {
			storeId: this.storeId,
			serviceId: this.serviceId,
			langCode: this.curLangCode,
			isPriority: this.isPrioritySelected,
			isFastLane: false,
			isBooking: false,
			entityId: this.entityId,
			entityName: this.entityName,
			serviceName: this.serviceName,
			storeName: this.storeName
		};

		// Get and fill specific entity take tickets rules if exist
		this._ticketTrackerService.tickets.setEntityTakeTicketsRules(ticketRequest, this._entity.settings as string);

		this._ticketTrackerService.tickets.ticketRequestInfo = ticketRequest;

		// Goto sms settings page
		this._ticketTrackerService.gotoPage(['sms-settings']);
	}

	/**
	 * Back button event to go to the entity selection
	 */
	goBackToEntitySelection() {
		// Clear variables selected
		this.serviceId = null;
		this.storeId = null;
		this.entityId = null;
		this._entity = null;
		this.navigationMenus = [];
		this.navigationSettings = null;
		this.isSmsAlertsSelected = false;
		this.entityFromQRCode = false;
		this.storeFromQRCode = false;
		this._storesNextPageIndex = null;

		this._cookieService.set('QoalaTicketTrackerNewTicketEntity', null);

		// Clear Customization
		this.clearCustomization();

		// Reset Ticket Tracker Settings at the service
		this.ticketTrackerSettings = null;
		this._ticketTrackerService.setTicketTrackerSettings(null);
		this._ticketTrackerService.setTermsAndConditionsUrl(null);
		this.showEntityMenu = true;
	}

	/**
	 * ================================================================================================================================
	 * Lists
	 * ================================================================================================================================
	 */

	/**
	 * Event when the user selects/changes an item
	 * @param step step id
	 * @param item item selected
	 * @param level menu level
	 */
	itemSelected(step: number, item: any, level?: number) {
		if (step === 2) {
			this.serviceId = null;
			this.selectedMenuIndex = 0;
			this.dynamicMenuModel = [];
			this.isSmsAlertsSelected = false;

			// Second Step .. save id and load stuff for next step (if item is not undefined)
			if (isNullOrUndefined(item)) {
				this.storeId = null;
				this.storeName = null;

				this.availableStores = [];
				this._storesNextPageIndex = null;
				this._storeSearchTerm = null;

				this.serviceId = null;
				this.serviceName = null;
				this.hasPriority = null;
				this.hasSmsNotifications = null;

				this.availableServices = [];
				this._servicesNextPageIndex = null;
				this._serviceSearchTerm = null;

				this.showServiceMenu = false;
				this.selectedMenuIndex = 0;
				this.dynamicMenuModel = [];

				this.storeFromQRCode = false;

			} else {
				this.storeId = item.id;
				this.storeName = item.name;

				this.availableServices = [];
				this._servicesNextPageIndex = null;
				this._serviceSearchTerm = null;

				// This must be here after the store id and name have been assign ... otherwise the service list is going to
				// send undefined as the store id to the backend (Qore.Gateway)
				// Modified by Carlos.Moreira @ 2021/09/09
				// Check if settings were sent from backend
				if (isNullOrUndefined(this.navigationSettings)) {
					this.showServiceMenu = true;
					this.loadServices();

				} else {
					// Otherwise let's hide it
					this.showServiceMenu = false;
				}
			}

		} else if (step === 3) {
			// We have a menu

			// Lets clear previous selection if there is
			this.serviceId = null;
			this.showServiceMenu = false;
			this.dynamicMenuModel.splice(level + 1);
			this.navigationMenus.splice(level + 1);
			this.isSmsAlertsSelected = false;

			// Let's save user choices and filter the path
			let hasChildren = false;

			if (isNullOrUndefined(item)) {
				this.selectedMenuIndex = level;
				this.serviceId = null;
				this.showServiceMenu = false;

			} else {
				// Get menu index
				this.selectedMenuIndex = level + 1;
				this.dynamicMenuModel[level] = item;

				// Check if we have children elements
				if (!isNullOrUndefined(this.navigationSettings.menus[this.selectedMenuIndex])) {
					let filteredElements = [];

					for (let i = 1; i < this.navigationSettings.menus.length; i++) {
						filteredElements = this.navigationSettings.menus[i].items.filter(menuItem =>
							menuItem.itemParentIds.includes(item.id));

						if (filteredElements.length > 0) {
							this.navigationMenus.push({ ...this.navigationSettings.menus[i] });
							this.navigationMenus[this.navigationMenus.length - 1].items = filteredElements;
							hasChildren = true;
						}
					}

					if (!hasChildren) {
						this.showServiceMenu = true;
						this.loadServices();
					}

				} else {
					// We don't have children.. Let's continue
					this.showServiceMenu = true;
					this.loadServices();
				}
			}
		} else {
			// Third Step ... save id and all done (if item is not undefined)
			this.hasSmsNotifications = null;
			this.isSmsAlertsSelected = false;
			this.markAsReadOnly = false;

			if (isNullOrUndefined(item)) {
				this.serviceId = null;
				this.serviceName = null;
				this.hasPriority = null;
				this.hasSmsNotifications = null;
				this.isSmsAlertsSelected = false;
				this.markAsReadOnly = false;

				this.availableServices = [];
				this._servicesNextPageIndex = null;
				this._serviceSearchTerm = null;

			} else {
				this.serviceId = item.id;
				this.serviceName = item.nameOnDisplay;

				// Check if service has priority
				this.hasPriority = item.isPriority;

				// Check if service has SMS notifications
				this.hasSmsNotifications = item.hasSmsNotifications;

				if (this.hasSmsNotifications && !isNullOrUndefined(this.navigationSettings)) {
					const index = this.navigationSettings.services.findIndex(service => service.id === this.serviceId);

					if (!isNullOrUndefined(this.navigationSettings.services[index].overrideHasSmsNotifications) &&
						this.navigationSettings.services[index].overrideHasSmsNotifications === false) {
						this.isSmsAlertsSelected = false;
						this.hasSmsNotifications = false;
					} else if (!isNullOrUndefined(this.navigationSettings.services[index].overrideHasSmsNotifications) &&
						this.navigationSettings.services[index].overrideHasSmsNotifications === true) {
						this.markAsReadOnly = true;
						this.isSmsAlertsSelected = true;
					}
				}
			}

			// Reset the flag so that the last full search is only done once
			// this.hasLastFullSearchInfo = false;
		}
	}

	/**
	 * Fire when the ng-select is open
	 * @param step step id
	 */
	selectOpen(step: number) {
		if (step === 2) {
			if (isNullOrUndefined(this.storeId)) {
				this.availableStores = [];
				this.clearStore();
			}
		} else {
			if (isNullOrUndefined(this.serviceId)) {
				this.availableServices = [];
				this.clearService();
			}
		}
	}

	/**
	 * Fire when the ng-select loses focus (blur)
	 * @param step step id
	 */
	selectBlur(step: number) {
		if (step === 2) {
			this._storeSearchTerm = null;
		} else {
			this._serviceSearchTerm = null;
		}
	}

	/**
	 * Selected Entity Event
	 * @param entity
	 */
	selectedEntity(entity: OperationalEntity) {
		// logger
		this._logger.debug(`${this.componentName}:selectedEntity`, 'Entity', entity);

		this.showEntityMenu = false;
		this.entityId = entity.id;
		this.entityLogo = entity.publicLogo;
		this.entityName = entity.name;
		this._entity = entity;

		this.loadSettings(entity.settings as string, entity.termsAndConditionsUrl);

		this.loadStores();
	}

	/**
	 * Search Stores (server and client side)
	 * @param event$ search term
	 */
	searchStores(event$: any) {
		this._storesSearchDelayTermChanged.next(event$);
	}

	/**
	 * Clear Store selected button pressed
	 */
	clearStore() {
		this.storeId = null;

		this.serviceId = null;
		this.serviceName = null;
		this.hasPriority = null;
		this.hasSmsNotifications = null;

		this.availableServices = [];
		this._servicesNextPageIndex = null;
		this._serviceSearchTerm = null;
		this._storesNextPageIndex = null;

		this.showServiceMenu = false;
		this.selectedMenuIndex = 0;
		this.dynamicMenuModel = [];

		this.loadStores();
	}

	/**
	 * Load Stores
	 * @param search Search term to filter stores
	 */
	loadStores(searchTerm?: string) {

		// Is search?
		if (isNullOrUndefined(searchTerm)) {
			this._storeSearchTerm = null;

			// No more stores (so no need to go to server)
			if (this._storesNextPageIndex === -1) { return; }
		} else {

			this._storesNextPageIndex = null;
			this._storeSearchTerm = searchTerm;
			this.availableStores = [];

			this.storeName = null;
			this.storeId = null;
		}

		this.loadingStores = true;

		this._logger.debug(`${this.componentName}:loadStores`, 'Entity Id', this.entityId,
			'Search Term is', this._storeSearchTerm, 'Next Page is', this._storesNextPageIndex);

		// Get information from the database (retrieve all stores if storeId exists in route parameter)
		this._ticketTrackerService.tickets.getListOfStores(this.entityId, this._storeSearchTerm,
			this._storesNextPageIndex, (this._routeParams.store) ? BaseService.allRecords : null)
			.pipe(share())
			.subscribe(
				(result: PaginatedList<StoreList>) => {
					this._logger.info(`${this.componentName}:loadStores`, 'Entity Id', this.entityId, 'Result returned', result);

					// First remove items that already exist (avoids duplicates)
					// Added by Carlos.Moreira @ 2021/05/11
					// Now checking if results are for the current entity selected
					// Added by André.Pinho @ 2021/08/04
					const resultFiltered: StoreList[] = result.items.filter(storeFilter =>
						isNullOrUndefined(this.availableStores) || this.availableStores.length === 0 ?
							storeFilter.entityId === this.entityId : !this.availableStores.some(store => store.id === storeFilter.id));

					// First reorder multi-language array before sending it to UI
					// Added by Carlos.Moreira @ 2019/04/24
					this.availableStores = MultiLanguage.reOrder(this.availableStores.concat(resultFiltered), 'name', this.curLangCode,
						false, true, true);

					// Assign current page index of stores
					this._storesNextPageIndex = result.hasNextPage ? result.pageIndex + 1 : -1;

					// If a store is found in route process it
					if (this._routeParams.store && this._firstTime) {
						// Find store in stores list
						const storeSelected = this.availableStores.find(store => store.id === parseInt(this._routeParams.store, 10));

						// If exists, select it
						if (!isNullOrUndefined(storeSelected)) {
							// If item is selected close dropdown
							setTimeout(() => {
								this.storesSelect.close();
								if (!isNullOrUndefined(this.servicesSelect)) {
									this.servicesSelect.focus();
								}
							}, this._timeoutToCloseDropdownDelay);

							if (!isNullOrUndefined(this.ticketTrackerSettings) &&
								this.ticketTrackerSettings?.tickets.hideEntityIfQrCodeRead &&
								this.ticketTrackerSettings?.tickets.hideStoreIfQrCodeRead) {
								this.storeFromQRCode = true;
							}

							this.itemSelected(2, storeSelected);
						}

					} else {
						// If only one store ... fill the next step :)
						if (this.availableStores.length === 1) {
							// If item is selected close dropdown
							setTimeout(() => {
								this.storesSelect.close();
								//this.servicesSelect.focus();
							}, this._timeoutToCloseDropdownDelay);

							this.itemSelected(2, this.availableStores[0]);
						}

						// if is loading last full search information and the store is found ... must reset setting and reload stuff
						// if (this.hasLastFullSearchInfo && this.availableStores.length === 0) {
						// 	this.hasLastFullSearchInfo = false;
						// 	this.loadStores('');
						// }
					}
				},
				error => {
					this._logger.error(`${this.componentName}:loadStores`, 'Entity Id', this.entityId, 'Result returned', error);
					this.availableStores = [];
					this._storesNextPageIndex = null;
					this._storeSearchTerm = null;

					// In case of error, set firstTime flag to false
					this._firstTime = false;

					this.loadingStores = false;
				},
				() => {
					this.loadingStores = false;
				}
			);
	}

	/**
	 * Search Services (server and client side)
	 * @param event$ search term
	 */
	searchServices(event$: any) {
		this._servicesSearchDelayTermChanged.next(event$);
	}

	/**
	 * Clear Service selected button pressed
	 */
	clearService() {
		this.serviceId = null;
		this.serviceName = null;
		this.hasPriority = null;
		this.hasSmsNotifications = null;
		this._servicesNextPageIndex = null;

		this.loadServices();
	}

	/**
	 * Load Services
	 * @param searchTerm Search term to filter services
	 */
	loadServices(searchTerm?: string) {
		// Is search?
		if (isNullOrUndefined(searchTerm)) {
			this._serviceSearchTerm = null;

			// No more services (so no need to go to server)
			if (this._servicesNextPageIndex === -1) { return; }
		} else {

			this._servicesNextPageIndex = null;
			this._serviceSearchTerm = searchTerm;
			this.availableServices = [];
		}

		this.loadingServices = true;

		this._logger.debug(`${this.componentName}:loadServices`, 'Entity Id', this.entityId,
			'Store Id', this.storeId,
			'Search Term is', this._storeSearchTerm, 'Next Page is', this._servicesNextPageIndex);

		// Get information from the database (retrieve all services if serviceId exists in route parameter)
		this._ticketTrackerService.tickets.getListOfServices(this.entityId, this.storeId, this._serviceSearchTerm,
			this._servicesNextPageIndex, (this._routeParams.service) ? BaseService.allRecords : null)
			.pipe(share())
			.subscribe(
				result => {
					this._logger.info(`${this.componentName}:loadServices`, 'Entity Id', this.entityId,
						'Store Id', this.storeId, 'Result returned', result);

					// First remove items that already exist (avoids duplicates)
					// Added by Carlos.Moreira @ 2021/05/11
					// Now checking if results are for the current entity selected
					// Added by André.Pinho @ 2021/08/04
					const resultFiltered: OperationalServiceList[] = result.items.filter(serviceFilter =>
						isNullOrUndefined(this.availableServices) || this.availableServices.length === 0 ?
							serviceFilter.entityId === this.entityId :
							!this.availableServices.some(service => service.id === serviceFilter.id));

					// First reorder multi-language array before sending it to UI
					this.availableServices = MultiLanguage.reOrder(this.availableServices.concat(resultFiltered), 'name', this.curLangCode,
						false, true, true);

					if (this.dynamicMenuModel.length > 0) {
						// Filtering services available that only are assigned to the last menu selected
						// Added by André.Pinho @ 2021/08/11
						// Improved by Carlos.Moreira @ 2021/08/11

						// Get chosen id (only the last one is necessary)
						const lastMenuId: number = this.dynamicMenuModel[this.dynamicMenuModel.length - 1].id;

						const filteredServicesId: number[] = this.navigationSettings.services.filter(service =>
							service.itemParentIds.some(parentId => parentId === lastMenuId)).map(serviceToShow => serviceToShow.id);

						this.availableServices = this.availableServices.filter(service => filteredServicesId.includes(service.id));

						this._logger.debug(`${this.componentName}:loadServices`, `Filtering services list for the menu id ${lastMenuId}`,
							'Available Services', this.availableServices);
					}

					// Assign current page index of services
					this._servicesNextPageIndex = result.hasNextPage ? result.pageIndex + 1 : -1;

					// If a service is found in route process it
					if (this._routeParams.service && this._firstTime) {
						// Find service in services list
						const serviceSelected =
							this.availableServices.find(service => service.id === parseInt(this._routeParams.service, 10));

						// If exists, select it
						if (!isNullOrUndefined(serviceSelected)) {
							// If item is selected close dropdown
							setTimeout(() => {
								this.servicesSelect.close();
							}, this._timeoutToCloseDropdownDelay);

							this.itemSelected(4, serviceSelected);
						}
					} else {

						// If only one service ... fill the next step :)
						if (this.availableServices.length === 1) {
							// If item is selected close dropdown
							setTimeout(() => {
								this.servicesSelect.close();
							}, this._timeoutToCloseDropdownDelay);

							this.itemSelected(4, this.availableServices[0]);
						}
					}
				},
				(error: any) => {
					this._logger.error(`${this.componentName}:loadServices`, 'Entity Id', this.entityId,
						'Store Id', this.storeId, 'Result returned', error);

					this.availableServices = [];
					this._servicesNextPageIndex = null;
					this._serviceSearchTerm = null;

					this.loadingServices = false;
				},
				() => {
					this.loadingServices = false;
					this._firstTime = false;
				}
			);
	}

	/**
	 * Clear Menu selected button pressed
	 * @param level Level
	 */
	clearMenu(level?: number) {
		this.itemSelected(3, null, level);
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Saves the last full search (for pre-filling this information the next time)
	 */
	private setLastFullSearchInfo() {
		this._logger.debug(`${this.componentName}:setLastFullSearchInfo`,
			'Entity', this.entityName, 'Store', this.storeName, 'Service', this.serviceName);

		// Entity, Store and Ticket
		this._cookieService.set('QoalaTicketTrackerNewTicketEntity', this.entityId.toString());
		// this._cookieService.set('QoalaTicketTrackerNewTicketStore', this.storeName.toString());
		// this._cookieService.set('QoalaTicketTrackerNewTicketService', this.serviceName.toString());
	}

	/**
	 * Generate new ticket
	 */
	private generateNewTicket() {
		this._logger.debug(`${this.componentName}:generateNewTicket`, 'Entity id', this.entityId, 'Store id', this.storeId);

		// Build requested object info
		const ticketRequest: TicketAddFromOtherDeviceUI = {
			storeId: this.storeId,
			serviceId: this.serviceId,
			langCode: this.curLangCode,
			isPriority: this.isPrioritySelected,
			isFastLane: false,
			isBooking: false,
			outputChannelId: ChannelEnum.QoalaApp,
			entityId: this.entityId,
			entityName: this.entityName,
			serviceName: this.serviceName,
			storeName: this.storeName
		};

		// Get and fill specific entity take tickets rules if exist
		this._ticketTrackerService.tickets.setEntityTakeTicketsRules(ticketRequest,
			this._entity.settings as string);

		// Check to see if we can take a ticket
		if (this._myItemsService.canTakeTicket(ticketRequest.entityId, ticketRequest.storeId, ticketRequest.serviceId,
			ticketRequest.takeTicketRule, ticketRequest.maxNumberOfTakenTickets)) {

			this._ticketTrackerService.tickets.getNewTicket(ticketRequest)
				.pipe(share())
				.subscribe(
					(result: PrintedTicket) => {
						this._logger.info(`${this.componentName}:generateNewTicket`, 'Result returned', result);

						if (!isNullOrUndefined(result)) {
							this._ticketTrackerService.gotoPage([result.ticketUniqueId]);
						} else {
							// Translate error code from resources
							const errorMessage: string = this._translateService.instant(`ERRORS.GettingTicketError`);

							// Send error message to UI
							swal.fire({
								title: '',
								text: errorMessage,
								icon: 'error',
								customClass: {
									popup: 'msg-box'
								}
							});
						}

					},
					(error: any) => {
						this._logger.error(`${this.componentName}:generateNewTicket`, 'Result returned', error);

						// Translate error code from resources
						const errorMessage: string =
							this._translateService.instant(`ERRORS.${DispenserErrors[error.details]}`, { errorCode: error.details });

						// Send error message to UI
						swal.fire({
							title: '',
							text: errorMessage,
							icon: 'error',
							customClass: {
								popup: 'msg-box'
							}
						});
					}
				);

		} else {
			// Translate error code from resources
			const infoMessage: string = this._translateService.instant(
				// eslint-disable-next-line max-len
				`ERRORS.YOU_HAVE_REACHED_MAX_NUMBER_TICKETS${StringUtilities.replaceLowerToUpper(TakeTicketRule[ticketRequest.takeTicketRule], '_')}`,
				{ maxNumberOfTakenTickets: ticketRequest.maxNumberOfTakenTickets });

			// Send error message to UI
			swal.fire({
				title: '',
				text: infoMessage,
				icon: 'info',
				customClass: {
					popup: 'msg-box'
				}
			});
		}
	}

	/**
	 * Gets route parameters (Parameters can be EntityId, StoreId and ServiceId)
	 */
	private getRouteParams() {
		// Check route
		this._activatedRoute.params.subscribe(params => {
			// 1. Store route info
			this._routeParams = params;

			if (this._routeParams.entity) {
				this.showEntityMenu = true;
				this.fromQRCode = true;
				this.entityId = this._routeParams.entity;
			}
		});
	}

	/**
	 * Load Settings
	 *
	 * Notes:
	 * 	- Now settings can be null, because we will have always terms and conditions
	 *    Modified by Carlos.Moreira @ 2024/09/03
	 *
	 * @param settings Entity Settings
	 * @param termsAndConditionsUrl Terms and Conditions
	 */
	private loadSettings(settings: string, termsAndConditionsUrl: string) {
		this._logger.debug(`${this.componentName}:loadSettings`, 'Settings', settings, 'Terms and Conditions Url', termsAndConditionsUrl);

		if (isNullOrUndefined(settings)) {
			// Save terms and conditions on service
			this._ticketTrackerService.setTermsAndConditionsUrl(termsAndConditionsUrl);

		} else {
			// Read JSON
			this.ticketTrackerSettings = JSON.parse(settings).ticketTrackerSettings;

			// Save terms and conditions on service
			this._ticketTrackerService.setTermsAndConditionsUrl(termsAndConditionsUrl);

			if (!isNullOrUndefined(this.ticketTrackerSettings?.tickets?.navigation)) {
				this.navigationSettings = this.ticketTrackerSettings.tickets.navigation;

				// remove title elements of services
				this.navigationSettings.services = this.navigationSettings.services.filter(service => !isNullOrUndefined(service.id));
				this.navigationSettings.menus
					.map((menu: TicketTrackerNavigationMenu) => {
						menu.items = menu.items
							.filter((item: TicketTrackerDisplayItem) => !isNullOrUndefined(item.id));
					});

				// Let's order the first elements
				this.navigationSettings.menus[0].items = MultiLanguage.reOrder(this.navigationSettings.menus[0].items,
					'nameOnDisplay', this.curLangCode, false, true, true);

				// Deep copy array of objects by value
				if (this.navigationMenus.length === 0) {
					this.navigationMenus.push(this.navigationSettings.menus[0]);
				}
			}

			// Read settings to hide or not entity in case of qr code read
			if (!isNullOrUndefined(this.ticketTrackerSettings?.tickets) && this.fromQRCode) {
				this.entityFromQRCode = this.ticketTrackerSettings.tickets.hideEntityIfQrCodeRead;
			}

			// Apply customization (assigning new ticket tracker settings to be saved in service)
			this.applyCustomization(this.ticketTrackerSettings, true);
		}
	}
}
