/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class MainMenuComponent
 * @description
 * Main Menu Component
 * Created by Iuri.cunha @ 2020/03/25
 */

// Angular Components
import { Component, OnInit } from '@angular/core';

// Third Parties
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

// Ticket Tracker Components
import { MyItem } from 'src/app/core/models/items/my-item.interface';
import { MyItemsService } from '../../../core/services/items/my-items.service';

// Libraries Components
import { isNullOrUndefined } from 'qevo.utilities';

@Component({
	selector: 'qoala-tt-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */
	private _cookieKey: 'QoalaScheduledCleanup';

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(private _cookieService: CookieService, private _myItemsService: MyItemsService) { }

	ngOnInit(): void {
		const cookie = this._cookieService.get(this._cookieKey);

		if (isNullOrUndefined(cookie) || moment(cookie).isBefore(moment())) {
			// Get user items from server (to clean internal list)
			this._myItemsService.getItemsFromServer().subscribe(() => {
				this._cookieService.set(
					this._cookieKey,
					moment().add(7, 'days').format('YYYY-MM-DD'),
					moment().add(1, 'year').toDate()
				);
			});
		}
	}
}
