/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * ValidateBookingInputPosition
 * @description
 * Validate Booking Input Position
 * Created by Carlos.Moreira @ 2022/12/27
 */

export enum ValidateBookingInputPosition {
	First = 1,
	Second = 2,
	Third = 3,
	Fourth = 4
}
