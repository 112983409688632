/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class Core Configuration
 * @description
 * Main Core Configuration Settings
 * Created by Carlos.Moreira @ 2018/02/23
 */

// Qore Components
import { Configuration } from './models/configuration/configuration.interface';
import { environment } from '../../environments/environment.local';

export class CoreConfig {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */
	public readonly configurations: Configuration;
	public environmentName: string;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor() {
		this.environmentName = environment.envName;

		// Import current settings to use the respective ones for each environment
		this.configurations = environment.configurations;
		if (this.environmentName !== 'prod') {
			console.log('CoreConfig:constructor', `Environment '${this.environmentName.trim()}'`, 'Configurations', this.configurations);
		}
	}
}

