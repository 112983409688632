/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class CallingPanelComponent
 * @description
 * Calling Panel Component
 * Created by Carlos.Moreira @ 2019/07/24
 */
// Angular Components
import {
	Component, ViewEncapsulation, Input, AfterViewInit, ViewChild, ElementRef,
	ChangeDetectorRef, OnChanges, SimpleChanges
} from '@angular/core';

// Ticket Tracker Components
import { CallingTicketUI } from '../../../../core/models/ticket/calling-ticket-ui.interface';

// Libraries Components
import { isNullOrUndefined, TicketUtilities } from 'qevo.utilities';
import { LoggerService } from 'qevo.services';

@Component({
	selector: 'qoala-tt-calling-panel',
	templateUrl: './calling-panel.component.html',
	styleUrls: ['./calling-panel.component.scss'],
	encapsulation: ViewEncapsulation.None // Disable CSS encapsulation
})
export class CallingPanelComponent implements AfterViewInit, OnChanges {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */
	// Component
	protected componentName: string;

	// Ticket to show
	@Input() ticket: CallingTicketUI;

	// Language
	@Input() curLangCode: string;

	// Scroll
	@Input() isScrollingUp: boolean;

	// Show Counter?
	@Input() showCounter: boolean;

	// Characters threshold to animate service name
	private _serviceTruncateThreshold: number;
	private _ticketTruncateThreshold: number;

	// Service name animation
	serviceAnimationDuration: number;
	animateServiceName: boolean;

	// Name to display animation
	ticketAnimationDuration: number;
	animateNameToDisplay: boolean;

	// Real "Ticket Formatted" or the "Name To Display" if exists
	nameToDisplay: string;
	isTicketNumber: boolean;

	// HTML elements
	@ViewChild('serviceNameContainer') serviceNameContainer: ElementRef;
	@ViewChild('nameToDisplayElement') nameToDisplayElement: ElementRef;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(private _cdRef: ChangeDetectorRef, private _logger: LoggerService) {
		// Gets the components name
		this.componentName = 'CallingPanelComponent';

		// Default values
		this.isScrollingUp = false;
		this.showCounter = true;
		this._serviceTruncateThreshold = 30;
		this._ticketTruncateThreshold = 13;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!isNullOrUndefined(changes['ticket']) && !isNullOrUndefined(changes['ticket'].currentValue)) {
			// Show the real "Ticket Formatted" or the "Name To Display" or the "CallingText" if any or them exists
			this.nameToDisplay = TicketUtilities.getTicketInfoToCall(this.ticket.ticketFormated, this.ticket.info as string);
			this.isTicketNumber = this.nameToDisplay === this.ticket.ticketFormated ? true : false;
		}
	}

	ngAfterViewInit() {
		// Check if service name has to be animated
		this.animateServiceName = this.serviceNameContainer.nativeElement.getAttribute('data') > this._serviceTruncateThreshold;

		// If it has to be animated calculate the animation duration according with the size of the element with the content
		if (this.animateServiceName) {
			setTimeout(() => {
				// + 5s so we can have a pause between each cycle of animation
				this.serviceAnimationDuration = this.serviceNameContainer.nativeElement.offsetWidth / 50 + 5;
			}, 10);
		}

		// Check if service name has to be animated
		this.animateNameToDisplay = !this.isTicketNumber && this.nameToDisplay.length > this._ticketTruncateThreshold;

		// If it has to be animated calculate the animation duration according with the size of the element with the content
		if (this.animateNameToDisplay) {
			setTimeout(() => {
				// + 5s so we can have a pause between each cycle of animation
				this.ticketAnimationDuration = this.nameToDisplayElement.nativeElement.offsetWidth / 50 + 5;
			}, 10);
		}

		// Detect new changes
		this._cdRef.detectChanges();
	}
}
