/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class ValidateBookingComponent
 * @description
 * Validate Booking Component
 * Created by André.Pinho @ 2022/12/27
 */
// Angular Components
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Shared Components
import { TicketTrackerErrors } from '../../../core/enums/errors/ticket-tracker-errors.interface';
import { ValidateBookingInputPosition } from '../../../core/enums/validate-booking/validate-booking-input-position.enum';
import { BookingsService } from '../../../core/services/bookings/bookings.service';

// Libraries Components
import { isNullOrUndefined } from 'qevo.utilities';
import { LoggerService, UIMessagesService } from 'qevo.services';
import { TranslateService } from '@ngx-translate/core';
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';
import { ServiceTypeEnum } from 'qevo.models';
import { MyItemsService } from '../../../core/services/items/my-items.service';
import { MyLocalItem } from 'src/app/core/models/items/my-local-item.interface';

@Component({
	selector: 'qoala-tt-validate-booking',
	templateUrl: './validate-booking.component.html',
	styleUrls: ['./validate-booking.component.scss']
})
export class ValidateBookingComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */
	// Component
	protected componentName: string;

	// Router Subscription
	private _routerSub: Subscription;

	// Booking Control Code
	bookingControlCode: string;

	// Validation Digits
	validationDigits: string;

	// UI Enum
	ValidateBookingInputPosition: typeof ValidateBookingInputPosition = ValidateBookingInputPosition;

	// Input Numbers
	firstPosition: string;
	secondPosition: string;
	thirdPosition: string;
	fourthPosition: string;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _bookingsService: BookingsService,
		private _loggerService: LoggerService,
		private _translateService: TranslateService,
		private _uiMessagesService: UIMessagesService,
		private _ticketTrackerService: TicketTrackerService,
		private _itemsService: MyItemsService
	) {
		// Gets the components name
		this.componentName = 'ValidateBookingComponent';
	}

	ngOnInit(): void {
		// Subscribe to params
		this._routerSub = this._activatedRoute.params.subscribe(params => {
			// Default
			if (isNullOrUndefined(params.bookingcode)) {
				// Error
				this._loggerService.warn(`${this.componentName}:OnInit`, 'Booking Code does not exist');

				// Navigate to error page
				this.gotoErrorPage(TicketTrackerErrors.BookingNotFound);
			} else {
				// Get booking control code
				this.bookingControlCode = params.bookingcode;
			}
		});
	}

	ngOnDestroy(): void {
		if (!isNullOrUndefined(this._routerSub)) { this._routerSub.unsubscribe(); }
	}

	/**
	 * ================================================================================================================================
	 * Events
	 * ================================================================================================================================
	 */

	/**
	 * Receive digits input
	 * @param newValue
	 * @param position
	 */
	digitsInput(newValue: any, position: ValidateBookingInputPosition) {
		// Get the position of the inserted digit
		switch (position) {
			case ValidateBookingInputPosition.First:
				this.firstPosition = newValue.target.value;
				if (this.firstPosition !== '') {
					document.getElementById(ValidateBookingInputPosition.Second.toString()).focus();
				}
				break;
			case ValidateBookingInputPosition.Second:
				this.secondPosition = newValue.target.value;
				if (this.secondPosition !== '') {
					document.getElementById(ValidateBookingInputPosition.Third.toString()).focus();
				}
				break;
			case ValidateBookingInputPosition.Third:
				this.thirdPosition = newValue.target.value;
				if (this.thirdPosition !== '') {
					document.getElementById(ValidateBookingInputPosition.Fourth.toString()).focus();
				}
				break;
			case ValidateBookingInputPosition.Fourth:
				this.fourthPosition = newValue.target.value;
				break;
		}
	}

	/**
	 * Validate Ownership
	 */
	validateOwnership() {
		// Get all digits inserted
		this.validationDigits = [this.firstPosition, this.secondPosition, this.thirdPosition, this.fourthPosition].join('');

		// Try to validate the inserted digits
		this._bookingsService.validateOwnership(this.bookingControlCode, this.validationDigits).subscribe((bookingCode) => {
			// Check if received booking id is in items list
			// If it isn't we add it to the list
			if (!this._itemsService.isMyItem(bookingCode)) {
				this._itemsService.addItem({
					id: bookingCode,
					type: ServiceTypeEnum.Booking
				});
			}
			// Navigate to booking details to track
			this._ticketTrackerService.gotoPage([bookingCode, ServiceTypeEnum.Booking]);
		},
			(error) => {
				this._loggerService.error(`${this.componentName}:doAction`, 'Check-in', 'Error', error);

				// Get error object
				const parsedError = JSON.parse(error);

				// Error toast
				this._ticketTrackerService.handleErrors(this._uiMessagesService, this._translateService,
					parsedError, parsedError.error); // 'GLOBAL.CONFIRM_BOX.ERROR'
			});
	}

	/**
	 * Verify Digit is there and delete it
	 * @param position
	 */
	verifyDigits(position: ValidateBookingInputPosition) {
		// Remove digit based on focused position
		switch (position) {
			case ValidateBookingInputPosition.First:
				if (this.firstPosition) {
					this.firstPosition = null;
				}
				break;
			case ValidateBookingInputPosition.Second:
				if (this.secondPosition) {
					this.secondPosition = null;
				}
				break;
			case ValidateBookingInputPosition.Third:
				if (this.thirdPosition) {
					this.thirdPosition = null;
				}
				break;
			case ValidateBookingInputPosition.Fourth:
				if (this.fourthPosition) {
					this.fourthPosition = null;
				}
				break;
		}
	}

	/**
	 * ================================================================================================================================
	 * Private
	 * ================================================================================================================================
	 */

	/**
	 * Goes to a specific error page (by default goes to the Out-Of-Order page)
	 * @param errorCode error code
	 * @param errorMessage error message
	 */
	private gotoErrorPage(errorCode?: number, errorMessage?: string) {
		// By default if nothing is passed, show cannot get dispenser configuration error code
		let errorPage: any[] = ['error', TicketTrackerErrors.TicketNotFound, ''];

		this._loggerService.debug(`${this.componentName}:gotoErroPage`, 'Error Code', errorCode, 'Error Message', errorMessage);

		// if error and message is passed use them
		if (!isNullOrUndefined(errorCode)) {
			errorPage = ['./error', errorCode, isNullOrUndefined(errorMessage) ? '' : errorMessage];
		}

		// Navigate to error page
		this._router.navigate(errorPage, { relativeTo: this._activatedRoute.root })
			.then(
				value => {
					this._loggerService.debug(`${this.componentName}:gotoErrorPage`, `Navigated '${errorPage.join('/')}' ?`, value);
				},
				error => {
					this._loggerService.error(`${this.componentName}:gotoErrorPage`, `Navigated '${errorPage.join('/')}' ? `, error);
				});
	}
}
